import { CellContext } from '@tanstack/react-table';
import { Button } from 'components/Form/Button';
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useCallback, useMemo } from 'react';
import { useInitialValue } from 'support/helpers/hooks/webedi/useInitialValue';
import { useDataTableContext } from '../../../contexts/DataTableContext';
import { isProcurosAddedValue } from '../cellHelpers';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { WebEDIRowData } from 'components/DataTable/Types';

export type Props = CellContext<WebEDIRowData, unknown>;

export const DeleteCell = ({ table, row, column }: Props): JSX.Element => {
  const { t } = useTranslation();
  const tableDataPrefix = table.options.meta?.dataPrefix ?? '';
  const { setValue, resetField, remove } = useDataTableContext();
  const quantityField = column?.columnDef.meta?.optionalConfig?.quantityField;

  const absolutePathToQuantityField = useMemo(
    () => (quantityField ? `${tableDataPrefix}.${row.index}.${quantityField}` : ''),
    [quantityField, tableDataPrefix, row.index],
  );

  const isAdded = isProcurosAddedValue(row);
  const value = useWatch({ name: absolutePathToQuantityField });
  const initialValue = useInitialValue(absolutePathToQuantityField);

  const handleDelete = useCallback(() => {
    if (isAdded || !absolutePathToQuantityField) {
      remove(row.index);
    } else {
      setValue(absolutePathToQuantityField, 0);
    }
  }, [absolutePathToQuantityField, isAdded, remove, row.index, setValue]);

  const handleRestore = useCallback(() => {
    resetField(absolutePathToQuantityField);
  }, [resetField, absolutePathToQuantityField]);

  const buttonProps = {
    iconOnly: true,
    size: 'extra-small' as const,
    variant: 'minimal' as const,
    className: 'mx-1',
  };
  const toBeRestored = value === 0 && value !== initialValue;

  return (
    <div className="w-full">
      <div className="flex justify-center px-3">
        {toBeRestored ? (
          <Button {...buttonProps} LeftIcon={ArrowPathIcon} onClick={handleRestore} analyticsId="table_item_restore">
            {t('webedi:deleteRow.restore')}
          </Button>
        ) : (
          <Button {...buttonProps} LeftIcon={XMarkIcon} onClick={handleDelete} analyticsId="table_item_remove">
            {t('webedi:deleteRow.remove')}
          </Button>
        )}
      </div>
    </div>
  );
};
