import { useNavigate, useParams } from 'react-router-dom';
import { useCurrentPartner } from 'services/repositories/partners/partners';
import { Page } from 'components/Page/Page';
import { useMessageWithProcessSpecification } from 'services/repositories/messages/messages';
import { DisplayModes } from 'components/DataTable/Types';
import { FormProvider, useForm } from 'react-hook-form';
import { Form } from 'components/Form/Form';
import { ui } from '@procuros/datachecks';
import { useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import {
  DocumentType,
  IssueResolverType,
  MessageState,
  MessageV2DTO,
  PartnerDTO,
  ProcessSpecificationDTO,
  TransactionInternalType,
} from 'support/types';
import { DocumentSections } from 'components/WebEDI/DocumentSections/DocumentSections';
import { DATA_CHECK_ERROR_CODE, EMPTY_FUNCTION } from 'support/helpers/const/const';
import { useFlattenDataAndUIConfig } from 'hooks/useFlattenDataAndUIConfig';
import { datachecksResolver } from 'support/helpers/resolvers/datachecksResolver';
import {
  cleanStore,
  setCanonical,
  setDestinationProcess,
  setInitialCanonical,
  setIsValidating,
  setProcessSpecificationFields,
} from 'stores/webedi/webedi';
import { terminateDataChecksWorker } from 'services/datachecks/datachecks';
import { UnkownTypePlaceholder } from '../TransactionViewPage/MessageViewPage/UnkownTypePlaceholder';
import { MessageHeader } from '../TransactionViewPage/MessageViewPage/MessageHeader/MessageHeader';
import { useTransactionActionRequired } from 'hooks/useTransactionActionRequired';
import { Placeholder } from 'components/Placeholder/Placeholder';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { routeToTransactionPage } from 'support/helpers/navigation/navigation';
import { useEditMessage } from '../hooks/useEditMessage';
import { WebEDIMessageErrorBanner } from 'components/WebEDI/WebEDIMessageErrorBanner/WebEDIMessageErrorBanner';
import { Datachecks } from '../components/Datachecks/Datachecks';
import { BottomBanner } from '../CreateEditDocumentBottomBanner';

const DISPLAY_MODE = DisplayModes.edit;
export function EditDocument() {
  const { id } = useParams<{ id: string }>();
  const { data: currentPartner } = useCurrentPartner();
  const { data: message, isError } = useMessageWithProcessSpecification({
    variables: {
      messageId: id,
    },
  });

  useLayoutEffect(() => {
    cleanStore();
    return () => {
      cleanStore();
      terminateDataChecksWorker();
    };
  }, []);

  const {
    data,
    uiConfig,
    isLoading: isLoadingDataChecks,
  } = useFlattenDataAndUIConfig(message?.fields, message?.data?.canonical);

  useEffect(() => {
    setProcessSpecificationFields(message?.fields);

    if (message?.data.canonical) {
      setInitialCanonical(message?.data.canonical);
      setCanonical(message?.data.canonical);
    }

    if (message?.process) {
      setDestinationProcess(message?.process);
    }
  }, [message]);

  if (!id) return;

  if (isError) {
    return <UnkownTypePlaceholder messageId={id} />;
  }

  return (
    <EditDocumentPageUI
      isLoading={isLoadingDataChecks}
      message={message?.data}
      data={data}
      relatedTransactions={message?.related_messages || []}
      currentPartner={currentPartner}
      uiConfig={uiConfig}
      processSpecFields={message?.fields}
    />
  );
}

type EditDocumentPageUIProps = {
  isLoading: boolean;
  message: MessageV2DTO | undefined;
  data: any;
  relatedTransactions: Array<MessageV2DTO>;
  currentPartner: PartnerDTO | undefined;
  uiConfig: Array<ui.Section>;
  processSpecFields: ProcessSpecificationDTO['fields'] | undefined;
};

const useEditDocumentForm = ({
  message,
  processSpecFields,
  data,
}: {
  message: MessageV2DTO | undefined;
  processSpecFields: ProcessSpecificationDTO['fields'] | undefined;
  data: any;
}) => {
  const resolver = useMemo(
    () => (message?.internal_state === MessageState.routingFailed ? undefined : datachecksResolver(processSpecFields)),
    [processSpecFields, message],
  );

  const formMethods = useForm<typeof data>({
    values: data,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver,
  });

  useEffect(() => {
    const hasDatachecksError = message?.blocking_error?.code === DATA_CHECK_ERROR_CODE;
    /*
     * If there is a data checks error, we need to trigger the validation to show the errors
     */
    if (hasDatachecksError && data) {
      setIsValidating(true);
      formMethods.handleSubmit(EMPTY_FUNCTION)();
    }
  }, [data, formMethods, message]);

  return formMethods;
};

export const EditDocumentPageUI = ({
  isLoading,
  message,
  data,
  uiConfig,
  relatedTransactions,
  currentPartner,
  processSpecFields,
}: EditDocumentPageUIProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [issueResolver, issueResolverLabel]: [IssueResolverType, string] = useTransactionActionRequired()(
    message?.issue_resolver_id,
  );
  const formMethods = useEditDocumentForm({ message, data, processSpecFields });
  const { handleSubmit, getValues } = formMethods;

  const { editMessage, isUpdating: isEditingMessage } = useEditMessage({
    message,
    processSpecificationFields: processSpecFields,
  });

  const onSubmit = useCallback(() => {
    editMessage(getValues());
  }, [editMessage, getValues]);

  return (
    <FormProvider {...formMethods}>
      <Datachecks targetRelationshipId={message?.relationship_id} documentType={message?.type} />
      <Page isLoading={isLoading}>
        <Form noValidate id="webedi-document" onSubmit={handleSubmit(onSubmit)}>
          <Page.Head>
            {!!message && (
              <MessageHeader
                mode={issueResolver !== IssueResolverType.CURRENT_PARTNER ? DisplayModes.view : DISPLAY_MODE}
                message={message}
                relatedTransactions={relatedTransactions}
                currentPartner={currentPartner}
                issueResolverLabel={issueResolverLabel}
                isLoading={isEditingMessage}
              />
            )}
          </Page.Head>
          <Page.Section
            topBanner={
              message?.blocking_error ? (
                <WebEDIMessageErrorBanner
                  messageId={message.id}
                  mode={DISPLAY_MODE}
                  blockingError={message.blocking_error}
                  issueResolver={issueResolver}
                />
              ) : null
            }
            bottomBanner={<BottomBanner documentType={message?.type as DocumentType} />}
          >
            {issueResolver !== IssueResolverType.CURRENT_PARTNER ? (
              <Placeholder
                title={t('webedi:editDocument.noActionRequired.title')}
                description={t('webedi:editDocument.noActionRequired.description')}
                icon={<LockClosedIcon />}
                ctaText={t('webedi:editDocument.noActionRequired.ctaText')}
                ctaOnClick={() => {
                  navigate(routeToTransactionPage(TransactionInternalType.message, message?.id as string));
                }}
              />
            ) : uiConfig ? (
              <DocumentSections
                mode={DISPLAY_MODE}
                uiConfig={uiConfig}
                documentType={message?.type as DocumentType}
                variables={message?.receiver_partner ? { receiverPartnerName: message.receiver_partner.name } : {}}
              />
            ) : null}
          </Page.Section>
        </Form>
      </Page>
    </FormProvider>
  );
};
