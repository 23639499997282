import { useEffect, useRef } from 'react';
import { logError } from 'services/logging/logging';
import { useConfirmMessageReceival } from 'services/repositories/transactions/transactions';
import {
  Connectors,
  DocumentType,
  MessageV2DTO,
  PartnerDTO,
  ReceiverEnvelopeState,
  UserDTO,
  ValueOf,
} from 'support/types';

export const useAcknowledgement = (
  message: MessageV2DTO | undefined,
  currentPartner: PartnerDTO | undefined,
  currentUser: UserDTO | undefined,
  documentType: ValueOf<DocumentType> | undefined,
) => {
  const hasRunAcknowledgement = useRef(false);
  const { mutate } = useConfirmMessageReceival();

  useEffect(() => {
    if (
      !message?.id ||
      !message.receiver_envelope?.id ||
      !currentPartner?.id ||
      !currentUser ||
      documentType !== DocumentType.order ||
      message.receiver_partner?.id !== currentPartner.id ||
      message.receiver_envelope?.state !== ReceiverEnvelopeState.pendingPickup ||
      message.receiver_integration?.connector !== Connectors.WEB_EDI ||
      currentUser.isAdmin ||
      hasRunAcknowledgement.current
    ) {
      return;
    }

    hasRunAcknowledgement.current = true;
    mutate(
      {
        messageId: message.id,
        receiverEnvelopeId: message.receiver_envelope.id,
      },
      {
        onSuccess: () => {
          hasRunAcknowledgement.current = true;
        },
        onError: (e) => {
          logError(e);
          hasRunAcknowledgement.current = false;
        },
      },
    );
  }, [message, currentPartner, currentUser, documentType, mutate]);
};
