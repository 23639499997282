import { CellContext } from '@tanstack/react-table';
import { classNames } from 'support/helpers/generic/generic';
import {
  disableArrowKeys,
  useCellClassNames,
  useDateConfigurations,
  useIsReadonly,
  isProcurosAddedValue,
} from '../cellHelpers';
import { CellProps } from '../../types';
import { RowStatus } from '../components/RowStatus/RowStatus';
import { useTranslation } from 'react-i18next';
import { TimestampDateInput } from 'components/Form/TimestampDateInput/TimestampDateInput';
import { useController, useFormContext } from 'react-hook-form';
import { InputWrapperCell } from './InputWrapperCell';
import { WebEDIRowData } from 'components/DataTable/Types';

export type Props = CellContext<WebEDIRowData, unknown> & CellProps;
export const DateCell = ({ fieldId, column, row, table, isHighlighted, mode }: Props) => {
  const { t } = useTranslation();
  const {
    columnDef: { meta: columnMeta, ...columnDef },
    getIsFirstColumn,
    getIsLastColumn,
  } = column;

  const {
    field: { value: inputValue },
    fieldState: { error },
  } = useController({ name: fieldId });

  const { formattedValue } = useDateConfigurations({
    value: inputValue,
    mode,
  });

  const { register } = useFormContext();
  const isAdded = isProcurosAddedValue(row);
  // Allow users to set values on all fields if customer created the row or is edit mode
  const readOnly = useIsReadonly({ isAdded, mode, columnMeta, rowIndex: row.index, fieldId });

  const cellClassNames = useCellClassNames({
    isLastColumn: getIsLastColumn(),
    isFirstColumn: getIsFirstColumn(),
    row,
    table,
  });

  const inputClassNames = classNames(
    error
      ? 'group-hover:bg-red-100 bg-red-50 focus:!bg-transparent disabled:!bg-red-50'
      : 'group-hover:bg-procuros-green-50 focus:!bg-transparent disabled:!bg-transparent',
    cellClassNames,
    'text-start',
    columnMeta?.optionalConfig?.isStatusColumn ? '!pl-10' : undefined,
  );

  return (
    <InputWrapperCell fieldId={fieldId} isHighlighted={isHighlighted} className={cellClassNames}>
      <TimestampDateInput
        wrapperClassName="w-full"
        className={classNames(
          'bg-transparent w-full !border-none p-3 !outline-none text-xs !shadow-none rounded-none min-w-[140px]',
          inputClassNames,
        )}
        placeholder={!formattedValue && error?.type === 'required' ? t('common:table.errors.requiredField') : undefined}
        title={formattedValue ? String(formattedValue) : undefined}
        value={formattedValue ?? ''}
        readOnly={readOnly}
        {...register(fieldId)}
        hasError={Boolean(error)}
        leftIcon={
          columnMeta?.optionalConfig?.isStatusColumn ? (
            <RowStatus
              statusColumn={columnMeta?.optionalConfig?.quantityField}
              currentPath={fieldId}
              isProcurosAdded={isAdded}
              currentColumn={columnDef && 'accessorKey' in columnDef ? (columnDef.accessorKey as string) : ''}
              tooltips={{
                deleted: t('webedi:inputCell.status.deleted'),
                partial: t('webedi:inputCell.status.partial'),
                complete: t('webedi:inputCell.status.completed'),
              }}
            />
          ) : null
        }
        onKeyDown={disableArrowKeys}
        id={fieldId}
        data-field={fieldId}
        data-testid={fieldId}
        data-1p-ignore
      />
    </InputWrapperCell>
  );
};
