import { ui } from '@procuros/datachecks';
import { DisplayModes } from 'components/DataTable/Types';
import { DataDisplay } from 'components/Form/DataDisplay/DataDisplay';
import { MessageInput } from 'components/Form/MessageInput/MessageInput';
import { useWatch, useFormContext } from 'react-hook-form';
import { useWebEDIFieldError, useWebEDIErrors } from 'services/webediErrors/webediErrors';

type TextFieldProps = {
  field: ui.TextField;
  fieldId: string;
  isReadOnly: boolean;
  mode: DisplayModes;
};

export const TextField = ({ field, fieldId, isReadOnly, mode }: TextFieldProps) => {
  const value = useWatch({ name: fieldId });
  const { register } = useFormContext();
  const { error } = useWebEDIFieldError(fieldId);
  const { numberOfErrors, setCurrentError } = useWebEDIErrors();

  if (mode === DisplayModes.view) {
    return (
      <DataDisplay
        value={value}
        label={field.label}
        totalNumberOfErrors={numberOfErrors}
        error={error}
        fieldId={fieldId}
        onShowFieldErrorModal={() => error && setCurrentError(error)}
      />
    );
  }

  return (
    <MessageInput
      type="text"
      label={field.label}
      error={error}
      totalNumberOfErrors={numberOfErrors}
      {...register(fieldId)}
      value={value}
      onShowFieldErrorModal={() => error && setCurrentError(error)}
      required={field.modality === 'MANDATORY'}
      disabled={isReadOnly}
    />
  );
};
