import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addNotification, NotificationType } from 'stores/notifications/notifications';
import { ColumnTypesInternal } from 'components/DataTable/Types';
import { DocumentType, ProcessSpecificationDTO, RelationshipV2 } from 'support/types';
import { useWebEDIStore } from 'stores/webedi/webedi';
import { OcrResult, MergeStats, UnmatchedLineItem } from '../../types';
import { calculateMergeStats } from '../../utils';
import { ConfirmationStage } from './components/ConfirmationStage';
import { PickLineItemsStage } from './components/PickLineItemsStage';
import { UploadStage } from './components/UploadStage';
import classNames from 'classnames';

enum ExtractionStages {
  UPLOAD,
  PICK_LINE_ITEMS,
  CONFIRMATION,
}

type ExtractorProps = {
  senderProcessSpecification: ProcessSpecificationDTO;
  targetRelationship: RelationshipV2;
  onApplyOcrExtraction: (result: OcrResult) => void;
  setOpen: (open: boolean) => void;
};

export const Extractor = ({
  setOpen,
  senderProcessSpecification,
  targetRelationship,
  onApplyOcrExtraction,
}: ExtractorProps) => {
  const { t } = useTranslation();
  const canonical = useWebEDIStore((state) => state.initialCanonical);
  const receiverProcessSpecificationFields = useWebEDIStore((state) => state.processSpecificationFields);
  const [stage, setStage] = useState<ExtractionStages>(ExtractionStages.UPLOAD);
  const [documentHash, setDocumentHash] = useState<string>();
  const [mergedData, setMergedData] = useState<any>();
  const [mergeStats, setMergeStats] = useState<MergeStats>({
    matched: 0,
    added: 0,
    missing: 0,
  });
  const [unmatchedLineItems, setUnmatchedLineItems] = useState<Array<UnmatchedLineItem>>([]);

  const onBack = useCallback(() => {
    switch (stage) {
      case ExtractionStages.PICK_LINE_ITEMS:
        setStage(ExtractionStages.UPLOAD);
        break;
      case ExtractionStages.CONFIRMATION:
        if (unmatchedLineItems.length > 0) {
          setStage(ExtractionStages.PICK_LINE_ITEMS);
          break;
        }
        setStage(ExtractionStages.UPLOAD);
        break;
    }
  }, [stage, unmatchedLineItems]);

  const onConfirm = useCallback(() => {
    const finalMergedData = {
      ...mergedData,
      transport_units: mergedData.transport_units
        .map((unit: any, unitIndex: number) => ({
          ...unit,
          line_items: unit.line_items
            .map((lineItem: any, lineItemIndex: number) => {
              const unmatchedLineItem = unmatchedLineItems.find(
                (unmatchedLineItem) =>
                  unmatchedLineItem.path === `transport_units[${unitIndex}].line_items[${lineItemIndex}]`,
              );

              return {
                ...lineItem,
                _internal: {
                  ...lineItem._internal,
                  matched: !unmatchedLineItem,
                  keepUnmatched: unmatchedLineItem?.keep === 'true',
                  [ColumnTypesInternal.procuros_added]: !!unmatchedLineItem,
                },
              };
            })
            .filter((lineItem: any) => {
              if (lineItem._internal.matched === true) {
                return true;
              }

              return lineItem._internal.keepUnmatched === true;
            }),
        }))
        .filter((unit: any) => unit.line_items.length > 0),
    };

    onApplyOcrExtraction({
      documentHash: documentHash ?? '',
      data: finalMergedData,
    });
    setOpen(false);
    addNotification(
      {
        title: t('webedi:documentExtractor.notifications.success.title'),
        subtitle: t('webedi:documentExtractor.notifications.success.subtitle'),
      },
      NotificationType.success,
    );
  }, [mergedData, onApplyOcrExtraction, documentHash, setOpen, t, unmatchedLineItems]);

  const onApply = useCallback(
    (newUnmatchedLineItems: Array<UnmatchedLineItem>) => {
      setMergeStats(
        calculateMergeStats(mergedData, newUnmatchedLineItems, targetRelationship.message_type as DocumentType),
      );
      setUnmatchedLineItems(newUnmatchedLineItems);
      setStage(ExtractionStages.CONFIRMATION);
    },
    [mergedData, targetRelationship.message_type],
  );

  const onExtracted = useCallback(
    (data: any, documentHash: string) => {
      const unmatchedLineItems: Array<UnmatchedLineItem> = data.merged.transport_units.flatMap(
        (unit: any, unitIndex: number) =>
          unit.line_items
            .flatMap((lineItem: any, lineItemIndex: number) => {
              if (lineItem._ocr_internal.matched === false && lineItem._ocr_internal.extracted === true) {
                return {
                  path: `transport_units[${unitIndex}].line_items[${lineItemIndex}]`,
                  item: lineItem,
                  keep: 'false',
                };
              }
            })
            .filter(Boolean),
      );

      setMergedData(data.merged);
      setDocumentHash(documentHash);

      if (unmatchedLineItems.length > 0) {
        setUnmatchedLineItems(unmatchedLineItems);
        setStage(ExtractionStages.PICK_LINE_ITEMS);
        return;
      }

      setMergeStats(calculateMergeStats(data.merged, [], targetRelationship.message_type as DocumentType));
      setStage(ExtractionStages.CONFIRMATION);
    },
    [targetRelationship.message_type],
  );

  if (!receiverProcessSpecificationFields) return null;

  return (
    <div
      className={classNames('flex max-h-[800px] flex-col gap-4 overflow-auto p-6 w-full', {
        'h-[75vh] w-[75vw]': stage === ExtractionStages.UPLOAD,
      })}
    >
      {stage === ExtractionStages.UPLOAD && (
        <UploadStage
          senderSpec={senderProcessSpecification}
          receiverSpec={{ fields: receiverProcessSpecificationFields }}
          targetRelationship={targetRelationship}
          documentType={targetRelationship?.message_type}
          initialData={canonical}
          onExtracted={onExtracted}
        />
      )}
      {stage === ExtractionStages.PICK_LINE_ITEMS && (
        <PickLineItemsStage onBack={onBack} onApply={onApply} unmatchedLineItems={unmatchedLineItems} />
      )}
      {stage === ExtractionStages.CONFIRMATION && (
        <ConfirmationStage mergeStats={mergeStats} onBack={onBack} onConfirm={onConfirm} />
      )}
    </div>
  );
};
