import { Popup } from 'components/Display/Popup/Popup';
import { Button } from 'components/Form/Button/Button';
import { LoadingLogo } from 'components/Loading/LoadingLogo';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoverTransactions } from 'services/repositories/transactions/transactions';
import { NotificationType, useNotificationStore } from 'stores/notifications/notifications';
import { useAnalytics } from 'support/helpers/analytics/analytics';
import { EMPTY_FUNCTION } from 'support/helpers/const/const';

export type RetriggerTransactionsProps = {
  transactionIds: Array<string>;
  open: boolean;
  onClose?: () => void;
  onComplete: () => void;
};

export function RetriggerTransactionsModal({
  transactionIds,
  open,
  onClose = EMPTY_FUNCTION,
  onComplete,
}: RetriggerTransactionsProps) {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const { addNotification } = useNotificationStore();
  const [isLoading, setIsLoading] = useState(false);
  const { mutate } = useRecoverTransactions();

  const transactionItems = useMemo(() => transactionIds.map((id) => ({ transactionId: id })), [transactionIds]);

  useEffect(() => {
    // reset state when modal is closed
    // controlling loading state separately because of flickering when closing
    if (open) {
      setIsLoading(false);
    }
  }, [open]);

  const retrigger = async () => {
    const payload = { items: transactionItems };
    setIsLoading(true);
    mutate(payload, {
      onSuccess: (response) => {
        track('transactions_retriggered', {
          transaction_count: transactionIds.length,
          transaction_ids: transactionIds,
        });
        if (response.success) {
          if ((response.data?.failed ?? []).length > 0) {
            addNotification(
              {
                title: t('transactions:show.sections.errors.retriggerTransactions.warn', {
                  failed: response.data?.failed?.length,
                  total: transactionIds.length,
                }),
                subtitle: t('transactions:show.sections.errors.retriggerTransactions.failed_description'),
              },
              NotificationType.error,
            );
          } else {
            addNotification(
              {
                title: t('transactions:show.sections.errors.retriggerTransactions.success', {
                  count: transactionIds.length,
                }),
                subtitle: t('webedi:notifications.recover.successful_description'),
              },
              NotificationType.success,
            );
          }
        } else {
          addNotification(
            {
              title: t('transactions:show.sections.errors.retriggerTransactions.failed'),
              subtitle: t('transactions:show.sections.errors.retriggerTransactions.failed_description'),
            },
            NotificationType.error,
          );
        }
        onComplete();
      },
      onError: () => {
        addNotification(
          {
            title: t('transactions:show.sections.errors.retriggerTransactions.failed'),
            subtitle: t('transactions:show.sections.errors.retriggerTransactions.failed_description'),
          },
          NotificationType.error,
        );
      },
      onSettled: () => {
        onComplete();
      },
    });
  };

  const PrimaryButton = () => (
    <Button
      onClick={() => {
        retrigger();
      }}
      loading={isLoading}
      analyticsId="transactions:drop_submit"
    >
      <div data-testid="action-button">{t('transactions:bulk_retrigger_modal.action')}</div>
    </Button>
  );

  const CancelButton = ({ cancelButtonRef }: { cancelButtonRef: React.RefObject<HTMLButtonElement> }) => (
    <Button
      onClick={onComplete}
      disabled={isLoading}
      variant="secondary"
      ref={cancelButtonRef}
      analyticsId="transactions:drop_cancel"
    >
      <div data-testid="cancel-button">{t('transactions:show.sections.errors.retriggerTransactions.cancelText')}</div>
    </Button>
  );

  return (
    <>
      <Popup
        CancelButton={isLoading ? () => null : CancelButton}
        PrimaryButton={isLoading ? () => null : PrimaryButton}
        title={!isLoading ? t('transactions:bulk_retrigger_modal.title', { count: transactionIds.length }) : ''}
        subtitle={!isLoading ? t('transactions:bulk_retrigger_modal.subtitle') : ''}
        open={open}
        onClose={onClose}
        testId="retrigger-transaction-modal"
      >
        {isLoading && (
          <div className="flex h-full flex-col items-center justify-center text-center">
            <div className="mb-6 w-11">
              <LoadingLogo />
            </div>

            <p className="text-gray-900">{t('transactions:bulk_retrigger_modal.loading.title')}</p>
            <p className="text-gray-500">{t('transactions:bulk_retrigger_modal.loading.subtitle')}</p>
          </div>
        )}
      </Popup>
    </>
  );
}
