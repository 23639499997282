import * as Sentry from '@sentry/react';
import { isProduction } from 'support/helpers/generic/generic';

const getExtraProperties = (error: Error) =>
  Object.fromEntries(Object.entries(error).filter(([key]) => !['name', 'message', 'stack'].includes(key)));

export const logError = (error: unknown) => {
  if (!isProduction()) {
    console.error(error);
  }
  Sentry.withScope((scope) => {
    scope.setExtras(getExtraProperties(error as Error));
    Sentry.captureException(error);
  });
};
