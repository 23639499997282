import { ENV } from 'services/environment/environment';
import { MergeStats, UnmatchedLineItem, UpdateExtractionResult } from './types';
import { logError } from 'services/logging/logging';
import { DocumentType } from 'support/types';

export const recordExtractionQuality = async (documentHash: string, extractionResultUpdate: UpdateExtractionResult) => {
  try {
    await fetch(`${ENV.DOCUPARSE_API_URL}api/v1/extraction_quality/${documentHash}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ENV.DOCUPARSE_API_KEY}`,
      },
      body: JSON.stringify(extractionResultUpdate),
    });
  } catch (e: unknown) {
    logError(e);
  }
};

const MERGE_STATS_CONFIG: Record<
  DocumentType.shippingNotice,
  {
    matched: (lineItem: any) => boolean;
    unmatched: (lineItem: any) => boolean;
    missing: (lineItem: any) => boolean;
  }
> = {
  [DocumentType.shippingNotice]: {
    matched: (_lineItem: any) => {
      return true;
    },
    unmatched: (_lineItem: any) => {
      return true;
    },
    missing: (lineItem: any) => {
      return lineItem._ocr_internal?.original?.shipped_quantity !== 0;
    },
  },
};

const DEFAULT_MERGE_STATS_CONFIG = {
  matched: (_lineItem: any) => {
    return true;
  },
  unmatched: (_lineItem: any) => {
    return true;
  },
  missing: (_lineItem: any) => {
    return true;
  },
};
export const calculateMergeStats = (
  mergedData: any,
  unmatchedLineItems: Array<UnmatchedLineItem>,
  documentType: DocumentType,
): MergeStats => {
  const extractionStats = mergedData.transport_units?.reduce(
    (acc: any, transportUnit: any) => {
      const matchingFunctions =
        MERGE_STATS_CONFIG[documentType as keyof typeof MERGE_STATS_CONFIG] || DEFAULT_MERGE_STATS_CONFIG;
      const lineItems = transportUnit.line_items;
      const matched = lineItems.filter(
        (lineItem: any) => lineItem._ocr_internal.matched === true && matchingFunctions.matched(lineItem),
      ).length;
      const unmatched = lineItems.filter(
        (lineItem: any) =>
          lineItem._ocr_internal.matched === false &&
          lineItem._ocr_internal.extracted === true &&
          matchingFunctions.unmatched(lineItem),
      ).length;
      const missing = lineItems.filter(
        (lineItem: any) =>
          lineItem._ocr_internal.matched === false &&
          lineItem._ocr_internal.extracted === false &&
          matchingFunctions.missing(lineItem),
      ).length;

      return {
        matched: acc.matched + matched,
        unmatched: acc.unmatched + unmatched,
        missing: acc.missing + missing,
      };
    },
    { matched: 0, unmatched: 0, missing: 0 },
  );

  const addedUnmatchedLineItems = unmatchedLineItems.filter((unmatchedLineItem) => unmatchedLineItem.keep === 'true');

  return {
    matched: extractionStats.matched,
    added: addedUnmatchedLineItems.length,
    missing: extractionStats.missing,
  };
};
