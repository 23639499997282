import { logError } from 'services/logging/logging';
import { WebEDIError } from './webediErrors';
import { GenericError } from 'support/helpers/errors/errors';
import { WEBEDI_CELL_HEIGHT } from 'support/helpers/const/const';

const VIRTUALIZED_TABLES = ['line_items'];
export const scrollToWebEDIError = async (error: WebEDIError) => {
  let element = document.querySelector(`[data-field="${error.id}"]`);

  //if no element it means it is virtualized. We need to scroll the line items container. It's only that table that is virtualized
  if (!element) {
    const table = VIRTUALIZED_TABLES.find((table) => error.id.startsWith(table));
    if (table) {
      element = await getVirtualizedItem(error.id, table);
    } else {
      logError(new GenericError('[WebEDIV2] Failed to find element with error: ', { error }));
    }
  }

  if (element && 'focus' in element) {
    (element as HTMLInputElement).focus();
    element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  }
};

export const getVirtualizedItem = async (errorId: string, domContainerId: string): Promise<Element | null> => {
  const virtualizedContainer = getVirtualizedContainer(domContainerId);
  if (virtualizedContainer) {
    const index = getItemIndexSection(errorId);
    if (index !== -1) {
      virtualizedContainer.scrollTo(0, WEBEDI_CELL_HEIGHT * index);
    }
  }

  return new Promise((resolve) => {
    let numberOfMaximumChecks = 2;
    const intervalId = setInterval(() => {
      const element = document.querySelector(`[data-field="${errorId}"]`);
      if (element) {
        clearInterval(intervalId);
        resolve(element);
      } else if (numberOfMaximumChecks === 0) {
        clearInterval(intervalId);
        resolve(virtualizedContainer);
      } else {
        numberOfMaximumChecks--;
      }
    }, 1);
  });
};

const getVirtualizedContainer = (domContainerId: string): HTMLElement | null => {
  return document.getElementById(domContainerId);
};

const getItemIndexSection = (errorId: string): number => {
  // error.id is in the format of line_items.0.quantity
  return parseInt(errorId.split('.')[1]);
};
