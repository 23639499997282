import { ui } from '@procuros/datachecks';
import { useEnumerableConfigurations } from 'components/DataTable/cells/WebEDI/cellHelpers';
import { DisplayModes } from 'components/DataTable/Types';
import { DataDisplay } from 'components/Form/DataDisplay/DataDisplay';
import { MessageDropdown } from 'components/Form/MessageDropdown/MessageDropdown';
import { useController } from 'react-hook-form';
import { useWebEDIFieldError, useWebEDIErrors } from 'services/webediErrors/webediErrors';

type EnumerableFieldProps = {
  field: ui.EnumerableField;
  fieldId: string;
  isReadOnly: boolean;
  mode: DisplayModes;
};
export const EnumerableField = ({ field, fieldId, isReadOnly, mode }: EnumerableFieldProps) => {
  const { field: formField } = useController({ name: fieldId });
  const { error } = useWebEDIFieldError(fieldId);
  const { numberOfErrors, setCurrentError } = useWebEDIErrors();
  const { formattedValue } = useEnumerableConfigurations({
    meta: {
      optionalConfig: 'type_config' in field ? field.type_config : undefined,
      type: field.type,
    },
    value: formField.value,
  });

  if (mode === DisplayModes.view) {
    return (
      <DataDisplay
        value={formattedValue}
        label={field.label}
        totalNumberOfErrors={numberOfErrors}
        error={error}
        fieldId={fieldId}
        onShowFieldErrorModal={() => error && setCurrentError(error)}
      />
    );
  }

  return (
    <MessageDropdown
      {...formField}
      field={field}
      error={error}
      totalNumberOfErrors={numberOfErrors}
      onShowFieldErrorModal={() => error && setCurrentError(error)}
      required={field.modality === 'MANDATORY'}
      disabled={isReadOnly}
    />
  );
};
