import { CreateInvoiceAndCreditNoteSummary } from 'components/WebEDI/CreateInvoiceAndCreditNoteSummary/CreateInvoiceAndCreditNoteSummary';
import { CreateShippingNoticeSummary } from 'components/WebEDI/CreateShippingNoticeSummary/CreateShippingNoticeSummary';
import { DocumentType } from 'support/types';

export const BottomBanner = ({ documentType }: { documentType: DocumentType | undefined }) => {
  switch (documentType) {
    case DocumentType.invoice:
    case DocumentType.creditNote:
      return <CreateInvoiceAndCreditNoteSummary documentType={documentType} />;
    case DocumentType.shippingNotice:
      return <CreateShippingNoticeSummary documentType={documentType} />;
    default:
      return null;
  }
};
