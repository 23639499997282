import { createColumnHelper } from '@tanstack/react-table';
import { DeleteCell } from '../../cells/WebEDI/CreateEdit/DeleteCell';
import { ColumnTypesInternal, WebEDIRowData } from '../../Types';

export const deletionPartial = (
  quantityField: string | undefined,
  columnHelper: ReturnType<typeof createColumnHelper<WebEDIRowData>>,
) =>
  columnHelper.display({
    id: ColumnTypesInternal.procuros_delete,
    meta: {
      type: ColumnTypesInternal.procuros_delete,
      optionalConfig: {
        quantityField,
      },
    },
    cell: DeleteCell,
  });
