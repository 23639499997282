import { TextCell } from './cells/WebEDI/CreateEdit/TextCell/TextCell';
import { EnumerableCell } from './cells/WebEDI/CreateEdit/EnumerableCell';
import { ui } from '@procuros/datachecks';
import { ViewURLCell } from './cells/WebEDI/View/ViewURLCell';
import { BooleanCell } from './cells/WebEDI/CreateEdit/BooleanCell';
import { DateCell } from './cells/WebEDI/CreateEdit/DateCell';
import { NumberCell } from './cells/WebEDI/CreateEdit/NumberCell';
import { MonetaryCell } from './cells/WebEDI/CreateEdit/MonetaryCell';
import { ViewTextCell } from './cells/WebEDI/View/ViewTextCell';
import { ViewBooleanCell } from './cells/WebEDI/View/ViewBooleanCell';
import { ViewDateCell } from './cells/WebEDI/View/ViewDateCell';
import { ViewMonetaryCell } from './cells/WebEDI/View/ViewMonetaryCell';
import { ViewNumberCell } from './cells/WebEDI/View/ViewNumberCell';
import { ViewEnumerableCell } from './cells/WebEDI/View/ViewEnumerableCell';

/**
 * Map object which connects available column types to actual column cells.
 * Each connection has two components for view and edit modes.
 */

type ViewCells =
  | typeof ViewTextCell
  | typeof ViewURLCell
  | typeof ViewBooleanCell
  | typeof ViewNumberCell
  | typeof ViewMonetaryCell
  | typeof ViewDateCell
  | typeof ViewEnumerableCell;

type CreateEditCells =
  | typeof TextCell
  | typeof BooleanCell
  | typeof NumberCell
  | typeof DateCell
  | typeof EnumerableCell;

export const cellMapping: Record<ui.FieldTypes, [ViewCells, CreateEditCells] | []> = {
  [ui.FieldTypes.text]: [ViewTextCell, TextCell],
  [ui.FieldTypes.url]: [ViewURLCell, TextCell],
  [ui.FieldTypes.boolean]: [ViewBooleanCell, BooleanCell],
  [ui.FieldTypes.number]: [ViewNumberCell, NumberCell],
  [ui.FieldTypes.monetary]: [ViewMonetaryCell, MonetaryCell],
  [ui.FieldTypes.date]: [ViewDateCell, DateCell],
  [ui.FieldTypes.date_time]: [ViewDateCell, DateCell],
  [ui.FieldTypes.select]: [ViewEnumerableCell, EnumerableCell],
  [ui.FieldTypes.multi_select]: [ViewEnumerableCell, EnumerableCell],
  [ui.FieldTypes.multi_column]: [],
};
