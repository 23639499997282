import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/Display/Tooltip/Tooltip';
import { Checkbox } from 'components/Form/Checkbox/Checkbox';
import { useTranslation } from 'react-i18next';
import { DocumentType } from 'support/types';
import { DocumentTypeSetting } from '../helpers';
import { deepCopy } from 'support/helpers/generic/generic';

type DocumentSettingsCheckboxesProps = {
  documentTypeSettings: Array<DocumentTypeSetting>;
  setDocumentTypeSettings: (updatedSettings: Array<DocumentTypeSetting>) => void;
};
export function DocumentSettingsCheckboxes({
  documentTypeSettings,
  setDocumentTypeSettings,
}: DocumentSettingsCheckboxesProps) {
  const { t } = useTranslation();
  const allDocumentTypes = Object.values(DocumentType);

  return (
    <div>
      <div className="grid grid-cols-3 gap-4 pb-3 text-sm font-medium text-gray-700">
        <div>{t('partners:request.documentTypes')}</div>
        <div className="flex justify-end gap-x-1">
          <span>{t('partners:request.incoming')}</span>
          <Tooltip>
            <TooltipTrigger>
              <InformationCircleIcon className="size-5 text-gray-400" />
            </TooltipTrigger>
            <TooltipContent>{t('partners:request.incomingTooltip')}</TooltipContent>
          </Tooltip>
        </div>
        <div className="flex justify-end gap-x-1">
          <span>{t('partners:request.outgoing')}</span>
          <Tooltip>
            <TooltipTrigger>
              <InformationCircleIcon className="size-5 text-gray-400" />
            </TooltipTrigger>
            <TooltipContent>{t('partners:request.outgoingTooltip')}</TooltipContent>
          </Tooltip>
        </div>
      </div>

      {allDocumentTypes.map((document, index) => {
        if (document === DocumentType.unknown) {
          return null;
        }

        const currentDocumentIndex = documentTypeSettings.findIndex(
          (documentSetting) => documentSetting.documentType === document,
        );
        const currentDocument = documentTypeSettings[currentDocumentIndex];

        if (!currentDocument) {
          return null;
        }
        // t('common:messageTypes.plural.QUOTE_REQUEST')
        // t('common:messageTypes.plural.QUOTE')
        // t('common:messageTypes.plural.ORDER')
        // t('common:messageTypes.plural.ORDER_RESPONSE')
        // t('common:messageTypes.plural.DISPATCH_INSTRUCTION')
        // t('common:messageTypes.plural.DISPATCH_INSTRUCTION_RESPONSE')
        // t('common:messageTypes.plural.SHIPPING_NOTICE')
        // t('common:messageTypes.plural.INVOICE')
        // t('common:messageTypes.plural.CREDIT_NOTE')
        // t('common:messageTypes.plural.PRODUCT_CATALOG')
        // t('common:messageTypes.plural.RECEIVAL_NOTICE')
        // t('common:messageTypes.plural.REMITTANCE_ADVICE')
        // t('common:messageTypes.plural.ACKNOWLEDGEMENT')
        // t('common:messageTypes.plural.UNKNOWN')

        return (
          <div key={`${document}-${index}`} className="grid grid-cols-3 gap-4 pb-3 text-sm text-gray-400">
            <div className="whitespace-nowrap text-sm font-normal text-gray-500">
              {t('common:messageTypes.plural.' + document)}
            </div>
            <div className="flex justify-end pr-0.5">
              {currentDocument && (
                <Checkbox
                  label=""
                  disabled={currentDocument.incoming === null}
                  checked={currentDocument.incoming !== false}
                  onChange={(e) => {
                    const updatedDocumentTypeSettings: Array<DocumentTypeSetting> = deepCopy(documentTypeSettings);

                    updatedDocumentTypeSettings.splice(currentDocumentIndex, 1, {
                      ...currentDocument,
                      incoming: e.target.checked,
                    });
                    setDocumentTypeSettings(updatedDocumentTypeSettings);
                  }}
                />
              )}
            </div>
            <div className="flex justify-end pr-0.5">
              {currentDocument && (
                <Checkbox
                  label=""
                  disabled={currentDocument.outgoing === null}
                  checked={currentDocument.outgoing !== false}
                  onChange={(e) => {
                    const updatedDocumentTypeSettings: Array<DocumentTypeSetting> = deepCopy(documentTypeSettings);

                    updatedDocumentTypeSettings.splice(currentDocumentIndex, 1, {
                      ...currentDocument,
                      outgoing: e.target.checked,
                    });
                    setDocumentTypeSettings(updatedDocumentTypeSettings);
                  }}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
