import { ui } from '@procuros/datachecks';
import { DisplayModes } from 'components/DataTable/Types';
import { DataDisplay } from 'components/Form/DataDisplay/DataDisplay';
import { MessageInput } from 'components/Form/MessageInput/MessageInput';
import { useWatch, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useWebEDIFieldError, useWebEDIErrors } from 'services/webediErrors/webediErrors';

type BooleanFieldProps = {
  field: ui.BooleanField;
  fieldId: string;
  isReadOnly: boolean;
  mode: DisplayModes;
};
export const BooleanField = ({ field, fieldId, isReadOnly, mode }: BooleanFieldProps) => {
  const { t } = useTranslation();
  const value = useWatch({ name: fieldId });
  const { register } = useFormContext();
  const { error } = useWebEDIFieldError(fieldId);
  const { numberOfErrors, setCurrentError } = useWebEDIErrors();

  if (mode === DisplayModes.view) {
    return (
      <DataDisplay
        value={value ? t('common:yes') : t('common:no')}
        label={field.label}
        totalNumberOfErrors={numberOfErrors}
        error={error}
        fieldId={fieldId}
        onShowFieldErrorModal={() => error && setCurrentError(error)}
      />
    );
  }
  return (
    <MessageInput
      type="checkbox"
      label={field.label}
      error={error}
      totalNumberOfErrors={numberOfErrors}
      {...register(fieldId)}
      value={value}
      onShowFieldErrorModal={() => error && setCurrentError(error)}
      required={field.modality === 'MANDATORY'}
      disabled={isReadOnly}
    />
  );
};
