import { useClose, DialogTitle, Description } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { ui } from '@procuros/datachecks';
import { createColumnHelper } from '@tanstack/react-table';
import { EnumerableCell, DataTable } from 'components/DataTable';
import { HeaderCell } from 'components/DataTable/cells/HeaderCell';
import { useDataTableContext, DataTableProvider } from 'components/DataTable/contexts/DataTableContext';
import { DisplayModes, WebEDIRowData } from 'components/DataTable/Types';
import { Button } from 'components/Form/Button';
import { Translate } from 'components/Translate/Translate';
import { useMemo, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UnmatchedLineItem } from '../../../types';
import { ViewTextCell } from 'components/DataTable/cells/WebEDI/View/ViewTextCell';
import { ViewNumberCell } from 'components/DataTable/cells/WebEDI/View/ViewNumberCell';

const LineItemsTable = () => {
  const { fields, setValue } = useDataTableContext();
  const { t } = useTranslation();
  const columnHelper = useMemo(() => createColumnHelper<WebEDIRowData>(), []);
  const columns = useMemo(
    () => [
      columnHelper.accessor('item.item.identifiers.gtin', {
        id: 'gtin',

        header: (props) => (
          <HeaderCell {...props} mode={props.table.options.meta?.mode}>
            {t('webedi:documentExtractor.pickLineItems.columns.gtin')}
          </HeaderCell>
        ),
        cell: (props) => (
          <ViewTextCell
            isHighlighted={false}
            {...props}
            fieldId={`data.${props.row.id}.item.item.identifiers.gtin`}
            key="gtin"
          />
        ),
      }),
      columnHelper.accessor('item.item.name', {
        id: 'name',
        header: (props) => (
          <HeaderCell {...props} mode={props.table.options.meta?.mode}>
            {t('webedi:documentExtractor.pickLineItems.columns.name')}
          </HeaderCell>
        ),
        cell: (props) => (
          <ViewTextCell isHighlighted={false} {...props} fieldId={`data.${props.row.id}.item.item.name`} key="name" />
        ),
      }),
      columnHelper.accessor('item.shipped_quantity', {
        id: 'shipped_quantity',
        header: (props) => (
          <HeaderCell {...props} mode={props.table.options.meta?.mode}>
            {t('webedi:documentExtractor.pickLineItems.columns.shippedQuantity')}
          </HeaderCell>
        ),
        cell: (props) => (
          <ViewNumberCell
            isHighlighted={false}
            {...props}
            fieldId={`data.${props.row.id}.item.shipped_quantity`}
            key="shipped_quantity"
          />
        ),
      }),
      columnHelper.accessor('keep', {
        id: 'keep',
        header: (props) => (
          <HeaderCell {...props} required mode={props.table.options.meta?.mode}>
            {t('webedi:documentExtractor.pickLineItems.columns.action')}
          </HeaderCell>
        ),
        cell: (props) => (
          <EnumerableCell
            isHighlighted={false}
            mode={DisplayModes.view}
            {...props}
            fieldId={`data.${props.row.id}.keep`}
            key="keep"
          />
        ),
        meta: {
          type: ui.FieldTypes.select,
          optionalConfig: {
            options: [
              { value: 'true', label: t('webedi:documentExtractor.pickLineItems.keep') },
              { value: 'false', label: t('webedi:documentExtractor.pickLineItems.discard') },
            ],
          },
        },
      }),
    ],
    [columnHelper, t],
  );

  return (
    <DataTable
      data={fields}
      enableGlobalSearch={false}
      meta={{ dataPrefix: 'data', mode: DisplayModes.edit }}
      columns={columns}
      headerMenu={
        <div className="flex flex-1 gap-2">
          <div className="mr-auto text-xs font-medium uppercase tracking-wide text-gray-500">
            {t('webedi:documentExtractor.pickLineItems.itemCount', {
              count: fields.length,
            })}
          </div>
          <Button
            variant="secondary"
            size="extra-small"
            onClick={() =>
              setValue(
                undefined,
                fields.map((field) => ({ ...field, keep: 'true' })),
              )
            }
            analyticsId="document-extractor:keep-all"
          >
            {t('webedi:documentExtractor.pickLineItems.keepAll')}
          </Button>
          <Button
            variant="secondary"
            size="extra-small"
            onClick={() =>
              setValue(
                undefined,
                fields.map((field) => ({ ...field, keep: 'false' })),
              )
            }
            analyticsId="document-extractor:discard-all"
          >
            {t('webedi:documentExtractor.pickLineItems.discardAll')}
          </Button>
        </div>
      }
    />
  );
};

type PickLineItemsStageProps = {
  onBack: () => void;
  onApply: (data: any) => void;
  unmatchedLineItems: Array<UnmatchedLineItem>;
};

export const PickLineItemsStage = ({ unmatchedLineItems, onBack, onApply }: PickLineItemsStageProps) => {
  const { t } = useTranslation();
  const close = useClose();
  const formMethods = useForm({ defaultValues: { data: unmatchedLineItems } });
  const onSubmit = useCallback(
    (data: any) => {
      onApply(data.data);
    },
    [onApply],
  );

  return (
    <FormProvider {...formMethods}>
      <DataTableProvider name="data">
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <div className="mb-6 flex items-start justify-between">
            <div>
              <DialogTitle as="h3" className="text-lg font-medium text-gray-900">
                {t('webedi:documentExtractor.pickLineItems.title')}
              </DialogTitle>
              <Description className="mt-2 text-sm leading-tight text-gray-500">
                <Translate i18nKey="webedi:documentExtractor.pickLineItems.subTitle" />
              </Description>
            </div>
            <Button
              variant="minimal"
              iconOnly
              size="base"
              LeftIcon={XMarkIcon}
              onClick={close}
              analyticsId="document-extractor:close"
            />
          </div>
          <div>
            <LineItemsTable />
          </div>
          <div className="mt-5 flex justify-end gap-2 sm:mt-6">
            <Button type="button" variant="secondary" onClick={onBack} analyticsId="document-extractor:back">
              {t('common:back')}
            </Button>
            <Button type="submit" analyticsId="document-extractor:next">
              {t('common:next')}
            </Button>
          </div>
        </form>
      </DataTableProvider>
    </FormProvider>
  );
};
