import { CellContext } from '@tanstack/react-table';
import { CellProps } from '../../types';
import { DropdownCell } from './DropdownCell';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { WebEDIRowData } from 'components/DataTable/Types';

export type Props = CellContext<WebEDIRowData, unknown> & CellProps;

export const BooleanCell = (props: Props) => {
  const { t } = useTranslation();
  const booleanCellOptions = useMemo(
    () => [
      { label: t('common:yes'), value: true },
      { label: t('common:no'), value: false },
    ],
    [t],
  );
  return <DropdownCell {...props} options={booleanCellOptions} />;
};
