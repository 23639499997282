import { CellContext } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { classNames } from 'support/helpers/generic/generic';
import { EMPTY_FIELD } from 'support/helpers/const/const';
import { useValue } from 'components/WebEDI/helpers';
import { cloneElement, useMemo } from 'react';
import useIsTruncated from 'hooks/useIsTruncated';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/Display/Tooltip/Tooltip';
import { ErrorIcon } from 'components/DataTable/components/Icons';
import { useCellClassNames, getCellNestedLevel } from '../cellHelpers';
import { NestedLevelAddon } from '../components/NestedLevelAddon/NestedLevelAddon';
import { CellProps } from '../../types';
import { ViewWrapperCell } from './ViewWrapperCell';
import { useWebEDIFieldError } from 'services/webediErrors/webediErrors';
import { WebEDIRowData } from 'components/DataTable/Types';

export type Props = CellContext<WebEDIRowData, unknown> & Pick<CellProps, 'fieldId' | 'isHighlighted'>;

export const ViewTextCell = ({ row, cell, column, table, isHighlighted, fieldId }: Props) => {
  const { t } = useTranslation();
  const { error } = useWebEDIFieldError(fieldId);
  const value = useValue(row.original, fieldId, column.columnDef.meta?.optionalConfig?.expression);
  const {
    columnDef: { meta: columnMeta },
  } = column;

  const formatValue = useMemo(
    () => columnMeta?.optionalConfig?.translateValue || ((value: any) => value),
    [columnMeta?.optionalConfig?.translateValue],
  );

  const cellClassNames = useCellClassNames({
    row,
    table,
    isFirstColumn: column.getIsFirstColumn(),
    isLastColumn: column.getIsLastColumn(),
  });
  const cellNestedLevel = columnMeta?.showNestLevel ? getCellNestedLevel(row) : 0;
  const [truncatedRef, valueIsTruncated] = useIsTruncated();

  return (
    <ViewWrapperCell className={cellClassNames} isHighlighted={isHighlighted} fieldId={fieldId}>
      <Tooltip enabled={valueIsTruncated}>
        <TooltipTrigger>
          <span className="flex w-full items-center">
            <div
              className={classNames('flex w-full gap-x-0.5 overflow-hidden', {
                'justify-start': columnMeta?.optionalConfig?.textAlign === 'left',
              })}
              style={{ width: columnMeta?.optionalConfig?.width }}
            >
              {cellNestedLevel > 1 ? (
                <span className="pr-1.5">
                  <NestedLevelAddon level={cellNestedLevel} />
                </span>
              ) : null}
              {!value && error ? (
                t('common:table.errors.requiredField')
              ) : value ? (
                <span className="flex-initial truncate" ref={truncatedRef}>
                  {formatValue(value)}
                </span>
              ) : (
                <span className="text-gray-400">{EMPTY_FIELD}</span>
              )}
            </div>
            {columnMeta?.rightAddon && !error ? (
              <span className="text-gray-500">{cloneElement(columnMeta.rightAddon, { cell })}</span>
            ) : null}
          </span>
        </TooltipTrigger>
        <TooltipContent>{formatValue(value)}</TooltipContent>
      </Tooltip>
      {error ? <ErrorIcon /> : null}
    </ViewWrapperCell>
  );
};
