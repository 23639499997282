import { useVirtualizer as useTanstackVirtual, notUndefined } from '@tanstack/react-virtual';
import { Row } from '@tanstack/react-table';
import { useRef } from 'react';
import { DataRenderModes } from '../Types';
import { WEBEDI_CELL_HEIGHT } from 'support/helpers/const/const';

export type Props<TData> = {
  rows: Array<Row<TData>>;
  overscan: number;
  dataRenderMode: keyof typeof DataRenderModes;
};

export const useVirtualizer = <TData>({ rows, overscan, dataRenderMode }: Props<TData>) => {
  const parentRef = useRef(null);
  const virtualizer = useTanstackVirtual({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => WEBEDI_CELL_HEIGHT,
    overscan,
    enabled: dataRenderMode === DataRenderModes.virtualized,
  });
  const virtualRows = virtualizer.getVirtualItems();
  /** Fix for sticky header */
  const [before, after] =
    virtualRows.length > 0
      ? [
          notUndefined(virtualRows[0]).start - virtualizer.options.scrollMargin,
          virtualizer.getTotalSize() - notUndefined(virtualRows[virtualRows.length - 1]).end,
        ]
      : [0, 0];
  return { parentRef, virtualizer, virtualRows, before, after };
};
