import { BasePopupV2 } from 'components/Display/BasePopup/BasePopupV2';
import { OcrResult } from './types';
import { ProcessSpecificationDTO, RelationshipV2 } from 'support/types';
import { isBetaOCR } from 'support/helpers/ocr/ocr';
import { BetaExtractor } from './components/BetaExtractor/BetaExtractor';
import { Extractor } from './components/Extractor/Extractor';

type DocumentExtractorProps = {
  senderProcessSpecification: ProcessSpecificationDTO;
  targetRelationship: RelationshipV2;
  onApplyOcrExtraction: (result: OcrResult) => void;
  setOpen: (open: boolean) => void;
  open: boolean;
};

export const DocumentExtractor = ({
  open,
  setOpen,
  senderProcessSpecification,
  targetRelationship,
  onApplyOcrExtraction,
}: DocumentExtractorProps) => {
  const isBeta = isBetaOCR(senderProcessSpecification);
  return (
    <BasePopupV2 setOpen={setOpen} open={open} width={isBeta ? 'fit-content' : 'xl'} shouldGrowIndefinitely>
      {isBeta ? (
        <BetaExtractor
          senderProcessSpecification={senderProcessSpecification}
          targetRelationship={targetRelationship}
          setOpen={setOpen}
        />
      ) : (
        <Extractor
          senderProcessSpecificationFields={senderProcessSpecification?.fields}
          targetRelationship={targetRelationship}
          onApplyOcrExtraction={onApplyOcrExtraction}
          setOpen={setOpen}
        />
      )}
    </BasePopupV2>
  );
};
