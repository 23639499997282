import { CatalogPartnerListItem } from 'pages/PriceCatalogs/PriceCatalogsPage';
import { DocumentType } from 'support/types';
import { components } from 'support/types/schema-portal';
import { diffInMinutes } from 'support/helpers/dateTime/dateTime';

type NotificationLog = components['schemas']['NotificationLog'];
export type NotificationLogGroup = Array<NotificationLog>;

/**
 * Groups notification logs that were created within the specified time interval
 * Used to show when price catalogs were last requested and who they were sent to
 *
 * @param item - The catalog partner list item containing relationship data
 * @param intervalInMinutes - Time interval to group notifications (in minutes)
 * @returns Array of notification logs in the most recent group, or null if none exist
 */
export const lastPriceCatalogNotificationLogGroup = (
  item: CatalogPartnerListItem,
  intervalInMinutes: number,
): NotificationLogGroup | null => {
  const productCatalogRelationship = item.partner.sender_relationships?.find(
    (relationship) => relationship.message_type === DocumentType.productCatalog,
  );

  if (!productCatalogRelationship?.notification_logs) {
    return null;
  }

  const lastGroupOfNotifications: NotificationLogGroup = [];
  const sortedRequestDocumentNotificationLogs = productCatalogRelationship.notification_logs
    .filter((log) => log.notification_name === 'RequestDocumentNotification')
    .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

  sortedRequestDocumentNotificationLogs.forEach((notification) => {
    if (
      lastGroupOfNotifications.length === 0 ||
      diffInMinutes(
        notification.created_at,
        new Date(lastGroupOfNotifications[lastGroupOfNotifications.length - 1].created_at),
      ) < intervalInMinutes
    ) {
      lastGroupOfNotifications.push(notification);
    }
  });

  return lastGroupOfNotifications.length > 0 ? lastGroupOfNotifications : null;
};
