import { useEffect } from 'react';
import { UseFormWatch } from 'react-hook-form';
import { useDebounceValue } from 'usehooks-ts';

export const useDebouncedWatch = (watch: UseFormWatch<any>, debounceTime: number): any => {
  const [value, setValue] = useDebounceValue<any>(undefined, debounceTime);

  useEffect(() => {
    const { unsubscribe } = watch(setValue);
    return unsubscribe;
  }, [watch, setValue]);

  return value;
};
