import { DashboardSection } from '../components/DashboardSection';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { routeToPage } from 'support/helpers/navigation/navigation';
import { InboxRoutes, InboxStatsDTO } from 'support/types';

// t('inbox:overview.pendingOrders')
// t('inbox:overview.pendingOrderResponses')
// t('inbox:overview.pendingShippingNotices')
// t('inbox:overview.pendingInvoices')
const InboxOverviewItems = [
  {
    key: 'pendingOrders',
    label: 'inbox:overview.pendingOrders',
  },
  {
    key: 'pendingOrderResponses',
    label: 'inbox:overview.pendingOrderResponses',
  },
  {
    key: 'pendingShippingNotices',
    label: 'inbox:overview.pendingShippingNotices',
  },
  {
    key: 'pendingInvoices',
    label: 'inbox:overview.pendingInvoices',
  },
];

export const InboxOverview = ({ inboxStatsData }: { inboxStatsData: InboxStatsDTO }) => {
  const { t } = useTranslation();

  return (
    <DashboardSection title={t('inbox:list.title')}>
      <ul className="-mt-5 flex w-full flex-col">
        {InboxOverviewItems.map((item) => (
          <li key={item.key} className="group border-b hover:bg-procuros-green-50">
            <Link to={routeToPage(InboxRoutes.root, {}, { type: item.key })} className="block space-x-2 px-2 py-4">
              <span className="font-bold text-gray-900 group-hover:text-procuros-green-900">
                {inboxStatsData.data[item.key as keyof typeof inboxStatsData.data]}
              </span>
              <span className="text-gray-500 group-hover:text-procuros-green-900">{t(item.label)}</span>
            </Link>
          </li>
        ))}
      </ul>
    </DashboardSection>
  );
};
