import { Filter } from 'components/ListTable/types';
import { createInfiniteQuery, createMutation, createQuery } from 'react-query-kit';
import { buildFilterUrl, withParameter } from 'support/helpers/urls/urls';
import { IntegrationDTO, PaginationDTO } from 'support/types';
import { createData, fetchData, queryClient } from '../../http/http';
import { useCurrentPartner } from '../partners/partners';
import portalSchema from 'support/types/schema-portal';

type IntegrationsResponseDTO = PaginationDTO<IntegrationDTO>;

type GetIntegrationResponse =
  portalSchema.paths['/v1/integrations/{integrationId}']['get']['responses']['200']['content']['application/json'];

type CreateIntegrationPayload =
  portalSchema.paths['/v1/integrations']['post']['requestBody']['content']['application/json'];

type CreateIntegrationResponse =
  portalSchema.paths['/v1/integrations']['post']['responses']['201']['content']['application/json'];

type CreateIntegrationRequestPayload =
  portalSchema.paths['/v1/integrations/request-new']['post']['requestBody']['content']['application/json'];

type CreateIntegrationRequestResponse =
  portalSchema.paths['/v1/integrations/request-new']['post']['responses']['201']['content']['application/json'];

type GetIntegrationProcessesResponse =
  portalSchema.paths['/v1/integrations/{integrationId}/processes']['get']['responses']['200']['content']['application/json'];

export const useInfiniteIntegrations = createInfiniteQuery<IntegrationsResponseDTO, { query?: Filter }, Error>({
  primaryKey: '/integrations',
  queryFn: async ({ queryKey: [, variables], pageParam }) => {
    const urlParams = buildFilterUrl({ ...variables?.query, cursor: pageParam });
    const { data } = await fetchData<IntegrationsResponseDTO>('/v1/integrations' + urlParams);

    return data;
  },
  getNextPageParam: (lastPage) => lastPage.nextCursor,
  getPreviousPageParam: (_, allPages) => allPages.at(-1)?.previousCursor,
});

export const useCreateIntegrationRequest = createMutation<
  CreateIntegrationRequestResponse,
  CreateIntegrationRequestPayload,
  Error
>({
  mutationFn: async (data) => {
    const response = await createData<CreateIntegrationRequestResponse>('/v1/integrations/request-new', data);
    return response.data;
  },
  onSuccess: async () => queryClient.invalidateQueries(useInfiniteIntegrations.getKey()),
});

export const useIntegration = createQuery<GetIntegrationResponse, { id?: string; withConfiguration?: boolean }, Error>({
  primaryKey: '/integrations',
  queryFn: async ({ queryKey: [, variables] }) => {
    const withParameters = [];
    if (variables.withConfiguration) {
      withParameters.push('configuration');
    }

    const { data } = await fetchData<IntegrationDTO>(
      '/v1/integrations/' + variables.id + withParameter(withParameters),
    );
    return data;
  },
  enabled: (data, variables) => !!variables.id,
});

export const useCreateIntegration = createMutation<CreateIntegrationResponse, CreateIntegrationPayload, Error>({
  mutationFn: async (data) => {
    const response = await createData<CreateIntegrationResponse>('/v1/integrations', data);

    queryClient.setQueryData(useIntegration.getKey({ id: response.data.id, withConfiguration: true }), response.data);
    return response.data;
  },
  onSuccess: async () =>
    Promise.all([
      queryClient.invalidateQueries(useCurrentPartner.getKey()),
      queryClient.invalidateQueries(useInfiniteIntegrations.getKey()),
    ]),
});

export const useIntegrationProcesses = createQuery<GetIntegrationProcessesResponse, { id?: string }, Error>({
  primaryKey: '/integrations/processes',
  queryFn: async ({ queryKey: [, variables] }) => {
    const { data } = await fetchData<GetIntegrationProcessesResponse>(
      '/v1/integrations/' + variables.id + '/processes',
    );
    return data;
  },
  enabled: (data, variables) => !!variables.id,
});

type CreateOCRConfigurationRequestRequestParameters =
  portalSchema.paths['/v1/integrations/{integrationId}/processes/{processId}/ocr-configuration-request']['post']['parameters']['path'];

type CreateOCRConfigurationRequestRequestResponse =
  portalSchema.paths['/v1/integrations/{integrationId}/processes/{processId}/ocr-configuration-request']['post']['responses']['200']['content']['application/json'];
export const useCreateOCRConfigurationRequest = createMutation<
  CreateOCRConfigurationRequestRequestResponse,
  CreateOCRConfigurationRequestRequestParameters,
  Error
>({
  mutationFn: async (data) => {
    const response = await createData<CreateOCRConfigurationRequestRequestResponse>(
      `/v1/integrations/${data.integrationId}/processes/${data.processId}/ocr-configuration-request`,
      {},
    );

    return response.data;
  },
});
