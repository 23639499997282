import { Filter } from 'components/ListTable/types';
import { ConnectionsFilterKey } from 'pages/Connections/tableConfig';
import { createInfiniteQuery, createMutation, createQuery } from 'react-query-kit';
import { patchData, queryClient } from 'services/http/http';
import { resetMessageQueries } from 'support/helpers/repositories/repositories';
import { determineInternalType } from 'support/helpers/transactions/transactions';
import { buildFilterUrl } from 'support/helpers/urls/urls';
import {
  DocumentType,
  ErrorDialog,
  PaginationDTO,
  PartnerV2,
  RelationshipV2,
  TransactionDTO,
  TransactionInternalType,
} from 'support/types';
import portalSchema from 'support/types/schema-portal';
import { createData, fetchData } from '../../http/http';
import { getMessageOrEnvelope } from '../helpers/helpers';
import { useConnectedPartners, useInfinitePartners, useTradeRequestLastMessage } from '../partners/partners';
import { useConnectionsStats } from '../stats/stats';
import { useInfiniteTransactions, useMessages } from '../transactions/transactions';

export type CreateTradeRequestRequest =
  portalSchema.paths['/v1/partners/trade-requests']['post']['requestBody']['content']['application/json'];

type CreateTradeRequestResponse =
  portalSchema.paths['/v1/partners/trade-requests']['post']['responses']['201']['content']['application/json'];

type GetRelationshipDocumentCreationConfig =
  portalSchema.paths['/v1/relationships/{relationship_id}/sender_envelopes/new/{source_message_id}']['get']['responses']['200']['content']['application/json'];

export type RelationshipsResponseDTO =
  portalSchema.paths['/v1/relationships']['get']['responses']['200']['content']['application/json'];

export type ConnectionsResponseDTO =
  portalSchema.paths['/v1/connections']['get']['responses']['200']['content']['application/json'];

type RelationshipResponseDTO =
  portalSchema.paths['/v1/relationships/{relationshipId}']['get']['responses']['200']['content']['application/json'];

type DeactiveRelationshipResponseDTO =
  portalSchema.paths['/v1/relationships/{relationshipId}/deactivate']['post']['responses']['200']['content']['application/json'];

type EditInternalIdentifiersRequest =
  portalSchema.paths['/v1/relationships/{relationshipId}/edit-internal-identifiers']['post']['requestBody']['content']['application/json'];

type EditInternalIdentifiersResponse =
  portalSchema.paths['/v1/relationships/{relationshipId}/edit-internal-identifiers']['post']['responses']['200']['content']['application/json'];

type ResendEmailInvitationRequest = Required<
  portalSchema.paths['/v1/partners/{partnerId}/resend-trade-request-invitation-email']['post']
>['requestBody']['content']['application/json'];

type ResendEmailInvitationResponse =
  portalSchema.paths['/v1/partners/{partnerId}/resend-trade-request-invitation-email']['post']['responses']['200']['content']['application/json'];

export const useCreateTradeRequest = createMutation<CreateTradeRequestResponse, CreateTradeRequestRequest, Error>({
  mutationFn: async (payload) => {
    const { data } = await createData<CreateTradeRequestResponse>('/v1/partners/trade-requests', payload);
    return data;
  },
  onSuccess: () => {
    return Promise.all([
      queryClient.invalidateQueries(useTradeRequestLastMessage.getKey()),
      queryClient.invalidateQueries(useInfiniteConnections.getKey()),
      queryClient.invalidateQueries(useInfinitePartners.getKey()),
      queryClient.invalidateQueries(useConnectedPartners.getKey()),
    ]);
  },
});

export const useInfiniteConnections = createInfiniteQuery<ConnectionsResponseDTO, { query?: Filter }, Error>({
  primaryKey: '/connections',
  queryFn: async ({ queryKey: [, variables], pageParam }) => {
    const urlParams = buildFilterUrl({ filterValues: [], ...variables.query, cursor: pageParam });

    const { data } = await fetchData<ConnectionsResponseDTO>('/v1/connections' + urlParams);

    return data;
  },
  getNextPageParam: (lastPage) => {
    return lastPage.meta.next_cursor;
  },
  getPreviousPageParam: (_, allPages) => allPages.at(-1)?.meta.prev_cursor,
});

export const useRelationship = createQuery<RelationshipResponseDTO['data'], { relationshipId?: string }, Error>({
  primaryKey: '/relationships/:relationshipId',
  queryFn: async ({ queryKey: [, variables] }) => {
    const { data } = await fetchData<RelationshipResponseDTO>('/v1/relationships/' + variables.relationshipId);

    return data?.data;
  },
  enabled: (_, variables) => !!variables.relationshipId,
});

export const useRelationshipDocumentCreationConfig = createQuery<
  GetRelationshipDocumentCreationConfig['data'],
  { relationshipId: string | undefined; query: { source_message_id: string | undefined } },
  Error
>({
  primaryKey: '/relationships/:relationshipId/sender_envelopes/new',
  queryFn: async ({ queryKey: [, variables] }) => {
    const { data } = await fetchData<GetRelationshipDocumentCreationConfig>(
      `/v1/relationships/${variables.relationshipId}/sender_envelopes/new/${variables.query.source_message_id ?? ''}`,
    );
    return data?.data;
  },
  enabled: (data, variables) => {
    return !!variables.relationshipId && !data;
  },
});

export const useRelationshipDocumentCreation = createMutation<any, any, Error>({
  mutationFn: async ({ relationshipId, data, waitForMessage = true }) => {
    const response = await createData<any>(`/v1/relationships/${relationshipId}/sender_envelopes`, { data });

    if (![200, 201].includes(response.status) || !response.data || !('procuros_transaction_id' in response.data.data)) {
      switch (response.status) {
        case 202:
          throw new Error('message' in response.data ? response.data.message : ErrorDialog.internalServer);
        default:
          // Should not happen. Any non-2XX response should throw an error earlier
          throw new Error(ErrorDialog.internalServer);
      }
    }

    const createdSenderEnvelopeId = response.data.data.procuros_transaction_id;
    return await getMessageOrEnvelope(createdSenderEnvelopeId, waitForMessage);
  },
  onSuccess: (data, variables) =>
    /*
     * We are not invalidating the related transactions because we don't have access to them
     */
    Promise.all([
      queryClient.invalidateQueries(useInfiniteTransactions.getKey()),
      queryClient.invalidateQueries(useMessages.getKey()),
      ...(data.transactionType === TransactionInternalType.message
        ? [resetMessageQueries(queryClient, data.transactionId)]
        : []),
      queryClient.invalidateQueries(useRelationship.getKey({ relationshipId: variables.relationshipId })),
    ]),
});

export const useInfiniteRelationships = createInfiniteQuery<RelationshipsResponseDTO, { query?: Filter }, Error>({
  primaryKey: '/relationships',
  queryFn: async ({ queryKey: [_, variables], pageParam }) => {
    const urlParams = buildFilterUrl({ filterValues: [], ...variables.query, cursor: pageParam });
    const { data } = await fetchData<RelationshipsResponseDTO>('/v1/relationships' + urlParams);

    return data;
  },
  getNextPageParam: (lastPage) => {
    return lastPage.meta.next_cursor;
  },
  getPreviousPageParam: (_, allPages) => allPages.at(-1)?.meta.prev_cursor,
});

export const useResendEmailInvitation = createMutation<
  ResendEmailInvitationResponse,
  { partnerId: string } & ResendEmailInvitationRequest,
  Error
>({
  mutationFn: async (variables) => {
    const { data } = await createData<ResendEmailInvitationResponse>(
      `/v1/partners/${variables.partnerId}/resend-trade-request-invitation-email`,
      {
        language: variables.language,
        email: variables.email,
        name: variables.name,
      },
    );
    return data;
  },
  onSuccess: (_, variables) => {
    queryClient.setQueryData(
      useInfiniteConnections.getKey({
        query: {
          filterValues: [{ key: ConnectionsFilterKey.partnerId, value: variables.partnerId }],
          perPage: '1',
        },
      }),
      (data: any) => {
        const updatedPartner = data.pages
          .flatMap((page: any) => page.data)
          .find((partner: PartnerV2) => partner.id === variables.partnerId);
        if (updatedPartner.sender_relationships) {
          updatedPartner.sender_relationships = updatedPartner.sender_relationships.map(
            (relationship: RelationshipV2) => {
              return {
                ...relationship,
                trade_request: {
                  ...relationship.trade_request,
                  id: '',
                  last_email_sent: {
                    id: '',
                    created_at: new Date().toISOString(),
                    updated_at: new Date().toISOString(),
                    destination_contact:
                      variables.email || relationship.trade_request?.last_email_sent?.destination_contact,
                  },
                },
              };
            },
          );
        }

        if (updatedPartner.receiver_relationships) {
          updatedPartner.receiver_relationships = updatedPartner.receiver_relationships.map(
            (relationship: RelationshipV2) => {
              return {
                ...relationship,
                trade_request: {
                  ...relationship.trade_request,
                  id: '',
                  last_email_sent: {
                    id: '',
                    created_at: new Date().toISOString(),
                    updated_at: new Date().toISOString(),
                    destination_contact:
                      variables.email || relationship.trade_request?.last_email_sent?.destination_contact,
                  },
                },
              };
            },
          );
        }
        return data;
      },
    );
  },
});

export const useDeactivateRelationship = createMutation<
  DeactiveRelationshipResponseDTO,
  { relationshipId: string; partnerId: string },
  Error
>({
  mutationFn: async ({ relationshipId }) => {
    const { data } = await createData<DeactiveRelationshipResponseDTO>(
      `/v1/relationships/${relationshipId}/deactivate`,
    );
    return data;
  },
  onSuccess: () => {
    return Promise.all([
      queryClient.invalidateQueries(useRelationship.getKey()),
      queryClient.invalidateQueries(useInfiniteConnections.getKey()),
      queryClient.invalidateQueries(useConnectionsStats.getKey()),
    ]);
  },
});

export const useEditTradeRequestPartnerIdentifier = createMutation<
  EditInternalIdentifiersResponse,
  EditInternalIdentifiersRequest & { relationshipId: string },
  Error
>({
  mutationFn: async ({ relationshipId, partyIdentifier, partyIdentifierDomain }) => {
    const { data } = await createData<EditInternalIdentifiersResponse, EditInternalIdentifiersRequest>(
      `/v1/relationships/${relationshipId}/edit-internal-identifiers`,
      {
        partyIdentifier,
        partyIdentifierDomain,
      },
    );

    return data;
  },
  onSuccess: (_, variables) => {
    return queryClient.invalidateQueries(useRelationship.getKey({ relationshipId: variables.relationshipId }));
  },
});

type SendTestmessageRequest = Required<
  portalSchema.paths['/v1/relationships/{id}/testing-flow/send-documents']['patch']
>['requestBody']['content']['application/json'] & { relationshipId: string };

type SendTestmessageResponse =
  portalSchema.paths['/v1/relationships/{id}/testing-flow/send-documents']['patch']['responses']['200']['content']['application/json'];

export const useSendTestDocuments = createMutation<SendTestmessageResponse, SendTestmessageRequest, Error>({
  mutationFn: async ({ relationshipId, messages }) => {
    const { data } = await patchData<SendTestmessageResponse>(
      `/v1/relationships/${relationshipId}/testing-flow/send-documents`,
      { messages },
    );
    return data;
  },
  onSuccess: (_, variables) => {
    return Promise.all([
      queryClient.invalidateQueries(useRelationship.getKey({ relationshipId: variables.relationshipId })),
      queryClient.invalidateQueries(useInfiniteRelationships.getKey()),
      queryClient.invalidateQueries(useInfiniteConnections.getKey()),
      queryClient.invalidateQueries(useConnectionsStats.getKey()),
    ]);
  },
});

type ReceiveTestMessageRequest = Required<
  portalSchema.paths['/v1/relationships/{id}/testing-flow/deliver-documents']['patch']
>['parameters']['path'];

type ReceiveMessageResponse =
  portalSchema.paths['/v1/relationships/{id}/testing-flow/deliver-documents']['patch']['responses']['200']['content']['application/json'];

export const useReceiveTestMessage = createMutation<ReceiveMessageResponse, ReceiveTestMessageRequest, Error>({
  mutationFn: async ({ id: relationshipId }) => {
    const { data } = await patchData<ReceiveMessageResponse>(
      `/v1/relationships/${relationshipId}/testing-flow/deliver-documents`,
    );
    return data;
  },
  onSuccess: (_, variables) => {
    return Promise.all([
      queryClient.invalidateQueries(useRelationship.getKey({ relationshipId: variables.id })),
      queryClient.invalidateQueries(useInfiniteRelationships.getKey()),
      queryClient.invalidateQueries(useInfiniteConnections.getKey()),
      queryClient.invalidateQueries(useRelationshipTestDocuments.getKey()),
    ]);
  },
});

type ReviewTestMessageRequest = Required<
  portalSchema.paths['/v1/relationships/{id}/testing-flow/review']['patch']
>['requestBody']['content']['application/json'];

type ReviewTestMessageResponse =
  portalSchema.paths['/v1/relationships/{id}/testing-flow/review']['patch']['responses']['200']['content']['application/json'];

export const useReviewTestMessage = createMutation<
  ReviewTestMessageResponse,
  ReviewTestMessageRequest & { relationshipId: string },
  Error
>({
  mutationFn: async ({ relationshipId, state, go_live_date, activate_connection_immediately, reason }) => {
    const { data } = await patchData<ReviewTestMessageResponse>(
      `/v1/relationships/${relationshipId}/testing-flow/review`,
      { state, go_live_date, activate_connection_immediately, reason },
    );
    return data;
  },
  onSuccess: (_, variables) => {
    return Promise.all([
      queryClient.invalidateQueries(useRelationship.getKey({ relationshipId: variables.relationshipId })),
      queryClient.invalidateQueries(useInfiniteRelationships.getKey()),
      queryClient.invalidateQueries(useInfiniteConnections.getKey()),
      queryClient.invalidateQueries(useRelationshipTestDocuments.getKey({ relationshipId: variables.relationshipId })),
      queryClient.invalidateQueries(useConnectionsStats.getKey()),
    ]);
  },
});

type RelationshipTestDocumentsResponse = Required<
  portalSchema.paths['/v1/relationships/{id}/testing-flow/documents']['get']
>['responses']['200']['content']['application/json'];

export const useRelationshipTestDocuments = createQuery<
  PaginationDTO<TransactionDTO>,
  { query?: Filter; relationshipId: string },
  Error
>({
  primaryKey: '/relationships/testing-documents',
  queryFn: async ({ queryKey: [_, variables] }) => {
    const urlParams = buildFilterUrl({ filterValues: [], ...variables.query });
    const { data } = await fetchData<RelationshipTestDocumentsResponse>(
      `/v1/relationships/${variables.relationshipId}/testing-flow/documents` + urlParams,
    );

    return {
      ...data,
      items: data.items.map((transaction) => ({
        ...transaction,
        internalType: determineInternalType(transaction),
      })),
    };
  },
});

type GetSenderProcessSpecificationResponse =
  portalSchema.paths['/v1/relationships/{relationship_id}/sender-process-specification']['get']['responses']['200']['content']['application/json'];
export const useSenderProcessSpecification = createQuery<
  GetSenderProcessSpecificationResponse['data'],
  { relationshipId: string | undefined; sourceMessageId: string | undefined },
  Error
>({
  primaryKey: '/relationships/sender-process-specification',
  queryFn: async ({ queryKey: [_, variables] }) => {
    const { data } = await fetchData<GetSenderProcessSpecificationResponse>(
      `/v1/relationships/${variables.relationshipId}/sender-process-specification?source_message_id=${variables.sourceMessageId}`,
    );
    return data.data;
  },
  enabled: (_, variables) => !!variables.relationshipId,
});

type GetReceiverProcessSpecificationResponse =
  portalSchema.paths['/v1/relationships/{relationship_id}/receiver-process-specification']['get']['responses']['200']['content']['application/json'];
export const useReceiverProcessSpecification = createQuery<
  GetReceiverProcessSpecificationResponse['data'],
  { relationshipId: string | undefined; sourceMessageId: string | undefined },
  Error
>({
  primaryKey: '/relationships/receiver-process-specification',
  queryFn: async ({ queryKey: [_, variables] }) => {
    const { data } = await fetchData<GetReceiverProcessSpecificationResponse>(
      `/v1/relationships/${variables.relationshipId}/receiver-process-specification?source_message_id=${variables.sourceMessageId}`,
    );
    return data.data;
  },
  enabled: (_, variables) => !!variables.relationshipId,
});

type GetDocTypeProcessSpecificationResponse =
  portalSchema.paths['/v1/messages/{type}/default-process-spec']['get']['responses']['200']['content']['application/json'];
export const useDocTypeProcessSpecification = createQuery<
  GetDocTypeProcessSpecificationResponse['data'],
  { docType: DocumentType },
  Error
>({
  primaryKey: '/messages/default-process-spec',
  queryFn: async ({ queryKey: [_, variables] }) => {
    const { data } = await fetchData<GetDocTypeProcessSpecificationResponse>(
      `/v1/messages/${variables.docType}/default-process-spec`,
    );
    return data.data;
  },
  enabled: (_, variables) => !!variables.docType,
});

export type BulkRequestDocumentFromSenderRequest =
  portalSchema.paths['/v1/relationships/request-document']['post']['requestBody']['content']['application/json'];

type BulkRequestDocumentFromSenderResponse =
  portalSchema.paths['/v1/relationships/request-document']['post']['responses']['200']['content']['application/json'];

export const useBulkRequestDocumentFromSender = createMutation<
  BulkRequestDocumentFromSenderResponse,
  BulkRequestDocumentFromSenderRequest,
  Error
>({
  mutationFn: async (request) => {
    const { data } = await createData<BulkRequestDocumentFromSenderResponse>(
      `/v1/relationships/request-document`,
      request,
    );
    return data;
  },
  onSuccess: async () => {
    await queryClient.invalidateQueries(useInfiniteConnections.getKey());
  },
});
