import { KeyboardEventHandler, useMemo } from 'react';
import { ColumnDef, ColumnMeta, Row, RowData, Table } from '@tanstack/react-table';
import { classNames } from 'support/helpers/generic/generic';
import { formatCurrency, getCurrencySymbol } from 'support/helpers/currency/currency';
import { formatDateToISO, formatDay } from 'support/helpers/dateTime/dateTime';
import { useDocumentCurrency, useEnumerableOptions } from 'components/WebEDI/helpers';
import { ColumnTypesInternal, DisplayModes, WebEDIRowData } from '../../Types';
import { logError } from 'services/logging/logging';
import get from 'lodash/get';

export const convertTanstackIdsToDotDonation = <T extends string | undefined>(id: T): T => {
  return id?.replace(/\[(\d+)\]\./g, '.$1.') as T;
};

export const getFieldId = <TData = unknown, TValue = unknown>(
  columnDef: ColumnDef<TData, TValue>,
  dataPrefix: string | undefined,
  cellId: string,
  isRelativeModification?: boolean,
): string => {
  const accessorKey = 'accessorKey' in columnDef ? (columnDef.accessorKey as string) : undefined;
  ///modification_groups[0] to modifications_groups.0 so we can understand better the paths
  let dotNotationCellId = convertTanstackIdsToDotDonation(cellId);
  const dotNonationAccessorKey = convertTanstackIdsToDotDonation(accessorKey);
  const excludedReplacements = dotNonationAccessorKey?.split('.');

  if (isRelativeModification) {
    dotNotationCellId = dotNotationCellId.replace('amount', 'percentage');
  }
  const prefix = dataPrefix ? `${dataPrefix}.` : '';
  return `${prefix}${cellIdToFieldId(dotNotationCellId, excludedReplacements)}`;
};

const cellIdToFieldId = (cellId: string, excludedReplacements?: Array<string>): string => {
  let fieldId = cellId.replace(/_/g, '.');
  excludedReplacements?.forEach((replacement) => {
    fieldId = fieldId.replace(replacement.replaceAll('_', '.'), replacement);
  });
  return fieldId;
};

type CellClassNamesProps = {
  row: Pick<Row<RowData>, 'index'>;
  table: Pick<Table<RowData>, 'getRowCount'>;
  isFirstColumn: boolean;
  isLastColumn: boolean;
};

export const useCellClassNames = ({ row, table, isFirstColumn, isLastColumn }: CellClassNamesProps): string => {
  const totalRows = table.getRowCount();
  return useMemo(() => {
    const isLastRow = totalRows - 1 === row.index;

    return classNames({
      'rounded-bl-md': isFirstColumn && isLastRow,
      'rounded-br-md': isLastColumn && isLastRow,
    });
  }, [totalRows, row.index, isFirstColumn, isLastColumn]);
};

type CellConfigurationProps = {
  meta: ColumnMeta<RowData, unknown> | undefined;
  value: string | number | boolean | undefined;
  mode: DisplayModes;
  fieldId: string;
  rowData: RowData;
};

type CellConfigurationResult = {
  prefix: string;
  suffix: string;
  formattedValue: string | undefined;
};

export const useEnumerableConfigurations = ({
  meta,
  value,
}: Pick<CellConfigurationProps, 'meta' | 'value'>): Pick<CellConfigurationResult, 'formattedValue'> => {
  const options = useEnumerableOptions(meta?.optionalConfig);
  const formattedValue = options?.find((option) => option.value === value)?.label ?? undefined;

  return { formattedValue };
};

export const useDateConfigurations = ({
  value,
  mode,
}: Pick<CellConfigurationProps, 'value' | 'mode'>): Pick<CellConfigurationResult, 'formattedValue'> => {
  const formattedValue = formatDateValue(value, mode);

  return { formattedValue };
};

export const useMonetaryConfigurations = ({
  value,
  mode,
  fieldId,
  rowData,
}: Omit<CellConfigurationProps, 'meta'> & { rowData: WebEDIRowData }): CellConfigurationResult => {
  //The isPercentage is only needed because of the relative modifications. We use the same cell, just change the configurations
  const isPercentage =
    isModificationAmount(fieldId) &&
    isRelativeModification(fieldId, rowData, mode, Boolean(fieldId?.includes('line_items')));
  const currency = useDocumentCurrency();
  const { formattedValue, prefix } = formatMonetaryField(value, currency, mode);

  return { prefix: isPercentage ? '' : prefix, suffix: isPercentage ? '%' : '', formattedValue };
};

export const useNumberConfigurations = ({
  meta,
  value,
  mode,
  fieldId,
  rowData,
}: CellConfigurationProps & { rowData: WebEDIRowData }): CellConfigurationResult => {
  const isPercentage =
    isModificationAmount(fieldId) &&
    isRelativeModification(fieldId, rowData, mode, Boolean(fieldId?.includes('line_items')));
  const { formattedValue, prefix, suffix } = formatNumberField(value, meta, isPercentage, mode);

  return { prefix, suffix, formattedValue };
};

const isModificationAmount = (fieldId: string | undefined): boolean => {
  if (!fieldId) return false;
  return (
    fieldId.includes('modification_groups') &&
    (fieldId.includes('allowances') || fieldId.includes('charges')) &&
    (fieldId.includes('percentage') || fieldId.includes('amount'))
  );
};

export const formatMonetaryField = (value: unknown, currency: string, mode: DisplayModes) => {
  let formattedValue = '';
  let prefix = '';

  try {
    if (mode === DisplayModes.create || mode === DisplayModes.edit) {
      prefix = getCurrencySymbol(currency);
    }
    formattedValue = value ? formatCurrency(parseFloat(value as string), currency) : '';
  } catch (e) {
    logError(e);
    prefix = '';
  }

  return { formattedValue, prefix };
};

export const formatNumberField = (
  value: CellConfigurationProps['value'],
  meta: CellConfigurationProps['meta'],
  isPercentage: boolean,
  mode: DisplayModes,
): CellConfigurationResult => {
  const prefix = isPercentage ? '' : meta?.optionalConfig?.prefix ?? '';
  const suffix = isPercentage ? '%' : meta?.optionalConfig?.suffix ?? '';
  let formattedValue = typeof value === 'number' && isNaN(value) ? undefined : value ? String(value) : '';

  if (mode === DisplayModes.view) {
    formattedValue = formattedValue == undefined ? '' : formattedValue;
  }

  return { formattedValue, prefix, suffix };
};

export const isProcurosAddedValue = (row: Row<WebEDIRowData>): boolean => {
  return Boolean(row.original?._internal?.[ColumnTypesInternal.procuros_added]);
};

export const getCellNestedLevel = (row: Row<WebEDIRowData>): number => {
  return row.original?._internal?.level ?? 0;
};

type IsReadonlyProps = {
  columnMeta: ColumnMeta<RowData, unknown> | undefined;
  isAdded: boolean;
  mode: DisplayModes;
  rowIndex: number;
  fieldId: string;
};
const HARCODED_READONLY_FIELDS = [
  //parties.*.type
  new RegExp('^parties\\.\\d+\\.type$'),
];
export const useIsReadonly = ({ columnMeta, isAdded, mode, rowIndex, fieldId }: IsReadonlyProps) => {
  // if the row is added by the user, the field is always editable
  if (isAdded) return false;
  if (mode === DisplayModes.edit) return HARCODED_READONLY_FIELDS.some((field) => fieldId.match(field));
  if (columnMeta?.optionalConfig?.expression) return true;
  const isCellReadOnly = Boolean(columnMeta?.rowConfig?.[rowIndex]?.is_readonly);
  return Boolean(columnMeta?.readOnly || isCellReadOnly);
};

export const formatDateValue = (value: unknown, mode: DisplayModes) => {
  let date;

  if (value instanceof Date) {
    date = value;
  } else if (typeof value === 'string' || typeof value === 'number') {
    const timestamp = typeof value === 'string' ? parseInt(value) : value;
    date = new Date(timestamp * 1000);
  }

  if (date && !isNaN(date.getTime())) {
    const isoDate = formatDateToISO(date);
    return mode === DisplayModes.create || mode === DisplayModes.edit ? isoDate : formatDay(isoDate);
  }

  return undefined;
};

export const isRelativeModification = (
  fieldId: string | undefined,
  rowData: WebEDIRowData,
  mode: DisplayModes,
  isLineItems: boolean,
): boolean => {
  const isViewMode = mode === DisplayModes.view;
  if (!fieldId || !rowData) {
    return false;
  }
  if (!isLineItems) {
    return (
      typeof rowData === 'object' &&
      Boolean(
        ('type' in rowData && rowData.type === 'RELATIVE') ||
          (isViewMode && 'percentage' in rowData && rowData.percentage),
      )
    );
  } else {
    if (fieldId.includes('allowances')) {
      return (
        get(rowData, 'modification_groups.0.allowances.0.type') === 'RELATIVE' ||
        (isViewMode && Boolean(get(rowData, 'modification_groups.0.allowances.0.percentage')))
      );
    }
    if (fieldId.includes('charges')) {
      return (
        get(rowData, 'modification_groups.0.charges.0.type') === 'RELATIVE' ||
        (isViewMode && Boolean(get(rowData, 'modification_groups.0.charges.0.percentage')))
      );
    }
  }
  return false;
};

export const useLineItemsTransportUnitsConfig = (fieldId: string, rowData: WebEDIRowData) => {
  const isItemTransportUnit = fieldId.endsWith('transport_unit') && fieldId.includes('line_items');
  const shippedQuantity =
    typeof rowData === 'object' && rowData && 'shipped_quantity' in rowData && rowData.shipped_quantity;

  //in case it isn't a number we don't want to disable the field, the user is still to input the quantity
  const isItemShipped = typeof shippedQuantity === 'number' && !isNaN(shippedQuantity) ? shippedQuantity > 0 : true;

  return { isReadOnly: isItemTransportUnit && !isItemShipped };
};

export const disableArrowKeys: KeyboardEventHandler<HTMLInputElement> = (e) => {
  if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
    e.preventDefault();
  }
};
