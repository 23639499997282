import { useState } from 'react';
import { ProcessSpecificationDTO, RelationshipV2 } from 'support/types';
import { BetaUploadStage } from './components/BetaUploadStage';
import { BetaConfirmationStage } from './components/BetaConfirmationStage';
import classNames from 'classnames';
import { useCreateOCRConfigurationRequest } from 'services/repositories/integrations/integrations';
import { logError } from 'services/logging/logging';
import { GenericError } from 'support/helpers/errors/errors';
import { useLocalStorage } from 'usehooks-ts';

enum BetaExtractionStages {
  UPLOAD,
  CONFIRMATION,
}

type BetaExtractorProps = {
  targetRelationship: RelationshipV2;
  senderProcessSpecification: ProcessSpecificationDTO;
  setOpen: (open: boolean) => void;
};

const BETA_EXTRACTOR_LOCAL_STORAGE_KEY_PREFIX = 'documents_uploaded_';
export const BetaExtractor = ({ setOpen, targetRelationship, senderProcessSpecification }: BetaExtractorProps) => {
  const [documentsAlreadyUploaded, setDocumentsAlreadyUploaded] = useLocalStorage(
    BETA_EXTRACTOR_LOCAL_STORAGE_KEY_PREFIX + senderProcessSpecification.process?.id,
    false,
  );
  const [stage, setStage] = useState<BetaExtractionStages>(
    documentsAlreadyUploaded ? BetaExtractionStages.CONFIRMATION : BetaExtractionStages.UPLOAD,
  );
  const { mutate } = useCreateOCRConfigurationRequest();

  const onUploadConfirm = () => {
    if (!targetRelationship.sender_integration?.production?.id) {
      logError(
        new GenericError('Beta Extractor: sender integration not found', {
          senderIntegration: targetRelationship.sender_integration,
        }),
      );

      return;
    }

    if (!senderProcessSpecification?.process?.id) {
      logError(
        new GenericError('Beta Extractor: sender process specification not found', { senderProcessSpecification }),
      );
      return;
    }

    mutate(
      {
        integrationId: targetRelationship.sender_integration.production.id,
        processId: senderProcessSpecification.process.id,
      },
      {
        onSuccess: () => {
          setStage(BetaExtractionStages.CONFIRMATION);
          setDocumentsAlreadyUploaded(true);
        },
      },
    );
  };
  return (
    <div
      className={classNames('flex max-h-[800px] flex-col gap-4 overflow-auto p-6', {
        'h-[75vh] w-full': stage === BetaExtractionStages.UPLOAD,
        'sm:max-w-xl': stage === BetaExtractionStages.CONFIRMATION,
      })}
    >
      {stage === BetaExtractionStages.UPLOAD && (
        <BetaUploadStage
          documentType={targetRelationship?.message_type}
          onClose={() => setOpen(false)}
          onUploadConfirm={onUploadConfirm}
        />
      )}
      {stage === BetaExtractionStages.CONFIRMATION && (
        <BetaConfirmationStage
          onClose={() => setOpen(false)}
          onUploadMore={() => setStage(BetaExtractionStages.UPLOAD)}
        />
      )}
    </div>
  );
};
