import { useMemo } from 'react';
import { useWebEDIStore } from 'stores/webedi/webedi';

type OrderSummaryValues = {
  totalQuantity: number;
  lineCount: number;
  deliveryDate?: number;
};

const calculateLineItemsQuantity = (lineItems: Array<any> | undefined): number => {
  return (
    lineItems?.reduce((acc, item) => {
      if (item.line_items?.length) {
        return acc + calculateLineItemsQuantity(item.line_items);
      }

      return acc + (item.ordered_quantity ?? 0);
    }, 0) || 0
  );
};

export const useOrderSummary = (): OrderSummaryValues => {
  const canonical = useWebEDIStore((state) => state.canonical);

  return useMemo(
    () => ({
      totalQuantity: calculateLineItemsQuantity(canonical?.line_items),
      lineCount: canonical?.line_items?.length ?? 0,
      deliveryDate: canonical?.requested_delivery_date,
    }),
    [canonical?.line_items, canonical?.requested_delivery_date],
  );
};
