import classNames from 'classnames';
import { RowStatus } from 'components/DataTable/cells/WebEDI/components/RowStatus/RowStatus';
import { Input } from 'components/Form/Input/Input/Input';
import { ReactNode, memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type TextCellInputProps = {
  error: any;
  value?: string;
  readOnly: boolean;
  isStatusColumn: boolean;
  cellClassNames: string;
  fieldId: string;
  leftAddon: ReactNode | null;
  quantityField: string;
  isAdded: boolean;
  accessorKey: string;
};
// eslint-disable-next-line react/display-name
export const TextCellInput = memo(
  ({
    error,
    value,
    readOnly,
    isStatusColumn,
    fieldId,
    cellClassNames,
    leftAddon,
    quantityField,
    isAdded,
    accessorKey,
  }: TextCellInputProps) => {
    const { register } = useFormContext();
    const { t } = useTranslation();
    return (
      <Input
        wrapperClassName="w-full"
        className={classNames(
          'bg-transparent w-full !border-none p-3 !outline-none text-xs !shadow-none rounded-none min-w-[140px] text-start',
          error
            ? 'group-hover:bg-red-100 bg-red-50 focus:!bg-transparent disabled:!bg-red-50'
            : 'group-hover:bg-procuros-green-50 focus:!bg-transparent disabled:!bg-transparent',
          cellClassNames,
          isStatusColumn ? '!pl-10' : undefined,
        )}
        placeholder={!value && error?.type === 'required' ? t('common:table.errors.requiredField') : undefined}
        title={value ? String(value) : undefined}
        {...(value ? {} : register(fieldId))}
        value={value}
        readOnly={readOnly}
        type="text"
        hasError={Boolean(error)}
        leftIcon={
          isStatusColumn ? (
            <RowStatus
              statusColumn={quantityField}
              currentPath={fieldId}
              isProcurosAdded={isAdded}
              currentColumn={accessorKey}
              tooltips={{
                deleted: t('webedi:inputCell.status.deleted'),
                partial: t('webedi:inputCell.status.partial'),
                complete: t('webedi:inputCell.status.completed'),
              }}
            />
          ) : null
        }
        leftAddon={leftAddon}
        id={fieldId}
        data-field={fieldId}
        data-testid={fieldId}
        data-1p-ignore
      />
    );
  },
  (prevProps, nextProps) => {
    if (prevProps.fieldId.includes('parties') && prevProps.fieldId.includes('type')) {
      return false;
    }

    return (
      prevProps.value === nextProps.value &&
      prevProps.error === nextProps.error &&
      prevProps.readOnly === nextProps.readOnly &&
      prevProps.isStatusColumn === nextProps.isStatusColumn &&
      prevProps.cellClassNames === nextProps.cellClassNames &&
      prevProps.quantityField === nextProps.quantityField &&
      prevProps.isAdded === nextProps.isAdded &&
      prevProps.accessorKey === nextProps.accessorKey &&
      prevProps.fieldId === nextProps.fieldId
    );
  },
);
