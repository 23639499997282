import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PostHogProvider } from 'posthog-js/react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { router } from 'router';
import { queryClient } from 'services/http/http';
import { startVersionCheck } from 'services/version/version';
import { initializeSentry } from 'support/helpers/analytics/analytics';
import './i18n';
import './index.css';
import { ENV } from 'services/environment/environment';
import { PostHogConfig } from 'posthog-js';
import { isLocal } from 'support/helpers/generic/generic';

initializeSentry();
startVersionCheck();

const posthogOptions: Partial<PostHogConfig> = isLocal()
  ? {
      api_host: ENV.PUBLIC_POSTHOG_HOST,
      ui_host: 'https://eu.posthog.com',
      person_profiles: 'identified_only',
      capture_pageview: false,
      autocapture: false,
      opt_out_capturing_by_default: true,
      disable_session_recording: true,
    }
  : {
      api_host: ENV.PUBLIC_POSTHOG_HOST,
      ui_host: 'https://eu.posthog.com',
      person_profiles: 'identified_only',
      capture_pageview: false,
      session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
          password: true,
        },
      },
    };

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <PostHogProvider apiKey={ENV.PUBLIC_POSTHOG_KEY} options={posthogOptions}>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} position="top-left" />
    </QueryClientProvider>
  </PostHogProvider>,
);
