import classNames from 'classnames';
import { MessageFieldErrorModal, useMessageFieldErrorModal } from 'components/WebEDI/MessageFieldErrorModal';
import { ReactNode, useEffect } from 'react';
import { useWebEDIErrors, useWebEDIFieldError } from 'services/webediErrors/webediErrors';
import { useWebEDIStore } from 'stores/webedi/webedi';

type InputWrapperCellProps = {
  fieldId: string;
  isHighlighted: boolean;
  children: ReactNode;
  className?: string;
};
export const InputWrapperCell = ({ fieldId, isHighlighted, children, className }: InputWrapperCellProps) => {
  const isValidating = useWebEDIStore((state) => state.isValidating);
  const { error } = useWebEDIFieldError(fieldId);
  const { showFieldErrorModal, setShowFieldErrorModal, floatingStyles, refs } = useMessageFieldErrorModal();

  const { numberOfErrors, setCurrentError } = useWebEDIErrors();
  useEffect(() => {
    if (showFieldErrorModal && error) {
      setCurrentError(error);
    }
  }, [showFieldErrorModal, error, setCurrentError]);

  return (
    <>
      <div
        onFocus={() => setShowFieldErrorModal(true)}
        onBlur={() => setShowFieldErrorModal(false)}
        className={classNames(
          'group flex justify-between gap-2 items-center w-full',
          {
            'bg-red-50 focus-within:!bg-transparent focus-within:ring-red-500 focus-within:shadow-red-500 hover:bg-red-100':
              error,
            'hover:bg-procuros-green-50 focus-within:!bg-transparent focus-within:ring-procuros-green-500 focus-within:shadow-procuros-green-500':
              !error,
            'bg-amber-100': isHighlighted,
          },
          className,
        )}
        ref={refs.setReference}
      >
        {children}
      </div>
      {showFieldErrorModal && (
        <MessageFieldErrorModal
          error={error}
          viewMode={false}
          ref={refs.setFloating}
          style={floatingStyles}
          numberOfErrors={numberOfErrors}
          isValidating={isValidating}
        />
      )}
    </>
  );
};
