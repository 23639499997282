import { ui } from '@procuros/datachecks';
import isEqual from 'lodash/isEqual';
import { IntegrationProcessDTO, ProcessSpecificationDTO } from 'support/types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

type State = {
  uiConfig: Array<ui.Section>;
  canonical: Record<string, any> | undefined;
  initialCanonical: Record<string, any> | undefined;
  initialFlattenData: Record<string, any> | undefined;
  isLoading: boolean;
  isValidating: boolean;
  processSpecificationFields: ProcessSpecificationDTO['fields'] | undefined;
  destinationProcess: IntegrationProcessDTO | undefined | null;
};

export const initialState: State = {
  uiConfig: [],
  isLoading: true,
  isValidating: false,
  processSpecificationFields: undefined,
  canonical: undefined,
  initialCanonical: undefined,
  initialFlattenData: undefined,
  destinationProcess: undefined,
};

export const useWebEDIStore = create(
  immer<State>(() => ({
    ...initialState,
  })),
);

export const setIsValidating = (isValidating: boolean) => useWebEDIStore.setState({ isValidating });
export const setInitialFlattenData = (initialFlattenData: Record<string, any> | undefined) =>
  useWebEDIStore.setState({ initialFlattenData });
export const setInitialCanonical = (initialCanonical: Record<string, any> | undefined) =>
  useWebEDIStore.setState({ initialCanonical });
export const setUiConfig = (uiConfig: Array<ui.Section>) =>
  useWebEDIStore.setState((state) => {
    if (isEqual(state.uiConfig, uiConfig)) return state;
    return { uiConfig };
  });
export const setCanonical = (canonical: Record<string, any> | undefined) =>
  useWebEDIStore.setState((state) => {
    if (isEqual(state.canonical, canonical)) return state;
    return { canonical };
  });
export const setIsLoading = (isLoading: boolean) => useWebEDIStore.setState({ isLoading });
export const setProcessSpecificationFields = (
  processSpecificationFields: ProcessSpecificationDTO['fields'] | undefined,
) =>
  useWebEDIStore.setState((state) => {
    if (isEqual(state.processSpecificationFields, processSpecificationFields)) return state;
    return { processSpecificationFields: processSpecificationFields };
  });

export const setDestinationProcess = (destinationProcess: IntegrationProcessDTO | undefined | null) =>
  useWebEDIStore.setState({ destinationProcess });

export const cleanStore = () => useWebEDIStore.setState(initialState);
