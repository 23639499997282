import { MessageV2DTO, ReceiverEnvelopeDTO } from 'support/types';
import { WebEDISidebarTableContent } from '../WebEDISidebarTableContent/WebEDISidebarTableContent';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProofOfDeliveryDetails } from './components/ProofOfDeliveryDetails/ProofOfDeliveryDetails';
import { Button } from 'components/Form/Button';

export const hasProofOfDelivery = (
  message: MessageV2DTO | undefined | null,
): message is MessageV2DTO & { receiver_envelope: Required<Pick<ReceiverEnvelopeDTO, 'proofOfDelivery'>> } => {
  return Boolean(message?.receiver_envelope?.proofOfDelivery);
};

export const ProofOfDelivery = ({
  receiverEnvelope,
}: {
  receiverEnvelope: Required<Pick<ReceiverEnvelopeDTO, 'proofOfDelivery' | 'receiverIntegration'>>;
}) => {
  const { t } = useTranslation();
  const [areDetailsOpen, setAreDetailsOpen] = useState(false);
  return (
    <>
      <WebEDISidebarTableContent
        items={[
          {
            label: t('webedi:proofOfDelivery.label'),
            value: (
              <Button
                variant="text"
                size="extra-small"
                className="text-inherit underline"
                analyticsId="view_proof_of_transmission"
                onClick={() => setAreDetailsOpen(true)}
              >
                {t('webedi:proofOfDelivery.viewDetails')}
              </Button>
            ),
          },
        ]}
      />
      <ProofOfDeliveryDetails receiverEnvelope={receiverEnvelope} isOpen={areDetailsOpen} setOpen={setAreDetailsOpen} />
    </>
  );
};
