import { classNames } from 'support/helpers/generic/generic';
import { useWebEDIErrors, useWebEDIFieldError } from 'services/webediErrors/webediErrors';
import { MessageFieldErrorModal, useMessageFieldErrorModal } from 'components/WebEDI/MessageFieldErrorModal';
import { ReactNode, useEffect } from 'react';

type ViewWrapperCellProps = {
  children: ReactNode;
  className?: string;
  fieldId: string;
  isHighlighted: boolean;
};
export const ViewWrapperCell = ({ className, isHighlighted, fieldId, children }: ViewWrapperCellProps) => {
  const { error } = useWebEDIFieldError(fieldId);
  const { numberOfErrors, setCurrentError } = useWebEDIErrors();
  const { showFieldErrorModal, setShowFieldErrorModal, floatingStyles, refs } = useMessageFieldErrorModal();

  useEffect(() => {
    if (error && showFieldErrorModal) {
      setCurrentError(error);
    }
  }, [error, setCurrentError, showFieldErrorModal]);

  return (
    <>
      <div
        data-field={fieldId}
        tabIndex={error ? 0 : undefined}
        onFocus={() => setShowFieldErrorModal(true)}
        onBlur={() => setShowFieldErrorModal(false)}
        className={classNames('border w-full flex justify-between gap-2 items-center px-3 outline-none', className, {
          'text-red-700 underline border-bg-red-50 bg-red-50 py-2.5 cursor-pointer hover:bg-red-100': error,
          'py-3': !error,
          'bg-amber-100': isHighlighted,
          'border-red-500': showFieldErrorModal,
          'border-transparent': !showFieldErrorModal,
        })}
        ref={refs.setReference}
      >
        {children}
      </div>
      {showFieldErrorModal && (
        <MessageFieldErrorModal
          error={error}
          viewMode
          ref={refs.setFloating}
          style={floatingStyles}
          numberOfErrors={numberOfErrors}
        />
      )}
    </>
  );
};
