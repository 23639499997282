import { useValue } from 'components/WebEDI/helpers';
import { ReactNode, useMemo } from 'react';
import { TextCellInput } from './TextCellInput';

type CalculatedFieldInputProps = {
  fieldId: string;
  readOnly: boolean;
  error: any;
  cellClassNames: string;
  leftAddon: ReactNode | null;
  isAdded: boolean;
  columnDef: any;
  row: any;
};
export const CalculatedText = ({
  fieldId,
  readOnly,
  error,
  cellClassNames,
  leftAddon,
  isAdded,
  columnDef,
  row,
}: CalculatedFieldInputProps) => {
  const columnMeta = columnDef?.meta;
  const value = useValue(row.original, fieldId, columnMeta?.optionalConfig?.expression);
  const formatValue = useMemo(
    () => columnMeta?.optionalConfig?.translateValue || ((value: any) => value),
    [columnMeta?.optionalConfig?.translateValue],
  );

  return (
    <TextCellInput
      error={error}
      quantityField={columnMeta?.optionalConfig?.quantityField ?? ''}
      isAdded={isAdded}
      accessorKey={'accessorKey' in columnDef ? (columnDef.accessorKey as string) : ''}
      value={formatValue(value)}
      readOnly={readOnly}
      leftAddon={leftAddon}
      cellClassNames={cellClassNames}
      fieldId={fieldId}
      isStatusColumn={false}
    />
  );
};
