import { DocumentType } from 'support/types';
import { PropertyFilterType } from '../types';
import { t } from 'i18next';

export const buildDocumentTypeFilter = (key: string, exclude?: Array<DocumentType>) => ({
  key,
  type: PropertyFilterType.dropdown,
  label: t('transactions:list.filter.messageTypes.label'),
  value: '',
  dropDownOptions: {
    dropdownValues: [
      { label: t('transactions:list.filter.messageTypes.ORDER.label'), value: DocumentType.order },
      {
        label: t('transactions:list.filter.messageTypes.ORDER_RESPONSE.label'),
        value: DocumentType.orderResponse,
      },
      {
        label: t('transactions:list.filter.messageTypes.DISPATCH_INSTRUCTION.label'),
        value: DocumentType.dispatchInstruction,
      },
      {
        label: t('transactions:list.filter.messageTypes.DISPATCH_INSTRUCTION_RESPONSE.label'),
        value: DocumentType.dispatchResponse,
      },
      {
        label: t('transactions:list.filter.messageTypes.SHIPPING_NOTICE.label'),
        value: DocumentType.shippingNotice,
      },
      { label: t('transactions:list.filter.messageTypes.INVOICE.label'), value: DocumentType.invoice },
      { label: t('transactions:list.filter.messageTypes.CREDIT_NOTE.label'), value: DocumentType.creditNote },
      {
        label: t('transactions:list.filter.messageTypes.PRODUCT_CATALOG.label'),
        value: DocumentType.productCatalog,
      },
      {
        label: t('transactions:list.filter.messageTypes.ACKNOWLEDGEMENT.label'),
        value: DocumentType.acknowledgement,
      },
      { label: t('transactions:list.filter.messageTypes.UNKNOWN.label'), value: DocumentType.unknown },
    ].filter((option) => !exclude?.includes(option.value)),
  },
  eventDetails: {
    event: 'filter_changed',
    properties: {
      filter_type: 'documentType',
    },
  },
});
