import { ArrowLongRightIcon } from '@heroicons/react/24/outline';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { Section } from 'components/Display/Section/Section';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/Display/Tooltip/Tooltip';
import { RelationshipBadge } from 'pages/Connections/components/RelationshipBadge/RelationshipBadge';
import { useTranslation } from 'react-i18next';
import {
  Connectors,
  IdentifierDomainOptions,
  IntegrationDTO,
  PartnerDTO,
  RelationshipStatus,
  RelationshipV2,
} from 'support/types';
import { InlineInput } from 'components/Form/InlineInput/InlineInput';
import { useEditTradeRequestPartnerIdentifier } from 'services/repositories/relationships/relationships';
import { addNotification } from 'stores/notifications/notifications';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { processSubmissionErrors } from 'support/helpers/errors/errors';
import { RelationshipDetailsBanner } from './components/RelationshipDetailsBanner/RelationshipDetailsBanner';
import { DirectionBadge } from 'components/DirectionBadge/DirectionBadge';

export const RelationshipDetailsHeader = ({
  relationship,
  currentPartnerId,
}: {
  relationship: RelationshipV2;
  currentPartnerId: PartnerDTO['id'];
}) => {
  const { t } = useTranslation();
  const direction = relationship.sender_partner?.id === currentPartnerId ? 'OUTBOUND' : 'INBOUND';
  const requestedBy = relationship.trade_request?.requested_by === currentPartnerId ? 'current' : 'other';

  return (
    <div className="flex flex-col gap-6">
      <div className="grid gap-4">
        <Section.Item title={t('connections:relationshipDetails.header.state.title')} direction="horizontal">
          <RelationshipBadge relationship={relationship} currentPartnerId={currentPartnerId} />
        </Section.Item>
        <Section.Item title={t('connections:relationshipDetails.header.direction.title')} direction="horizontal">
          <DirectionBadge direction={direction} />
        </Section.Item>
        <Section.Item title={t('connections:relationshipDetails.header.integrations.title')} direction="horizontal">
          <ConnectedIntegrations relationship={relationship} currentPartnerId={currentPartnerId} />
        </Section.Item>
        {requestedBy === 'current' && <InternalIdentifierEditor relationship={relationship} />}
      </div>
      <RelationshipDetailsBanner
        relationship={relationship}
        currentPartnerId={currentPartnerId}
        requester={requestedBy === 'current'}
      />
    </div>
  );
};

const ConnectedIntegrations = ({
  relationship,
  currentPartnerId,
}: {
  relationship: RelationshipV2;
  currentPartnerId: string;
}) => {
  const { t } = useTranslation();

  const senderConnector = [RelationshipStatus.LIVE, RelationshipStatus.ACTIVE].includes(
    RelationshipStatus[relationship.status],
  )
    ? relationship.sender_integration?.production?.connector
    : relationship.sender_integration?.testing?.connector || relationship.sender_integration?.production?.connector;

  const receiverConnector = [RelationshipStatus.LIVE, RelationshipStatus.ACTIVE].includes(
    RelationshipStatus[relationship.status],
  )
    ? relationship.receiver_integration?.production?.connector
    : relationship.receiver_integration?.testing?.connector || relationship.receiver_integration?.production?.connector;

  return (
    <div className="flex items-center gap-1 text-xs text-gray-700">
      <ConnectorName connector={senderConnector} />
      {relationship.sender_partner?.id === currentPartnerId ? (
        <span className="text-gray-500">{t('connections:relationshipDetails.header.integrations.currentPartner')}</span>
      ) : null}
      <ArrowLongRightIcon className="w-4 text-gray-500" />
      <ConnectorName connector={receiverConnector} />
      {relationship.receiver_partner?.id === currentPartnerId ? (
        <span className="text-gray-500">{t('connections:relationshipDetails.header.integrations.currentPartner')}</span>
      ) : null}
    </div>
  );
};

const ConnectorName = ({ connector }: { connector: IntegrationDTO['connector'] | null | undefined }) => {
  const { t } = useTranslation();

  const connectorToTranslate = connector ?? Connectors.NONE;

  // t('integrations:connector.NONE.tooltip')
  const connectorNeedsTooltip = [Connectors.NONE];

  return (
    <div className="flex items-center gap-1">
      <span>{t(`integrations:connector.${connectorToTranslate}.name`)}</span>
      {connectorNeedsTooltip.includes(connectorToTranslate as Connectors) ? (
        <Tooltip>
          <TooltipTrigger>
            <InformationCircleIcon className="size-5 text-gray-400" />
          </TooltipTrigger>
          <TooltipContent>{t(`integrations:connector.${connectorToTranslate}.tooltip`)}</TooltipContent>
        </Tooltip>
      ) : null}
    </div>
  );
};

const InternalIdentifierEditor = ({ relationship }: { relationship: RelationshipV2 }) => {
  const { t } = useTranslation();
  const { mutateAsync } = useEditTradeRequestPartnerIdentifier();

  const {
    register,
    formState: { errors },
    setError,
    handleSubmit,
  } = useForm({
    defaultValues: {
      partyIdentifier: relationship.trade_request?.partyIdentifier || '',
      partyIdentifierDomain: IdentifierDomainOptions.RECEIVER_INTERNAL,
    },
    resolver: yupResolver(
      Yup.object().shape({
        partyIdentifier: Yup.string().required(t('connections:relationshipDetails.header.internalIdentifier.required')),
        partyIdentifierDomain: Yup.string().required(),
      }),
    ),
    mode: 'onChange',
  });

  const onSubmit = async (data: { partyIdentifier: string }) => {
    try {
      await mutateAsync({
        relationshipId: relationship.id,
        partyIdentifier: data.partyIdentifier,
        partyIdentifierDomain: IdentifierDomainOptions.RECEIVER_INTERNAL,
      });
      addNotification(t('connections:relationshipDetails.header.internalIdentifier.success'));
    } catch (error) {
      processSubmissionErrors({
        error: error as Error,
        t,
        setNonInputErrors: undefined,
        setInputError: setError,
        defaultData: {
          partyIdentifier: '',
          partyIdentifierDomain: IdentifierDomainOptions.RECEIVER_INTERNAL,
        },
      });
      throw error;
    }
  };

  // We only allow editing internal identifiers
  if (relationship.trade_request?.partyIdentifierDomain !== IdentifierDomainOptions.RECEIVER_INTERNAL) {
    return null;
  }

  return (
    <Section.Item
      title={
        <div className="flex items-center gap-2">
          {t('connections:relationshipDetails.header.internalIdentifier.title')}
          <Tooltip>
            <TooltipTrigger>
              <InformationCircleIcon className="size-5 text-gray-400" />
            </TooltipTrigger>
            <TooltipContent>{t('connections:relationshipDetails.header.internalIdentifier.tooltip')}</TooltipContent>
          </Tooltip>
        </div>
      }
      direction="horizontal"
    >
      <InlineInput
        defaultValue={relationship.trade_request?.partyIdentifier || ''}
        key={relationship.trade_request?.partyIdentifier}
        errors={errors.partyIdentifier}
        {...register('partyIdentifier')}
        onSave={async () => await handleSubmit(onSubmit)()}
      />
    </Section.Item>
  );
};
