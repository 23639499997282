import { useNavigate, useParams } from 'react-router-dom';
import { useCreateIntegrationRequest } from 'services/repositories/integrations/integrations';
import { useEffect, useMemo } from 'react';
import { Connectors, ConnectorsType, IntegrationConfigurationSteps, IntegrationsRoutes } from 'support/types';
import { useTranslation } from 'react-i18next';
import { ContactSoon } from 'components/ContactSoon/ContactSoon';
import { ConnectorSteps } from './components/ConnectorSteps/ConnectorSteps';

const connectorsAvailable = [Connectors.WEB_EDI];

export const NewEditIntegration = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { type: connector, step } = useParams<{ type: Connectors; step?: IntegrationConfigurationSteps }>();
  const { mutate: sendIntegrationRequest } = useCreateIntegrationRequest();
  const isDisabledConnector = useMemo(
    () => connector && !connectorsAvailable.some((c) => c === connector),
    [connector],
  );

  useEffect(() => {
    if (connector && isDisabledConnector && connector !== Connectors.NONE) {
      sendIntegrationRequest({ connector: connector as ConnectorsType });
    }
  }, [connector, sendIntegrationRequest, isDisabledConnector]);

  if (isDisabledConnector) {
    return (
      <ContactSoon
        title={t('integrations:contactSoon.title')}
        description={t('integrations:contactSoon.description')}
        ctaOptions={{
          ctaAction: () => navigate(IntegrationsRoutes.root),
          ctaText: t('integrations:contactSoon.button'),
        }}
      />
    );
  }

  return <ConnectorSteps connector={connector as Connectors} stepKey={step} />;
};
