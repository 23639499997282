import { CellContext } from '@tanstack/react-table';
import { classNames } from 'support/helpers/generic/generic';
import { CellProps } from '../../types';
import { useCellClassNames } from '../cellHelpers';
import { useValue } from 'components/WebEDI/helpers';
import { cloneElement } from 'react';
import useIsTruncated from 'hooks/useIsTruncated';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/Display/Tooltip/Tooltip';
import { ViewWrapperCell } from './ViewWrapperCell';
import { useWebEDIFieldError } from 'services/webediErrors/webediErrors';

export type Props<TData, TValue> = CellContext<TData, TValue> & Pick<CellProps, 'fieldId' | 'isHighlighted'>;
export const ViewURLCell = <TData = unknown, TValue = string>({
  row,
  cell,
  column,
  table,
  isHighlighted,
  fieldId,
}: Props<TData, TValue>) => {
  const { error } = useWebEDIFieldError(fieldId);
  const value = useValue(row.original, fieldId, column.columnDef.meta?.optionalConfig?.expression);
  const {
    columnDef: { meta: columnMeta },
  } = column;
  const cellClassNames = useCellClassNames({
    row,
    table,
    isFirstColumn: column.getIsFirstColumn(),
    isLastColumn: column.getIsLastColumn(),
  });
  const [truncatedRef, valueIsTruncated] = useIsTruncated();

  return (
    <ViewWrapperCell className={cellClassNames} isHighlighted={isHighlighted} fieldId={fieldId}>
      <Tooltip enabled={valueIsTruncated}>
        <TooltipTrigger>
          <span className="flex w-full items-center">
            <div
              className={classNames('flex w-full gap-x-0.5 overflow-hidden')}
              style={{ width: columnMeta?.optionalConfig?.width }}
            >
              <span ref={truncatedRef} className="truncate">
                <a href={value} target="_blank" rel="noreferrer" className="underline">
                  {value}
                </a>
              </span>
            </div>
            {columnMeta?.rightAddon && !error ? (
              <span className="text-gray-500">{cloneElement(columnMeta.rightAddon, { cell })}</span>
            ) : null}
          </span>
        </TooltipTrigger>
        <TooltipContent>{value}</TooltipContent>
      </Tooltip>
    </ViewWrapperCell>
  );
};
