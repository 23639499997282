import { ReactNode } from 'react';

export const WebEDISidebarTableContent = ({
  items,
  footer,
}: {
  items: Array<{ label: string; value: ReactNode }>;
  footer?: { label: string; value: ReactNode };
}) => {
  return (
    <div>
      <div className="space-y-6">
        {items.map((item) => (
          <div key={item.label} className="flex items-center justify-between">
            <span className="text-xs text-gray-500">{item.label}</span>
            <span className="text-end text-xs text-gray-900">{item.value}</span>
          </div>
        ))}
      </div>
      {!!footer && (
        <div className="mt-3 flex items-center justify-between border-t border-gray-200 pt-3">
          <span className="text-xs text-gray-500">{footer.label}</span>
          <span className="text-end text-base font-bold text-gray-900 ">{footer.value}</span>
        </div>
      )}
    </div>
  );
};
