import { useMemo } from 'react';
import { ENV } from 'services/environment/environment';
import { useCurrentPartner } from 'services/repositories/partners/partners';

export enum FEATURE_FLAGS {
  AS2_SETUP = 'AS2_SETUP',
  TEMPORARY_PRICE_CATALOGS = 'TEMPORARY_PRICE_CATALOGS',
}

export const useLocalFeatureFlag = (flag: FEATURE_FLAGS) => {
  return ENV.FEATURE_FLAG[flag];
};

/**
 * @description
 * This hook will return if a feature flag is enabled or not for the current partner.
 * It will return a boolean and a isLoading flag.
 * The isLoading flag will be true until the partner is loaded.
 *
 * The flags value being used as parameter will never be read, only the first value counts.
 * We need it because it can be an array and the useEffect will be triggered when the array reference changes.
 */
export const usePartnerFeatureFlag = (flags: FEATURE_FLAGS | Array<FEATURE_FLAGS>) => {
  const { data, isLoading: isLoadingPartner } = useCurrentPartner();

  const state = useMemo(() => {
    if (!data) {
      return { enabled: false, isLoading: isLoadingPartner };
    }
    if (!Array.isArray(flags)) {
      return { enabled: data.features[flags as keyof typeof data.features], isLoading: isLoadingPartner };
    }
    return {
      enabled: flags.some((flag) => data.features[flag as keyof typeof data.features]),
      isLoading: isLoadingPartner,
    };
  }, [data, isLoadingPartner, flags]);

  return state;
};
