import { CellContext } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { classNames } from 'support/helpers/generic/generic';
import { EMPTY_FIELD } from 'support/helpers/const/const';
import { cloneElement } from 'react';
import { useCellClassNames, useDateConfigurations } from '../cellHelpers';
import { DisplayModes } from 'components/DataTable/Types';
import { ErrorIcon } from 'components/DataTable/components/Icons';
import { CellProps } from '../../types';
import { useController } from 'react-hook-form';
import { ViewWrapperCell } from './ViewWrapperCell';

export type Props<TData, TValue> = CellContext<TData, TValue> & Pick<CellProps, 'fieldId' | 'isHighlighted'>;
export const ViewDateCell = <TData = unknown, TValue = string>({
  row,
  cell,
  column,
  table,
  isHighlighted,
  fieldId,
}: Props<TData, TValue>) => {
  const { t } = useTranslation();
  const {
    field: { value },
    fieldState: { error },
  } = useController({ name: fieldId });

  const {
    columnDef: { meta: columnMeta },
  } = column;

  const { formattedValue } = useDateConfigurations({
    value,
    mode: DisplayModes.view,
  });

  const cellClassNames = useCellClassNames({
    row,
    table,
    isFirstColumn: column.getIsFirstColumn(),
    isLastColumn: column.getIsLastColumn(),
  });

  return (
    <ViewWrapperCell fieldId={fieldId} isHighlighted={isHighlighted} className={cellClassNames}>
      <span className="flex w-full items-center">
        <div
          className={classNames('flex w-full gap-x-0.5 overflow-hidden tabular-nums', {
            'justify-start': columnMeta?.optionalConfig?.textAlign === 'left',
          })}
          style={{ width: columnMeta?.optionalConfig?.width }}
        >
          {!formattedValue && error ? (
            t('common:table.errors.requiredField')
          ) : formattedValue ? (
            <span className="flex-initial">{formattedValue}</span>
          ) : (
            <span className="text-gray-400">{EMPTY_FIELD}</span>
          )}
        </div>
        {columnMeta?.rightAddon && !error ? (
          <span className="text-gray-500">{cloneElement(columnMeta.rightAddon, { cell })}</span>
        ) : null}
      </span>
      {error ? <ErrorIcon /> : null}
    </ViewWrapperCell>
  );
};
