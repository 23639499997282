import { useFlattenDataAndUIConfig } from 'hooks/useFlattenDataAndUIConfig';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { applyRowMasksToData } from 'services/datachecks/datachecks';
import { useWebEDIStore, setUiConfig, setInitialFlattenData, setIsLoading } from 'stores/webedi/webedi';

export const useInitialWebEDISetup = () => {
  const initialCanonical = useWebEDIStore((state) => state.initialCanonical);
  const processSpecificationFields = useWebEDIStore((state) => state.processSpecificationFields);
  const { reset } = useFormContext();
  const { data, uiConfig, isLoading } = useFlattenDataAndUIConfig(processSpecificationFields, initialCanonical);

  useEffect(() => {
    if (uiConfig) {
      setUiConfig(uiConfig);
    }

    if (data) {
      const filteredData = uiConfig ? applyRowMasksToData(data, uiConfig) : data;

      setInitialFlattenData(filteredData);
      reset(filteredData);
    }
  }, [data, reset, uiConfig]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);
};
