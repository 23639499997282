import { CellContext } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { classNames } from 'support/helpers/generic/generic';
import { useWebEDIFieldError } from 'services/webediErrors/webediErrors';
import { ErrorIcon } from 'components/DataTable/components/Icons';
import { useCellClassNames } from '../cellHelpers';
import { CellProps } from '../../types';
import { useWatch } from 'react-hook-form';
import { ViewWrapperCell } from './ViewWrapperCell';

export type Props<TData, TValue> = CellContext<TData, TValue> & Pick<CellProps, 'fieldId' | 'isHighlighted'>;
export const ViewBooleanCell = <TData = unknown, TValue = string>({
  row,
  column,
  table,
  isHighlighted,
  fieldId,
}: Props<TData, TValue>) => {
  const { t } = useTranslation();
  const { error } = useWebEDIFieldError(fieldId);
  const value = useWatch({ name: fieldId });

  const {
    columnDef: { meta: columnMeta },
  } = column;

  const cellClassNames = useCellClassNames({
    row,
    table,
    isFirstColumn: column.getIsFirstColumn(),
    isLastColumn: column.getIsLastColumn(),
  });

  return (
    <ViewWrapperCell fieldId={fieldId} isHighlighted={isHighlighted} className={cellClassNames}>
      <span className="flex w-full items-center">
        <div
          className={classNames('flex w-full gap-x-0.5 overflow-hidden', {
            'justify-start': columnMeta?.optionalConfig?.textAlign === 'left',
          })}
          style={{ width: columnMeta?.optionalConfig?.width }}
        >
          <span className="flex-initial">{value ? t('common:yes') : t('common:no')}</span>
        </div>
      </span>
      {error ? <ErrorIcon /> : null}
    </ViewWrapperCell>
  );
};
