import { ui } from '@procuros/datachecks';
import { useDateConfigurations } from 'components/DataTable/cells/WebEDI/cellHelpers';
import { DisplayModes } from 'components/DataTable/Types';
import { DataDisplay } from 'components/Form/DataDisplay/DataDisplay';
import { MessageInput } from 'components/Form/MessageInput/MessageInput';
import { useWatch, useFormContext } from 'react-hook-form';
import { useWebEDIFieldError, useWebEDIErrors } from 'services/webediErrors/webediErrors';

type DateFieldProps = {
  field: ui.DateField | ui.DateTimeField;
  fieldId: string;
  isReadOnly: boolean;
  mode: DisplayModes;
};
export const DateField = ({ field, fieldId, isReadOnly, mode }: DateFieldProps) => {
  const value = useWatch({ name: fieldId });
  const { register } = useFormContext();
  const { error } = useWebEDIFieldError(fieldId);
  const { numberOfErrors, setCurrentError } = useWebEDIErrors();
  const { formattedValue } = useDateConfigurations({
    value,
    mode,
  });

  if (mode === DisplayModes.view) {
    return (
      <DataDisplay
        value={formattedValue}
        label={field.label}
        totalNumberOfErrors={numberOfErrors}
        error={error}
        fieldId={fieldId}
        onShowFieldErrorModal={() => error && setCurrentError(error)}
      />
    );
  }
  return (
    <MessageInput
      type={field.type === ui.FieldTypes.date ? 'date' : 'datetime-local'}
      label={field.label}
      error={error}
      totalNumberOfErrors={numberOfErrors}
      {...register(fieldId)}
      value={formattedValue}
      onShowFieldErrorModal={() => error && setCurrentError(error)}
      required={field.modality === 'MANDATORY'}
      disabled={isReadOnly}
    />
  );
};
