import { CellContext } from '@tanstack/react-table';
import { memo } from 'react';
import { useWebEDIFieldError } from 'services/webediErrors/webediErrors';
import { InputWrapperCell } from 'components/DataTable/cells/WebEDI/CreateEdit/InputWrapperCell';
import {
  isProcurosAddedValue,
  useIsReadonly,
  getCellNestedLevel,
  useCellClassNames,
} from 'components/DataTable/cells/WebEDI/cellHelpers';
import { NestedLevelAddon } from 'components/DataTable/cells/WebEDI/components/NestedLevelAddon/NestedLevelAddon';
import { CellProps } from 'components/DataTable/cells/types';
import { TextCellInput } from './TextCellInput';
import { CalculatedText } from './CalculatedText';
import { WebEDIRowData } from 'components/DataTable/Types';

export type Props = CellContext<WebEDIRowData, unknown> & CellProps;
// eslint-disable-next-line react/display-name
export const TextCell = memo(
  ({ fieldId, column, row, table, isHighlighted, mode }: Props) => {
    const {
      columnDef: { meta: columnMeta, ...columnDef },
      getIsFirstColumn,
      getIsLastColumn,
    } = column;
    const { error } = useWebEDIFieldError(fieldId);

    const isAdded = isProcurosAddedValue(row);
    const readOnly = useIsReadonly({ isAdded, mode, columnMeta, rowIndex: row.index, fieldId });
    const cellNestedLevel = columnMeta?.showNestLevel ? getCellNestedLevel(row) : 0;
    const cellClassNames = useCellClassNames({
      isLastColumn: getIsLastColumn(),
      isFirstColumn: getIsFirstColumn(),
      row,
      table,
    });

    const leftAddon =
      columnMeta?.showNestLevel && cellNestedLevel > 1 ? <NestedLevelAddon level={cellNestedLevel} /> : null;

    if (columnMeta?.optionalConfig?.expression || columnMeta?.optionalConfig?.translateValue) {
      return (
        <InputWrapperCell fieldId={fieldId} isHighlighted={isHighlighted} className={cellClassNames}>
          <CalculatedText
            fieldId={fieldId}
            readOnly={readOnly}
            error={error}
            cellClassNames={cellClassNames}
            leftAddon={leftAddon}
            isAdded={isAdded}
            row={row}
            columnDef={column.columnDef}
          />
        </InputWrapperCell>
      );
    }

    return (
      <InputWrapperCell fieldId={fieldId} isHighlighted={isHighlighted} className={cellClassNames}>
        <TextCellInput
          error={error}
          quantityField={columnMeta?.optionalConfig?.quantityField ?? ''}
          isAdded={isAdded}
          accessorKey={'accessorKey' in columnDef ? (columnDef.accessorKey as string) : ''}
          readOnly={readOnly}
          leftAddon={leftAddon}
          isStatusColumn={Boolean(columnMeta?.optionalConfig?.isStatusColumn)}
          cellClassNames={cellClassNames}
          fieldId={fieldId}
        />
      </InputWrapperCell>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.row.id === nextProps.row.id && prevProps.isHighlighted === nextProps.isHighlighted;
  },
);
