import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useMemo } from 'react';
import { ENV } from 'services/environment/environment';
import { useSenderProcessSpecification } from 'services/repositories/relationships/relationships';
import { DocumentType, ProcessSpecificationDTO } from 'support/types';

export const useShouldShowOcr = (documentType: DocumentType | undefined) => {
  const shippingNoticeOcrEnabled = useFeatureFlagEnabled('ocr-shipping-notice');
  return useMemo(() => {
    return documentType === DocumentType.shippingNotice && !!shippingNoticeOcrEnabled;
  }, [documentType, shippingNoticeOcrEnabled]);
};

const IS_BETA_OCR_ENABLED = ENV.FEATURE_FLAG.BETA_OCR;

export const isBetaOCR = (senderProcessSpecification: ProcessSpecificationDTO | undefined) => {
  if (!IS_BETA_OCR_ENABLED) return false;
  return !senderProcessSpecification?.meta?.ocr?.enabled;
};

export const useIsBetaOCR = ({
  targetRelationshipId,
  sourceMessageId,
}: {
  targetRelationshipId: string | undefined;
  sourceMessageId: string | undefined;
}) => {
  const { data: senderProcessSpecification, isLoading } = useSenderProcessSpecification({
    variables: { relationshipId: targetRelationshipId, sourceMessageId },
    enabled: IS_BETA_OCR_ENABLED,
  });

  return { isLoading, isBeta: isBetaOCR(senderProcessSpecification) };
};
