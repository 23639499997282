import { CellContext } from '@tanstack/react-table';
import { classNames } from 'support/helpers/generic/generic';
import {
  useCellClassNames,
  getCellNestedLevel,
  useLineItemsTransportUnitsConfig,
  isProcurosAddedValue,
} from '../cellHelpers';
import { Dropdown } from 'components/Form/Dropdown/Dropdown';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { ErrorIcon } from '../../../components/Icons';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useWebEDIErrors, useWebEDIFieldError } from 'services/webediErrors/webediErrors';
import { MessageFieldErrorModal, useMessageFieldErrorModal } from 'components/WebEDI/MessageFieldErrorModal';
import { CellProps } from '../../types';
import { useDataTableContext } from '../../../contexts/DataTableContext';
import { useWebEDIStore } from 'stores/webedi/webedi';
import { DropdownCellReadOnly, DropdownCellReadOnlyProps } from './DropdownCellReadOnly';
import { DisplayModes, WebEDIRowData } from '../../../Types';

type DropdownCellProps = {
  options: Array<{
    label: string;
    value: string | number | boolean;
  }>;
};
export type Props = CellContext<WebEDIRowData, unknown> & Omit<CellProps, 'register'> & DropdownCellProps;

export const DropdownCell = (props: Props) => {
  const { fieldId, column, table, row, isHighlighted, mode, options } = props;
  const { columnDef } = column;
  const isValidating = useWebEDIStore((state) => state.isValidating);
  const isAdded = isProcurosAddedValue(row);
  const { isReadOnly: isTransportUnitReadOnly } = useLineItemsTransportUnitsConfig(fieldId, row?.original);

  //Allow users to set values on all fields if customer created the row or is edit mode
  const isCellReadOnly = Boolean(columnDef.meta?.rowConfig?.[row.index]?.is_readonly);
  const readOnly =
    isTransportUnitReadOnly ||
    (Boolean(columnDef.meta?.readOnly || isCellReadOnly) && !isAdded && mode !== DisplayModes.edit);

  const level = getCellNestedLevel(row);
  const isNested = level > 1;
  const { error } = useWebEDIFieldError(fieldId);
  const hasError = Boolean(error);
  const { numberOfErrors, setCurrentError } = useWebEDIErrors();
  const { showFieldErrorModal, setShowFieldErrorModal, floatingStyles, refs } = useMessageFieldErrorModal();

  useEffect(() => {
    if (error && showFieldErrorModal) {
      setCurrentError(error);
    }
  }, [error, setCurrentError, showFieldErrorModal]);

  const onFocus = useCallback(() => {
    setShowFieldErrorModal(true);
  }, [setShowFieldErrorModal]);

  const onBlur = useCallback(() => {
    setShowFieldErrorModal(false);
  }, [setShowFieldErrorModal]);

  const cellClassNames = useCellClassNames({
    row,
    table,
    isFirstColumn: column.getIsFirstColumn(),
    isLastColumn: column.getIsLastColumn(),
  });

  const value = useWatch({ name: fieldId });
  const previousValue = useRef<string | undefined>(value);
  const { setValue } = useDataTableContext();
  useEffect(() => {
    if (!value && previousValue.current) {
      setValue(fieldId, previousValue.current);
    }
  }, [value, previousValue, setValue, fieldId]);

  useEffect(() => {
    let isCancelled = false;
    if (isModificationType(fieldId) && !value && !previousValue.current && options.length > 0) {
      setTimeout(() => {
        if (!isCancelled) {
          previousValue.current = options[0].value as string;
          setValue(fieldId, options[0].value);
        }
      }, 100);
    }

    return () => {
      isCancelled = true;
    };
  }, [value, fieldId, options, setValue]);

  if (readOnly) {
    return (
      <DropdownReadOnly
        cellProps={props}
        fieldId={fieldId}
        options={options}
        isNotShippedItemTransportUnit={isTransportUnitReadOnly}
      />
    );
  }

  return (
    <div
      ref={refs.setReference}
      className={classNames(
        'w-full p-1.5 border border-transparent',
        {
          'bg-red-50': hasError,
          'bg-amber-100': isHighlighted,
        },
        cellClassNames,
      )}
    >
      <Dropdown
        disabled={columnDef.meta?.disableIfNested && isNested}
        onChange={(value) => {
          if (value) {
            previousValue.current = value;
            setValue(fieldId, value);
          }
        }}
        value={value || previousValue.current}
        onFocus={onFocus}
        onOpen={onFocus}
        onBlur={onBlur}
        options={options}
        hasErrors={hasError}
        rightIcon={hasError ? <ErrorIcon className="bg-white !text-red-500" /> : undefined}
        name={fieldId}
        size="extra-small"
        buttonClassName="rounded-3xl"
      />
      {showFieldErrorModal && (
        <MessageFieldErrorModal
          error={error}
          viewMode={false}
          ref={refs.setFloating}
          style={floatingStyles}
          numberOfErrors={numberOfErrors}
          isValidating={isValidating}
        />
      )}
    </div>
  );
};

const useViewForm = ({ fieldId, options }: { fieldId: string; options: DropdownCellProps['options'] }) => {
  const value = useWatch({ name: fieldId });
  const valueForReadonly = useMemo(() => {
    return options?.find((option) => option.value === value)?.label;
  }, [value, options]);

  return useForm({ values: { [fieldId]: valueForReadonly } });
};

const DropdownReadOnly = ({
  fieldId,
  options,
  cellProps,
  isNotShippedItemTransportUnit,
}: {
  fieldId: string;
  options: DropdownCellProps['options'];
  cellProps: Props;
  isNotShippedItemTransportUnit: DropdownCellReadOnlyProps['isNotShippedItemTransportUnit'];
}) => {
  const formMethods = useViewForm({ fieldId, options });
  return (
    <FormProvider {...formMethods}>
      <DropdownCellReadOnly {...cellProps} isNotShippedItemTransportUnit={isNotShippedItemTransportUnit} />
    </FormProvider>
  );
};

//in case it is type of modification, set by default the first value
const isModificationType = (fieldId: string | undefined) => {
  return fieldId?.includes('.type') && fieldId?.includes('modification_groups');
};
