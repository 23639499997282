import { DialogTitle } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { BasePopupV2 } from 'components/Display/BasePopup/BasePopupV2';
import { Button } from 'components/Form/Button';
import { Translate } from 'components/Translate/Translate';
import { formatDayAndTime, formatTimestamp } from 'support/helpers/dateTime/dateTime';
import { Connectors, ReceiverEnvelopeDTO } from 'support/types';

type ProofOfDeliveryDetailsProps = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  receiverEnvelope: Required<Pick<ReceiverEnvelopeDTO, 'proofOfDelivery' | 'receiverIntegration'>>;
};

const formatTimestampToDayAndTime = formatTimestamp(formatDayAndTime);
export const ProofOfDeliveryDetails = ({ isOpen, setOpen, receiverEnvelope }: ProofOfDeliveryDetailsProps) => {
  const details = receiverEnvelope.proofOfDelivery;
  return (
    <BasePopupV2 open={isOpen} setOpen={setOpen} width="xl" className="max-h-[80%] overflow-auto">
      <div className="p-6">
        <DialogTitle as="h3" className="text-lg font-medium text-gray-900">
          <Translate i18nKey="webedi:proofOfDelivery.title" />
        </DialogTitle>

        <ul className="my-4 space-y-4">
          {details.map((detail: any) => (
            <li key={detail.delivered_to} className="flex w-full flex-col gap-2">
              <span className="flex justify-between text-sm text-gray-700">
                <span>
                  <Translate
                    i18nKey="webedi:proofOfDelivery.deliveredAt.label"
                    values={{
                      at: formatTimestampToDayAndTime(detail.delivered_at) || null,
                      to: detail.delivered_to,
                    }}
                  />
                </span>
                <CheckCircleIcon className="size-5 text-procuros-green-500" />
              </span>
              <pre className="block max-h-[300px] overflow-y-auto rounded-md border border-gray-300 bg-gray-50 px-3 py-2 text-sm text-gray-500">
                <code>
                  <Translate
                    i18nKey={`webedi:proofOfDelivery.proof.${[Connectors.EMAIL, Connectors.WEB_EDI].includes(receiverEnvelope.receiverIntegration.connector as Connectors) ? receiverEnvelope.receiverIntegration.connector : 'default'}`}
                    values={{ proof: detail.delivery_proof }}
                  />
                </code>
              </pre>
            </li>
          ))}
        </ul>
        <div className="flex flex-row-reverse">
          <Button analyticsId="close_proof_of_transmission" className="ml-auto mr-0" onClick={() => setOpen(false)}>
            <Translate i18nKey="common:close" />
          </Button>
        </div>
      </div>
    </BasePopupV2>
  );
};

/**
 * t('webedi:proofOfDelivery.proof.WEB_EDI')
 * t('webedi:proofOfDelivery.proof.EMAIL')
 * t('webedi:proofOfDelivery.proof.default')
 */
