import { ui } from '@procuros/datachecks';
import { useMonetaryConfigurations } from 'components/DataTable/cells/WebEDI/cellHelpers';
import { DisplayModes } from 'components/DataTable/Types';
import { DataDisplay } from 'components/Form/DataDisplay/DataDisplay';
import { MessageInput } from 'components/Form/MessageInput/MessageInput';
import { useWatch, useFormContext } from 'react-hook-form';
import { useWebEDIFieldError, useWebEDIErrors } from 'services/webediErrors/webediErrors';

type MonetaryFieldProps = {
  field: ui.MonetaryField;
  fieldId: string;
  isReadOnly: boolean;
  mode: DisplayModes;
};
export const MonetaryField = ({ field, fieldId, isReadOnly, mode }: MonetaryFieldProps) => {
  const value = useWatch({ name: fieldId });
  const { register } = useFormContext();
  const { error } = useWebEDIFieldError(fieldId);
  const { numberOfErrors, setCurrentError } = useWebEDIErrors();
  const { prefix, formattedValue } = useMonetaryConfigurations({
    value: value,
    mode: DisplayModes.create,
    rowData: {},
    fieldId,
  });

  if (mode === DisplayModes.view) {
    return (
      <DataDisplay
        value={formattedValue}
        label={field.label}
        totalNumberOfErrors={numberOfErrors}
        error={error}
        fieldId={fieldId}
        onShowFieldErrorModal={() => error && setCurrentError(error)}
      />
    );
  }
  return (
    <MessageInput
      type="number"
      title={formattedValue}
      label={field.label}
      error={error}
      leftAddon={prefix}
      totalNumberOfErrors={numberOfErrors}
      {...register(fieldId, { valueAsNumber: true })}
      value={value} //formattedValue is not used since it contains the currency symbol
      onShowFieldErrorModal={() => error && setCurrentError(error)}
      required={field.modality === 'MANDATORY'}
      disabled={isReadOnly}
      min={0}
      step={0.01}
    />
  );
};
