import { RowData } from '@tanstack/react-table';

/** internal columns which aren't used in a canonical document */
export enum ColumnTypesInternal {
  procuros_select = 'procuros_select',
  procuros_delete = 'procuros_delete',
  procuros_added = 'procuros_added',
  procuros_index = 'procuros_index',
}

/** Supported view modes */
export enum DisplayModes {
  view = 'view',
  edit = 'edit',
  create = 'create',
}

/** Supported data modes */
export enum DataRenderModes {
  auto = 'auto',
  plain = 'plain',
  scrollable = 'scrollable',
  virtualized = 'virtualized',
}

export type WebEDIRowData = {
  _internal?: {
    level?: number;
    [ColumnTypesInternal.procuros_added]?: boolean;
  };
} & Record<string, unknown> &
  RowData;
