type LineItem = {
  _internal: Record<string, any>;
  [key: string]: any;
};

export const reAddInternalInformation = (
  formValues: Record<string, any>,
  currentFormData: Record<string, any>,
): Record<string, any> => {
  if (!('line_items' in formValues)) return formValues;

  return {
    ...formValues,
    line_items: formValues.line_items?.map((lineItem: LineItem, index: number) => ({
      ...lineItem,
      _internal: {
        ...currentFormData.line_items?.[index]?._internal,
        ...lineItem._internal,
      },
    })),
  };
};

// 1. First, define a helper to retrieve the relevant identifier from a line item.
//    This consolidates checking for instruction_response_line_identifier,
//    receival_notice_line_identifier, or shipping_notice_line_identifier.
function getLineItemIdentifier(lineItem: any): string | undefined {
  const identifier =
    lineItem.instruction_response_line_identifier ||
    lineItem.receival_notice_line_identifier ||
    lineItem.shipping_notice_line_identifier;

  if (!identifier) return undefined;

  return `${identifier}-${lineItem.order_line_identifier || ''}`;
}

/*
 * We need to order the line items to make sure we have the same order as the form.
 *  Order changes when we move line items between transport units.
 * The canonical is {transport_units: [{line_items: []}, {line_items:[]}]}
 * If we move the line item from transport unit 0 to transport unit 1, the flatten order of line items in the canonical will change.
 */
//TODO: We need to review this function and make sure it works as expected
// We disabled it since there are some items with the same line_identifier during OCR. See CORE-6562

export const orderLineItems = (newLineItems: any, desiredOrder: any) => {
  // 2. Build a lookup Map from identifier -> lineItem
  const lookup = new Map<string, any>();

  newLineItems.forEach((li: any) => {
    const id = getLineItemIdentifier(li);
    if (id) lookup.set(id, li);
  });

  // 3. Go in the order of formData.line_items (desiredOrder)
  //    For each item in desiredOrder, pick the matching line item from our lookup.
  const ordered: Array<any> = [];
  const notFoundItems: Array<any> = [];

  desiredOrder.forEach((formItem: any) => {
    const formItemId = getLineItemIdentifier(formItem);

    if (formItemId && lookup.has(formItemId)) {
      ordered.push(lookup.get(formItemId));
      // Remove from lookup so we don’t accidentally pick it again
      lookup.delete(formItemId);
    }
  });

  // 4. Whatever remains in the lookup wasn’t in desiredOrder -> put them at the end.
  lookup.forEach((value) => {
    notFoundItems.push(value);
  });

  // 5. Concatenate to get the final ordered array
  return ordered.concat(notFoundItems);
};
