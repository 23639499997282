import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { usePylonJwt } from 'services/repositories/user/user';

export function PylonLogin() {
  const { data: pylonJwt } = usePylonJwt();
  const [searchParams] = useSearchParams();
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (!!pylonJwt && !!searchParams.get('redirect_uri') && formRef.current) {
      formRef.current.submit();
    }
  }, [pylonJwt, searchParams]);

  return (
    <div>
      <form ref={formRef} action={searchParams.get('redirect_uri') ?? ''} method="post">
        <input type="hidden" name="jwt" value={pylonJwt} />
      </form>
      loading...
    </div>
  );
}
