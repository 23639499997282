import { CellContext } from '@tanstack/react-table';
import classNames from 'classnames';
import { useValue } from 'components/WebEDI/helpers';
import { useMessageFieldErrorModal, MessageFieldErrorModal } from 'components/WebEDI/MessageFieldErrorModal';
import { useMemo, useEffect, FocusEventHandler, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useWebEDIErrors, useWebEDIFieldError } from 'services/webediErrors/webediErrors';
import { useCellClassNames, isRelativeModification, getFieldId, useEnumerableConfigurations } from '../cellHelpers';
import { CellProps } from '../../types';
import { Input } from 'components/Form/Input';
import { WebEDIRowData } from 'components/DataTable/Types';
import { useFormContext } from 'react-hook-form';
import { useWebEDIStore } from 'stores/webedi/webedi';

export type DropdownCellReadOnlyProps = CellContext<WebEDIRowData, unknown> &
  Omit<CellProps, 'register'> & {
    isNotShippedItemTransportUnit: boolean;
  };

export const DropdownCellReadOnly = ({
  cell,
  column,
  row,
  table,
  isHighlighted,
  isNotShippedItemTransportUnit,
  mode,
}: DropdownCellReadOnlyProps) => {
  const { t } = useTranslation();
  const isValidating = useWebEDIStore((state) => state.isValidating);
  const {
    columnDef: { meta: columnMeta, ...columnDef },
    getIsFirstColumn,
    getIsLastColumn,
  } = column;

  const { id } = cell;

  let fieldId = useMemo(
    () => getFieldId(columnDef, table.options.meta?.dataPrefix, id),
    [columnDef, id, table.options.meta?.dataPrefix],
  );

  const isLineItems = table.options.meta?.dataPrefix.includes('line_items');
  const isRelativeModificationRow = isRelativeModification(fieldId, row.original, mode, Boolean(isLineItems));

  if (isRelativeModificationRow && fieldId.endsWith('.amount')) {
    fieldId = fieldId.replace('.amount', '.percentage');
  }

  const inputValue = useValue(row.original, fieldId, columnMeta?.optionalConfig?.expression);
  const { formattedValue } = useEnumerableConfigurations({
    meta: columnMeta,
    value: inputValue,
  });

  //We need to use formContext since we have a fake form in there so we get the right translations
  const { register } = useFormContext();
  const { onBlur, ...otherRegister } = register(fieldId);
  const { showFieldErrorModal, setShowFieldErrorModal, floatingStyles, refs } = useMessageFieldErrorModal();
  const { error } = useWebEDIFieldError(fieldId);
  const { numberOfErrors, setCurrentError } = useWebEDIErrors();
  const cellClassNames = useCellClassNames({
    isLastColumn: getIsLastColumn(),
    isFirstColumn: getIsFirstColumn(),
    row,
    table,
  });

  const inputClassNames = classNames(
    error
      ? 'group-hover:bg-red-100 bg-red-50 focus:!bg-transparent disabled:!bg-red-50'
      : 'group-hover:bg-procuros-green-50 focus:!bg-transparent disabled:!bg-transparent',
    cellClassNames,
    'text-start',
    columnMeta?.optionalConfig?.isStatusColumn ? '!pl-10' : undefined,
  );

  useEffect(() => {
    if (showFieldErrorModal && error) {
      setCurrentError(error);
    }
  }, [showFieldErrorModal, error, setCurrentError]);

  const onBlurHandler: FocusEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      onBlur(e);
      setShowFieldErrorModal(false);
    },
    [onBlur, setShowFieldErrorModal],
  );

  const onFocusHandler = useCallback(() => {
    setShowFieldErrorModal(true);
  }, [setShowFieldErrorModal]);

  //We need the readOnly for the formatted value to be shown when rendering the type of the Parties
  //On number inputs we can't render formatted values because the input type is number and the formatted value will contain a currency symbol
  const valueToShow = isNotShippedItemTransportUnit
    ? t('webedi:line_items.field.transport_unit.not_shipped')
    : formattedValue || inputValue;

  return (
    <>
      <div
        className={classNames(
          'group flex justify-between gap-2 items-center w-full',
          {
            'bg-red-50 focus-within:!bg-transparent focus-within:ring-red-500 focus-within:shadow-red-500 hover:bg-red-100':
              error,
            'hover:bg-procuros-green-50 focus-within:!bg-transparent focus-within:ring-procuros-green-500 focus-within:shadow-procuros-green-500':
              !error,
            'bg-amber-100': isHighlighted,
          },
          cellClassNames,
        )}
        ref={refs.setReference}
      >
        <Input
          wrapperClassName="w-full"
          className={classNames(
            'bg-transparent w-full !border-none px-3 !outline-none text-xs !shadow-none rounded-none py-3 min-w-[140px]',
            inputClassNames,
          )}
          placeholder={
            !formattedValue && error?.type === 'required' ? t('common:table.errors.requiredField') : undefined
          }
          title={formattedValue ? String(formattedValue) : undefined}
          {...otherRegister}
          value={valueToShow ?? ''}
          readOnly={true}
          type="text"
          hasError={Boolean(error)}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          id={fieldId}
          data-field={fieldId}
          data-testid={fieldId}
          data-1p-ignore
        />
      </div>
      {showFieldErrorModal && (
        <MessageFieldErrorModal
          error={error}
          viewMode={false}
          ref={refs.setFloating}
          style={floatingStyles}
          numberOfErrors={numberOfErrors}
          isValidating={isValidating}
        />
      )}
    </>
  );
};
