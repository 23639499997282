import { ui } from '@procuros/datachecks';
import { useNumberConfigurations } from 'components/DataTable/cells/WebEDI/cellHelpers';
import { DisplayModes } from 'components/DataTable/Types';
import { DataDisplay } from 'components/Form/DataDisplay/DataDisplay';
import { MessageInput } from 'components/Form/MessageInput/MessageInput';
import { useWatch, useFormContext } from 'react-hook-form';
import { useWebEDIFieldError, useWebEDIErrors } from 'services/webediErrors/webediErrors';

type NumberFieldProps = {
  field: ui.NumberField;
  fieldId: string;
  isReadOnly: boolean;
  mode: DisplayModes;
};
export const NumberField = ({ field, fieldId, isReadOnly, mode }: NumberFieldProps) => {
  const value = useWatch({ name: fieldId });
  const { register } = useFormContext();
  const { error } = useWebEDIFieldError(fieldId);
  const { numberOfErrors, setCurrentError } = useWebEDIErrors();

  const { prefix, suffix, formattedValue } = useNumberConfigurations({
    meta: {
      optionalConfig: 'type_config' in field ? field.type_config : undefined,
      type: field.type,
    },
    value,
    mode: DisplayModes.create,
    fieldId,
    rowData: {}, //we don't need it since we don't need to format according to the data in the same row (there's no row in header)
  });

  if (mode === DisplayModes.view) {
    return (
      <DataDisplay
        value={formattedValue}
        label={field.label}
        totalNumberOfErrors={numberOfErrors}
        error={error}
        fieldId={fieldId}
        onShowFieldErrorModal={() => error && setCurrentError(error)}
      />
    );
  }
  return (
    <MessageInput
      type="number"
      label={field.label}
      error={error}
      title={formattedValue}
      leftAddon={prefix}
      rightAddon={suffix}
      totalNumberOfErrors={numberOfErrors}
      {...register(fieldId, { valueAsNumber: true })}
      value={value}
      onShowFieldErrorModal={() => error && setCurrentError(error)}
      required={field.modality === 'MANDATORY'}
      disabled={isReadOnly}
      min={0}
      step={0.01}
    />
  );
};
