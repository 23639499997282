import { WebEDISection } from 'components/WebEDI/WebEDISection/WebEDISection';
import classNames from 'classnames';
import { ui } from '@procuros/datachecks';
import { DisplayModes } from 'components/DataTable/Types';
import { DataTableProvider } from 'components/DataTable/contexts/DataTableContext';
import { DocumentSectionError } from '../DocumentSectionError/DocumentSectionError';
import { memo, useMemo } from 'react';
import { BooleanField } from './Fields/BooleanField';
import { DateField } from './Fields/DateField';
import { EnumerableField } from './Fields/EnumerableField';
import { MonetaryField } from './Fields/MonetaryField';
import { NumberField } from './Fields/NumberField';
import { TextField } from './Fields/TextField';

export const DocumentHeaderInfo = memo(({ config, mode }: { config: ui.InfoSection; mode: DisplayModes }) => {
  if (!config.fields?.length) return null;

  return (
    <WebEDISection header={{ title: config.label, description: config.description, spacing: 'medium' }}>
      <div className="space-y-4">
        <DocumentSectionError path="info" />
        <DataTableProvider name="info.0">
          <div className={classNames('grid gap-4 grid-cols-4')}>
            {config.fields.map((field) => (
              <Field key={field.path} mode={mode} field={field} />
            ))}
          </div>
        </DataTableProvider>
      </div>
    </WebEDISection>
  );
});

DocumentHeaderInfo.displayName = 'DocumentHeaderInfo';

const Field = ({ mode, field }: { mode: DisplayModes; field: ui.Field }) => {
  const fieldId = `info.0.${field.path}`;

  const readOnly = useMemo(() => {
    return Boolean(field.is_readonly) && mode !== DisplayModes.edit;
  }, [field.is_readonly, mode]);

  switch (field.type) {
    case ui.FieldTypes.select:
      return <EnumerableField field={field} fieldId={fieldId} isReadOnly={readOnly} mode={mode} />;
    case ui.FieldTypes.boolean:
      return <BooleanField field={field} fieldId={fieldId} isReadOnly={readOnly} mode={mode} />;

    case ui.FieldTypes.date:
    case ui.FieldTypes.date_time:
      return <DateField field={field} fieldId={fieldId} isReadOnly={readOnly} mode={mode} />;

    case ui.FieldTypes.monetary:
      return <MonetaryField field={field} fieldId={fieldId} isReadOnly={readOnly} mode={mode} />;

    case ui.FieldTypes.number:
      return <NumberField field={field} fieldId={fieldId} isReadOnly={readOnly} mode={mode} />;

    case ui.FieldTypes.text:
      return <TextField field={field} fieldId={fieldId} isReadOnly={readOnly} mode={mode} />;

    default:
      return null;
  }
};
