import { logError } from 'services/logging/logging';
import { LoaderResult } from '../../types';

type UploadLoaderOptions = {
  url: string;
  bearerToken: string;
  body?: Record<string, string | Blob | undefined | null>;
};

export const UploadLoader =
  (options: UploadLoaderOptions) =>
  async (file: File): Promise<LoaderResult> => {
    return new Promise((resolve, reject) => {
      // resolve({ data: { hash: 'testhash' } });
      // return;

      // throw new Error('Not implemented');

      // Upload to provided URL using formdata with the file and bearer token
      const formData = new FormData();
      formData.append('file', file);
      options?.body &&
        Object.keys(options.body).forEach((key) => {
          if (options.body![key]) formData.append(key, options.body![key]);
        });
      fetch(options.url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${options.bearerToken}`,
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((content) => {
          if (content.success === false) {
            console.log(content);
            reject(content.error);
          }
          resolve({ data: content.data });
        })
        .catch((e: unknown) => {
          console.log(e);
          logError(e);
          reject(e);
        });
    });
  };
