import { ChangeEventHandler, FocusEventHandler, forwardRef, useCallback, useEffect } from 'react';
import { Input } from '../Input/Input/Input';
import { MessageFieldErrorModal, useMessageFieldErrorModal } from 'components/WebEDI/MessageFieldErrorModal';
import { WebEDIError } from 'services/webediErrors/webediErrors';
import { useTranslation } from 'react-i18next';
import { TimestampDateInput } from '../TimestampDateInput/TimestampDateInput';
import { useWebEDIStore } from 'stores/webedi/webedi';

type MessageInputProps = Omit<Parameters<typeof Input>[0], 'errors' | 'hasError'> & {
  error: WebEDIError | undefined;
  onShowFieldErrorModal?: (showFieldErrorModal: boolean) => void;
  totalNumberOfErrors: number;
};
export const MessageInput = forwardRef<HTMLInputElement, MessageInputProps>(
  ({ error, totalNumberOfErrors, onBlur, onShowFieldErrorModal, ...otherProps }, ref) => {
    const { t } = useTranslation();
    const isValidating = useWebEDIStore((state) => state.isValidating);
    const { showFieldErrorModal, setShowFieldErrorModal, floatingStyles, refs } = useMessageFieldErrorModal();
    const onInputFocus = useCallback(() => {
      setShowFieldErrorModal(true);
    }, [setShowFieldErrorModal]);

    const onInputBlur: FocusEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        onBlur?.(e);
        setShowFieldErrorModal(false);
      },
      [onBlur, setShowFieldErrorModal],
    );

    const handleOnChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      (event) => {
        otherProps.onChange?.(event);
      },
      [otherProps],
    );

    useEffect(() => {
      if (error && showFieldErrorModal) {
        onShowFieldErrorModal?.(true);
      }
    }, [error, onShowFieldErrorModal, showFieldErrorModal]);

    const InputComponent = otherProps.type === 'date' ? TimestampDateInput : Input;
    if (!otherProps.name) {
      return null;
    }

    return (
      <div>
        <InputComponent
          {...otherProps}
          value={otherProps.value ?? ''}
          placeholder={
            !otherProps.value && error?.type === 'required' ? t('common:table.errors.requiredField') : undefined
          }
          onChange={handleOnChange}
          ref={ref}
          wrapperRef={refs.setReference}
          description={undefined}
          errors={undefined}
          hasError={Boolean(error)}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          name={otherProps.name}
          data-field={otherProps.name}
          min={otherProps.type === 'number' ? 0 : undefined}
          data-1p-ignore={true}
        />

        {showFieldErrorModal && (
          <MessageFieldErrorModal
            viewMode={false}
            error={error}
            ref={refs.setFloating}
            style={floatingStyles}
            numberOfErrors={totalNumberOfErrors}
            isValidating={isValidating}
          />
        )}
      </div>
    );
  },
);

MessageInput.displayName = 'MessageInput';
