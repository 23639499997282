import { ui } from '@procuros/datachecks';
import { DataTable, useGenerateWebEDIColumns } from 'components/DataTable';
import { useDataTableContext } from 'components/DataTable/contexts/DataTableContext';
import { ColumnTypesInternal, DisplayModes } from 'components/DataTable/Types';
import { useEmptyStructure } from 'hooks/useObjectStructure';
import { ReactNode, Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { WebEDISection } from '../WebEDISection/WebEDISection';
import { Button } from 'components/Form/Button';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useRemovePercentageColumn } from '../hooks/useRemovePercentageColumn';

export const DocumentModificationGroup = ({
  config,
  dataPrefix,
  mode,
  multiLevel,
}: {
  config: ui.GlobalModificationsSubSection;
  dataPrefix: string;
  mode: DisplayModes;
  multiLevel?: boolean;
}) => {
  const { t } = useTranslation();
  const { fields, append } = useDataTableContext();
  const configWithouPercentange = useRemovePercentageColumn(config.fields);
  const columns = useGenerateWebEDIColumns({
    config: configWithouPercentange,
    data: fields,
    deletableRows: mode === DisplayModes.create || mode === DisplayModes.edit,
  });

  const ViewModeWrapper = useCallback(
    ({ children }: { children: ReactNode }) => (
      <WebEDISection header={{ title: '', description: config.label, spacing: 'small' }}>{children}</WebEDISection>
    ),
    [config.label],
  );
  const emptyModification = useEmptyStructure(fields);
  const Wrapper = mode === DisplayModes.view ? ViewModeWrapper : Fragment;
  return (
    <Wrapper>
      <DataTable
        meta={{ dataPrefix: dataPrefix, mode }}
        columns={columns}
        data={fields}
        emptyState={
          multiLevel
            ? t(`webedi:${config.path}.empty_state.multi_level.${mode}.text`)
            : t(`webedi:${config.path}.empty_state.one_level.${mode}.text`)
        }
        footerMenu={
          (mode === DisplayModes.create || mode === DisplayModes.edit) && (
            <Button
              variant="secondary"
              size="extra-small"
              LeftIcon={PlusIcon}
              onClick={() =>
                append({ ...emptyModification, _internal: { [ColumnTypesInternal.procuros_added]: true } })
              }
              analyticsId="webedi:modification_group_modification_add"
            >
              {t(`webedi:${config.path}.add_button.text`)}
            </Button>
          )
        }
        enableGlobalSearch={false}
        equalWidthColumns
      />
    </Wrapper>
  );
};

/**
 * i18n
 * t('webedi:allowances.empty_state.multi_level.view.text')
 * t('webedi:allowances.empty_state.multi_level.create.text')
 * t('webedi:allowances.empty_state.multi_level.edit.text')
 * t('webedi:charges.empty_state.multi_level.view.text')
 * t('webedi:charges.empty_state.multi_level.create.text')
 * t('webedi:charges.empty_state.multi_level.edit.text')
 * t('webedi:allowances.empty_state.one_level.view.text')
 * t('webedi:allowances.empty_state.one_level.create.text')
 * t('webedi:allowances.empty_state.one_level.edit.text')
 * t('webedi:charges.empty_state.one_level.view.text')
 * t('webedi:charges.empty_state.one_level.create.text')
 * t('webedi:charges.empty_state.one_level.edit.text')
 * t('webedi:allowances.add_button.text')
 * t('webedi:charges.add_button.text')
 */
