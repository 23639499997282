import { useEffect, useState, useMemo, ReactNode } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Connectors, DashboardRoutes, IntegrationDTO, IntegrationsRoutes, PartnerDTO } from 'support/types';
import { TFunction } from 'i18next';
import { GenericError, processSubmissionErrors } from 'support/helpers/errors/errors';
import {
  WebEDIConfiguration,
  useUpdateWebEDIPartner,
  webEDIConfigurationSchema,
} from '../ConnectorSteps/WebEDI/helpers';
import { useNavigate } from 'react-router-dom';
import { useCreateIntegration } from 'services/repositories/integrations/integrations';
import { routeToPage } from 'support/helpers/navigation/navigation';
import { Section } from 'components/Display/Section/Section';
import { Radio } from 'components/Form/Radio/Radio';
import { addGlobalPopup, removeGlobalPopup } from 'stores/globalPopups/globalPopups';
import { IntegrationSetupCompleteModal } from 'components/IntegrationSetupCompleteModal/IntegrationSetupCompleteModal';
import { IntegrationConfigurationFormState } from './types';
import { Form } from 'components/Form/Form';
import { Input } from 'components/Form/Input/Input/Input';
import { ValidationErrors } from 'components/Form/ValidationErrors/ValidationErrors';
import { Button } from 'components/Form/Button/Button';
import { logError } from 'services/logging/logging';

type IntegrationConfigurationFormProps = {
  partner: PartnerDTO;
  integration: Pick<Required<IntegrationDTO>, 'name' | 'connector' | 'environment' | 'handle'> &
    Pick<Partial<IntegrationDTO>, 'id' | 'hasValidConfiguration' | 'configuration'>;
  mainForm: ReactNode;
  header: {
    name: boolean;
    environment: boolean;
  };
  defaultConfiguration: WebEDIConfiguration | any;
};

export const IntegrationConfigurationForm = ({
  partner,
  integration,
  mainForm,
  header,
  defaultConfiguration,
}: IntegrationConfigurationFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [nonInputErrors, setNonInputErrors] = useState<Array<string>>([]);
  const [submissionError, setSubmissionError] = useState<Error>();
  const { mutateAsync: createMutation, isLoading: isLoadingCreateMutation } = useCreateIntegration();
  const { mutateAsync: updateWebEDIIntegrationPartner, isLoading: isUpdatingWebEDI } = useUpdateWebEDIPartner({
    partner,
  });

  let isLoading;
  if (isWebEDIIntegrationGuard(integration)) {
    isLoading = isLoadingCreateMutation || isUpdatingWebEDI;
  } else {
    isLoading = true;
    logError(new GenericError('Invalid integration type', { integration }));
  }

  const createWebEDI = async (data: IntegrationConfigurationFormState) => {
    await updateWebEDIIntegrationPartner(data);
    await createMutation({
      name: data.name,
      environment: data.environment,
      type: data.type as Connectors.WEB_EDI & Connectors.AS2,
    });

    addGlobalPopup(<IntegrationSetupCompleteModal onClose={removeGlobalPopup} />);

    navigate(routeToPage(DashboardRoutes.root));
  };

  const submitCreate = async (data: IntegrationConfigurationFormState) => {
    setSubmissionError(undefined);
    try {
      if (isWebEDIFormStateGuard(data)) {
        await createWebEDI(data);
      } else {
        logError(new GenericError('Invalid integration type', { data }));
      }
    } catch (error) {
      setSubmissionError(error as Error);
    }
  };

  const defaultData = useMemo<IntegrationConfigurationFormState>(
    () => ({
      type: integration.connector as IntegrationConfigurationFormState['type'],
      handle: integration.handle,
      name: getIntegrationDefaultName({
        integrationName: integration.name,
        integrationConnector: integration.connector,
        partnerName: partner?.name,
        environment: integration.environment,
        t,
      }),
      environment: integration.environment,
      configuration: integration.configuration || defaultConfiguration,
    }),
    [
      integration.name,
      integration.environment,
      integration.connector,
      integration.handle,
      integration.configuration,
      partner?.name,
      defaultConfiguration,
      t,
    ],
  );

  const formMethods = useForm<IntegrationConfigurationFormState>({
    resolver: yupResolver(generateSchema(integration?.connector, t)),
    values: defaultData,
  });

  const {
    setError,
    handleSubmit,
    register,
    formState: { errors, dirtyFields },
    control,
    setValue,
  } = formMethods;

  const [environment] = useWatch({
    control,
    name: ['environment'],
  });

  useEffect(() => {
    if (!dirtyFields.name) {
      setValue(
        'name',
        getIntegrationDefaultName({
          integrationName: integration.name,
          integrationConnector: integration.connector,
          partnerName: partner?.name,
          environment,
          t,
        }),
      );
    }
  }, [dirtyFields, environment, integration, partner, setValue, t]);

  useEffect(() => {
    if (submissionError) {
      processSubmissionErrors({
        error: submissionError,
        setInputError: setError,
        setNonInputErrors,
        defaultData,
        t,
      });
    } else {
      setNonInputErrors([]);
    }
  }, [defaultData, setError, submissionError, t]);

  if (isNotWebEDIIntegrationGuard(integration) || isUpdateModeGuard(integration)) {
    logError(
      new GenericError('Invalid integration for configuration', {
        integration,
        isNotWebEDIIntegration: isNotWebEDIIntegrationGuard(integration),
        isUpdateMode: isUpdateModeGuard(integration),
      }),
    );
    return null;
  }

  const dropdownOptions: Array<{ label: string; value: IntegrationDTO['environment'] }> = [
    { label: t('integrations:environments.PRODUCTION'), value: 'PRODUCTION' },
    { label: t('integrations:environments.TESTING'), value: 'TESTING' },
  ];

  return (
    <>
      <ValidationErrors errors={nonInputErrors} />
      <Form onSubmit={handleSubmit(submitCreate)} className="space-y-8 divide-y divide-gray-200 *:pt-8 first:*:pt-0">
        {header.environment ? (
          <Section.Horizontal
            title={t('integrations:new.form.section.environment.title')}
            description={t('integrations:new.form.section.environment.description')}
          >
            <Radio.Group>
              {dropdownOptions.map((option) => (
                <Radio.Item key={option.value} label={option.label} value={option.value} {...register('environment')} />
              ))}
            </Radio.Group>
          </Section.Horizontal>
        ) : null}

        {header.name ? (
          <Section.Horizontal
            title={t('integrations:new.form.section.name.title')}
            description={t('integrations:new.form.section.name.description')}
          >
            <Input {...register('name')} required label={t('integrations:new.form.name.label')} errors={errors.name} />
          </Section.Horizontal>
        ) : null}

        <FormProvider {...formMethods}>{mainForm}</FormProvider>
        <div className="flex items-center gap-2">
          <Button
            variant="secondary"
            onClick={() => {
              navigate(IntegrationsRoutes.root);
            }}
            analyticsId="integrations:new_integration_cancel"
          >
            {t('common:cancel')}
          </Button>
          <Button type="submit" loading={isLoading} analyticsId="integrations:new_integration_create">
            {t(`integrations:new.form.submit.${integration.connector}`)}
          </Button>
        </div>
      </Form>
    </>
  );
};

const generateSchema = (type: string | undefined, t: TFunction) => {
  let configurationSchema = Yup.object().shape({});
  switch (type) {
    case Connectors.WEB_EDI:
      configurationSchema = webEDIConfigurationSchema(t);
      break;
  }
  return Yup.object().shape({
    type: Yup.string().oneOf([Connectors.WEB_EDI]).required(),
    name: Yup.string().required(
      t('common:form.required', {
        field: t('integrations:new.form.name.label'),
      }) ?? '',
    ),
    environment: Yup.string().oneOf(['TESTING', 'PRODUCTION']).required(),
    configuration: configurationSchema,
  });
};

const isWebEDIFormStateGuard = (
  data: IntegrationConfigurationFormState,
): data is IntegrationConfigurationFormState & { type: Connectors.WEB_EDI } => {
  return data.type === Connectors.WEB_EDI;
};

const isNotWebEDIIntegrationGuard = <T extends IntegrationConfigurationFormProps['integration']>(
  integration: T,
): integration is T & { connector: Exclude<Connectors, Connectors.WEB_EDI> } => {
  return !isWebEDIIntegrationGuard(integration);
};

const isWebEDIIntegrationGuard = <T extends IntegrationConfigurationFormProps['integration']>(
  integration: T,
): integration is T & { connector: typeof Connectors.WEB_EDI } => {
  return integration.connector === Connectors.WEB_EDI;
};

const isUpdateModeGuard = <T extends IntegrationConfigurationFormProps['integration']>(
  integration: T,
): integration is T & { id: string } => {
  return integration.id !== undefined;
};

const getIntegrationDefaultName = ({
  integrationName,
  integrationConnector,
  partnerName,
  environment,
  t,
}: {
  integrationName?: string;
  integrationConnector: string;
  partnerName?: string;
  environment: string;
  t: TFunction;
}) => {
  return integrationName
    ? integrationName
    : t('integrations:new.form.name.default_name', {
        partner: partnerName,
        environment: t(`integrations:environments.${environment}`),
        connector: t(`integrations:connector.${integrationConnector}.name`),
      });
};

/** i18n */
// t('integrations:new.form.submit.WEB_EDI')
