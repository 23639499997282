import { createColumnHelper, ColumnDef, ColumnHelper } from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';
import { HeaderCell } from '../cells/HeaderCell';
import { ColumnTypesInternal, WebEDIRowData } from '../Types';
import { cellMapping } from '../cellMapping';
import { selectionPartial } from '../columns/selectionPartial';
import { deletionPartial } from '../columns/WebEDI/deletionPartial';
import { ui } from '@procuros/datachecks';
import { logError } from 'services/logging/logging';
import { nonNullable } from 'support/helpers/generic/generic';
import { BodyCell } from '../cells/WebEDI/BodyCell';
import { t } from 'i18next';
import { useDataTableContext } from '../contexts/DataTableContext';
import { indexColumn } from '../columns/indexColumn';

// Utility function to determine if a field has a type config
const hasTypeConfig = (field: ui.Field): field is ui.NumberField | ui.EnumerableField => {
  return 'type_config' in field;
};

// Function to generate individual columns based on the field type and config
const generateColumn = ({
  columnProps,
  columnHelper,
  selectableRows,
  headerMenuActions,
  nestedLevelField,
}: GenerateProps<WebEDIRowData>): ColumnDef<WebEDIRowData> | null => {
  const columnType = columnProps.type;
  const [ViewComponent, EditComponent] = cellMapping[columnType];
  if (!ViewComponent || !EditComponent) {
    logError(`No cell mapping for column type: ${columnType}`);
    return null;
  }

  const optionalConfig = hasTypeConfig(columnProps)
    ? { ...columnProps.type_config, ...columnProps.extraFields, expression: columnProps.jsonata_expression }
    : { ...columnProps.extraFields, expression: columnProps.jsonata_expression };

  const style = {
    textAlign:
      optionalConfig && 'textAlign' in optionalConfig
        ? (optionalConfig.textAlign as 'right' | 'left')
        : [ui.FieldTypes.number, ui.FieldTypes.monetary].includes(columnType)
          ? ('right' as const)
          : ('left' as const),
    width: columnProps.extraFields?.width,
  };
  const required = columnProps.modality === 'MANDATORY';

  const meta = {
    type: columnProps.type,
    label: columnProps.label,
    readOnly: columnProps.is_readonly,
    required,
    optionalConfig,
    style,
    rightAddon: columnProps.extraFields?.rightAddon,
    leftAddon: columnProps.extraFields?.leftAddon,
    showNestLevel: columnProps.path === nestedLevelField,
    disableIfNested: columnProps.extraFields?.disabledIfNested,
    rowConfig: columnProps.row_config,
  };

  if (!columnProps.path) {
    return columnHelper.accessor(`expression_${columnProps.jsonata_expression}` as any, {
      id: `expression_${columnProps.jsonata_expression}`,
      header: (props) => (
        <HeaderCell
          {...props}
          tooltip={columnProps.description}
          required={required}
          mode={props.table.options.meta?.mode}
          selectableRows={selectableRows}
          actions={headerMenuActions}
        >
          {columnProps.label || columnProps.path}
        </HeaderCell>
      ),
      cell: BodyCell,
      footer: undefined,
      meta,
    });
  }

  return columnHelper.accessor(columnProps.path as any, {
    id: columnProps.path,
    size: columnProps.extraFields?.width,
    header: (props) => (
      <HeaderCell
        {...props}
        tooltip={columnProps.description}
        required={required}
        mode={props.table.options.meta?.mode}
        selectableRows={selectableRows}
        actions={headerMenuActions}
      >
        {columnProps.label || columnProps.path}
      </HeaderCell>
    ),
    cell: BodyCell,
    meta,
  });
};

// Function to generate all columns, including multi-column fields
const generateAllColumns = ({
  config,
  data,
  columnHelper,
  selectableRows,
  deleteField,
  nestedLevelField,
}: GenerateAllColumnsProps<WebEDIRowData>): Array<ColumnDef<WebEDIRowData>> => {
  return config
    .map((columnProps) => {
      if (columnProps.type === ui.FieldTypes.multi_column && data) {
        return generateMultiColumnColumns({ columnProps, columnHelper, selectableRows, data, deleteField });
      }
      return generateColumn({ columnProps, columnHelper, selectableRows, nestedLevelField });
    })
    .flat()
    .filter(nonNullable);
};

// Function to handle multi-column fields
const generateMultiColumnColumns = ({
  columnProps,
  columnHelper,
  selectableRows,
  data,
  deleteField,
}: GenerateMultiColumnProps<WebEDIRowData>): Array<ColumnDef<WebEDIRowData>> => {
  const requiredColumns = ['MANDATORY', 'CONDITIONAL'].includes(columnProps.modality);
  const defaultNumberOfColumns = requiredColumns ? 1 : 0;
  const neededGroups =
    data?.reduce((maxGroups, row) => {
      if (columnProps.path === undefined) {
        return maxGroups;
      }
      const rowData = (row as Record<string, any>)[columnProps.path] || [];
      return Math.max(rowData.length, maxGroups);
    }, 0) || defaultNumberOfColumns;

  const columns = Array.from({ length: neededGroups })
    .flatMap((_, index) =>
      columnProps.type_config.fields.map((subColumnProps) => {
        const newPath = `${columnProps.path}.${index}.${subColumnProps.path.replaceAll('*.', '')}`; //removing the wildcard since it should not be there
        return generateColumn({
          columnProps: {
            ...subColumnProps,
            label: `${columnProps.label} ${index + 1} - ${subColumnProps.label}`,
            path: newPath,
          } as ui.Field,
          columnHelper,
          selectableRows,
          headerMenuActions: [
            {
              label: t('common:data_table.cells.header_menu.delete', { index: index + 1, label: columnProps.label }),
              onClick: () => deleteField(index),
              className: 'text-red-700',
              analyticsId: 'table_column_delete',
            },
          ],
        });
      }),
    )
    .filter(nonNullable);
  return columns;
};

// Main function to create columns based on provided configuration
const createColumns = (
  config: Array<ui.Field>,
  data: Array<WebEDIRowData> | undefined,
  columnHelper: ColumnHelper<WebEDIRowData>,
  selectableRows: boolean,
  deletableRows: boolean,
  deleteBatchFromAllItems: (batchIndex: number) => void,
  deleteQuantityField?: string,
  nestedLevelField?: string,
  withIndexColumn?: boolean,
): Array<ColumnDef<WebEDIRowData>> => {
  const columns = generateAllColumns({
    config,
    data,
    columnHelper,
    selectableRows,
    deleteField: deleteBatchFromAllItems,
    nestedLevelField,
  });

  if (withIndexColumn && !columns.some(({ id }) => id === ColumnTypesInternal.procuros_index)) {
    columns.unshift(indexColumn(columnHelper));
  }

  if (deletableRows && !columns.some(({ id }) => id === ColumnTypesInternal.procuros_delete)) {
    columns.unshift(deletionPartial(deleteQuantityField, columnHelper));
  }
  if (selectableRows && !columns.some(({ id }) => id === ColumnTypesInternal.procuros_select)) {
    columns.unshift(selectionPartial(columnHelper));
  }

  return columns;
};

// Hook to generate columns based on the provided configuration and data
export const useGenerateWebEDIColumns = ({
  config,
  selectableRows,
  deletableRows,
  withIndexColumn,
  deleteQuantityField,
  data,
  nestedLevelField,
}: HookProps<WebEDIRowData>): Array<ColumnDef<WebEDIRowData, unknown>> => {
  const { setValue } = useDataTableContext();
  const columnHelper = useMemo(() => createColumnHelper<WebEDIRowData>(), []);

  const deleteBatchFromAllItems = useCallback(
    (indexToDelete: number) => {
      if (data) {
        setValue(
          undefined,
          data.map((field: any) => ({
            ...field,
            batches: field.batches?.filter((_: any, index: number) => index !== indexToDelete),
          })),
        );
      }
    },
    [data, setValue],
  );

  return useMemo(
    () =>
      createColumns(
        config,
        data,
        columnHelper,
        Boolean(selectableRows),
        Boolean(deletableRows),
        deleteBatchFromAllItems,
        deleteQuantityField,
        nestedLevelField,
        withIndexColumn,
      ),
    [
      config,
      data,
      columnHelper,
      selectableRows,
      deletableRows,
      deleteBatchFromAllItems,
      deleteQuantityField,
      nestedLevelField,
      withIndexColumn,
    ],
  );
};

// TypeScript types for function props
type GenerateProps<TData> = {
  columnProps: ui.Field & { extraFields?: Record<string, any> };
  columnHelper: ColumnHelper<TData>;
  selectableRows?: boolean;
  headerMenuActions?: Parameters<typeof HeaderCell>[0]['actions'];
  nestedLevelField?: string;
};

type GenerateAllColumnsProps<TData> = {
  config: Array<ui.Field>;
  data: Array<TData> | undefined;
  columnHelper: ColumnHelper<TData>;
  selectableRows?: boolean;
  deleteField: (index: number) => void;
  nestedLevelField?: GenerateProps<TData>['nestedLevelField'];
};

type GenerateMultiColumnProps<TData> = Omit<GenerateProps<TData>, 'columnProps'> & {
  data: Array<TData>;
  columnProps: ui.MultiColumnField;
  deleteField: (index: number) => void;
};

export type HookProps<TData> = {
  config: Array<ui.Field>;
  selectableRows?: boolean;
  deletableRows?: boolean;
  deleteQuantityField?: string;
  withIndexColumn?: boolean;
  data?: Array<TData>;
  nestedLevelField?: GenerateAllColumnsProps<TData>['nestedLevelField'];
};
