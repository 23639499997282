import { CellContext } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { classNames } from 'support/helpers/generic/generic';
import { EMPTY_FIELD } from 'support/helpers/const/const';
import { cloneElement } from 'react';
import useIsTruncated from 'hooks/useIsTruncated';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/Display/Tooltip/Tooltip';
import { DisplayModes, WebEDIRowData } from 'components/DataTable/Types';
import { ErrorIcon } from 'components/DataTable/components/Icons';
import { useCellClassNames, getCellNestedLevel, useNumberConfigurations } from '../cellHelpers';
import { NestedLevelAddon } from '../components/NestedLevelAddon/NestedLevelAddon';
import { CellProps } from '../../types';
import { useController } from 'react-hook-form';
import { ViewWrapperCell } from './ViewWrapperCell';
import { useValue } from 'components/WebEDI/helpers';

export type Props = CellContext<WebEDIRowData, unknown> & Pick<CellProps, 'fieldId' | 'isHighlighted'>;

export const ViewNumberCell = ({ row, cell, column, table, isHighlighted, fieldId }: Props) => {
  const { t } = useTranslation();
  const {
    fieldState: { error },
  } = useController({ name: fieldId });

  const {
    columnDef: { meta: columnMeta },
  } = column;

  const value = useValue(row.original, fieldId, column.columnDef.meta?.optionalConfig?.expression);
  const { prefix, suffix, formattedValue } = useNumberConfigurations({
    meta: columnMeta,
    value,
    mode: DisplayModes.view,
    rowData: row.original,
    fieldId,
  });

  const cellClassNames = useCellClassNames({
    row,
    table,
    isFirstColumn: column.getIsFirstColumn(),
    isLastColumn: column.getIsLastColumn(),
  });
  const cellNestedLevel = columnMeta?.showNestLevel ? getCellNestedLevel(row) : 0;
  const [truncatedRef, valueIsTruncated] = useIsTruncated();

  return (
    <ViewWrapperCell fieldId={fieldId} isHighlighted={isHighlighted} className={cellClassNames}>
      {error ? <ErrorIcon /> : null}
      <Tooltip enabled={valueIsTruncated}>
        <TooltipTrigger>
          <span className="flex w-full items-center">
            <div
              className={classNames('flex w-full gap-x-0.5 overflow-hidden tabular-nums', {
                'justify-end': columnMeta?.optionalConfig?.textAlign !== 'left',
                'justify-start': columnMeta?.optionalConfig?.textAlign === 'left',
              })}
              style={{ width: columnMeta?.optionalConfig?.width }}
            >
              {cellNestedLevel > 1 ? (
                <span className="pr-1.5">
                  <NestedLevelAddon level={cellNestedLevel} />
                </span>
              ) : null}
              {prefix && formattedValue ? <span className="text-gray-500">{prefix}</span> : null}
              {!formattedValue && error ? (
                t('common:table.errors.requiredField')
              ) : formattedValue ? (
                <span className="flex-initial truncate" ref={truncatedRef}>
                  {formattedValue}
                </span>
              ) : (
                <span className="text-gray-400">{EMPTY_FIELD}</span>
              )}
              {suffix && formattedValue ? <span className="text-gray-500">{suffix}</span> : null}
            </div>
            {columnMeta?.rightAddon && !error ? (
              <span className="text-gray-500">{cloneElement(columnMeta.rightAddon, { cell })}</span>
            ) : null}
          </span>
        </TooltipTrigger>
        <TooltipContent>{formattedValue}</TooltipContent>
      </Tooltip>
    </ViewWrapperCell>
  );
};
