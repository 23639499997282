import { CellContext } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { classNames } from 'support/helpers/generic/generic';
import { EMPTY_FIELD } from 'support/helpers/const/const';
import { useValue } from 'components/WebEDI/helpers';
import { cloneElement } from 'react';
import useIsTruncated from 'hooks/useIsTruncated';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/Display/Tooltip/Tooltip';
import { ErrorIcon } from 'components/DataTable/components/Icons';
import { useLineItemsTransportUnitsConfig, useCellClassNames, useEnumerableConfigurations } from '../cellHelpers';
import { CellProps } from '../../types';
import { ViewWrapperCell } from './ViewWrapperCell';
import { useWebEDIFieldError } from 'services/webediErrors/webediErrors';
import { WebEDIRowData } from 'components/DataTable/Types';

export type Props = CellContext<WebEDIRowData, unknown> & Pick<CellProps, 'fieldId' | 'isHighlighted'>;
export const ViewEnumerableCell = ({ row, cell, column, table, isHighlighted, fieldId }: Props) => {
  const { t } = useTranslation();
  const { error } = useWebEDIFieldError(fieldId);
  const value = useValue(row.original, fieldId, column.columnDef.meta?.optionalConfig?.expression);
  const { isReadOnly: isTransportUnitReadOnly } = useLineItemsTransportUnitsConfig(fieldId, row?.original);

  const {
    columnDef: { meta: columnMeta },
  } = column;

  const { formattedValue } = useEnumerableConfigurations({
    meta: columnMeta,
    value,
  });

  const valueToShow = isTransportUnitReadOnly
    ? t('webedi:line_items.field.transport_unit.not_shipped')
    : formattedValue;

  const cellClassNames = useCellClassNames({
    row,
    table,
    isFirstColumn: column.getIsFirstColumn(),
    isLastColumn: column.getIsLastColumn(),
  });
  const [truncatedRef, valueIsTruncated] = useIsTruncated();

  return (
    <ViewWrapperCell fieldId={fieldId} isHighlighted={isHighlighted} className={cellClassNames}>
      <Tooltip enabled={valueIsTruncated}>
        <TooltipTrigger>
          <span className="flex w-full items-center">
            <div
              className={classNames('flex w-full gap-x-0.5 overflow-hidden', {
                'justify-start': columnMeta?.optionalConfig?.textAlign === 'left',
              })}
              style={{ width: columnMeta?.optionalConfig?.width }}
            >
              {!valueToShow && error ? (
                t('common:table.errors.requiredField')
              ) : valueToShow ? (
                <span className="flex-initial truncate" ref={truncatedRef}>
                  {valueToShow}
                </span>
              ) : (
                <span className="text-gray-400">{EMPTY_FIELD}</span>
              )}
            </div>
            {columnMeta?.rightAddon && !error ? (
              <span className="text-gray-500">{cloneElement(columnMeta.rightAddon, { cell })}</span>
            ) : null}
          </span>
        </TooltipTrigger>
        <TooltipContent>{valueToShow}</TooltipContent>
      </Tooltip>
      {error ? <ErrorIcon /> : null}
    </ViewWrapperCell>
  );
};
