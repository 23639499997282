import { useOrderResponseSummary } from 'components/WebEDI/hooks/useOrderResponseSummary';
import { useTranslation } from 'react-i18next';
import { formatTimestampToDay } from 'support/helpers/dateTime/dateTime';
import { WebEDISidebarTableContent } from '../../WebEDISidebarTableContent/WebEDISidebarTableContent';

export const OrderResponseSummary = () => {
  const { t } = useTranslation();
  const orderResponseSummary = useOrderResponseSummary();
  return (
    <WebEDISidebarTableContent
      items={[
        {
          label: t('webedi:documentSummary.orderResponse.totalQuantity.label'),
          value: Math.round(orderResponseSummary.totalQuantity),
        },
        {
          label: t('webedi:documentSummary.orderResponse.lineCount.label'),
          value: Math.round(orderResponseSummary.lineCount),
        },
        {
          label: t('webedi:documentSummary.orderResponse.deliveryDate.label'),
          value: formatTimestampToDay(orderResponseSummary.deliveryDate) ?? '—',
        },
      ]}
    />
  );
};
