import { Page } from 'components/Page/Page';
import { Placeholder } from 'components/Placeholder/Placeholder';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routeToPage } from 'support/helpers/navigation/navigation';
import { TransactionsRoutes } from 'support/types';
import { DroppedMessageAlert } from '../DroppedMessageAlert/DroppedMessageAlert';
import { MessageHeader } from './MessageHeader/MessageHeader';
import { useMessage } from 'services/repositories/messages/messages';
import { useCurrentPartner } from 'services/repositories/partners/partners';
import { ArrowLeftIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { WebEDISidebar } from 'components/WebEDI/WebEDISidebar/WebEDISidebar';
import { DisplayModes } from 'components/DataTable/Types';

export const UnkownTypePlaceholder = ({ messageId }: { messageId: string }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: currentPartner } = useCurrentPartner();
  const { data, isLoading } = useMessage({
    variables: {
      messageId,
    },
  });

  const message = data?.data;
  const relatedTransactions = data?.related_messages;

  return (
    <Page isLoading={isLoading}>
      <Page.Head>
        {message ? (
          <MessageHeader
            message={message}
            relatedTransactions={relatedTransactions || []}
            currentPartner={currentPartner}
            mode={DisplayModes.view}
          />
        ) : null}
      </Page.Head>
      <Page.Section
        rightAside={
          <WebEDISidebar document={message} relatedTransactions={relatedTransactions} currentPartner={currentPartner} />
        }
      >
        <DroppedMessageAlert message={message} />

        <Placeholder
          title={t('webedi:viewMessage.fallback.placeholder.title')}
          description={t('webedi:viewMessage.fallback.placeholder.description', {
            externalRef: message?.external_message_ref,
          })}
          ctaOnClick={() => {
            navigate(routeToPage(TransactionsRoutes.root));
          }}
          ctaText={t('webedi:viewMessage.fallback.placeholder.cta')}
          ctaIcon={ArrowLeftIcon}
          icon={<EyeSlashIcon strokeWidth="1" />}
        />
      </Page.Section>
    </Page>
  );
};
