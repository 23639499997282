import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/Display/Tooltip/Tooltip';
import { formatDayAndTime } from 'support/helpers/dateTime/dateTime';
import { EMPTY_FIELD } from 'support/helpers/const/const';
import { components } from 'support/types/schema-portal';
import { useTranslation } from 'react-i18next';

type NotificationLog = components['schemas']['NotificationLog'];

type CatalogPartnerListLastRequestElementProps = {
  lastNotificationLogGroup: Array<NotificationLog> | null;
};

export const CatalogPartnerListLastRequestElement: React.FC<CatalogPartnerListLastRequestElementProps> = ({
  lastNotificationLogGroup,
}) => {
  const { t } = useTranslation();

  if (lastNotificationLogGroup && lastNotificationLogGroup.length > 0) {
    return (
      <span className="flex items-center gap-1 tabular-nums">
        {formatDayAndTime(lastNotificationLogGroup[0].created_at)}
        <Tooltip>
          <TooltipTrigger>
            <InformationCircleIcon className="mr-2 size-4 text-gray-400 hover:text-procuros-green-500" />
          </TooltipTrigger>
          <TooltipContent>
            <div>{t('priceCatalogs:partnerList.lastRequestElement.requestSentTo')}</div>
            <ul>
              {lastNotificationLogGroup.map((notification, index) => (
                <li key={index}>{notification.destination_contact}</li>
              ))}
            </ul>
          </TooltipContent>
        </Tooltip>
      </span>
    );
  }

  return EMPTY_FIELD;
};
