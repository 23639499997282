import { Popup } from 'components/Display/Popup/Popup';
import { Button } from 'components/Form/Button/Button';
import { LoadingLogo } from 'components/Loading/LoadingLogo';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationType, useNotificationStore } from 'stores/notifications/notifications';
import { useAnalytics } from 'support/helpers/analytics/analytics';
import { EMPTY_FUNCTION } from 'support/helpers/const/const';
import { CatalogPartnerListItem } from '../PriceCatalogsPage';
import {
  BulkRequestDocumentFromSenderRequest,
  useBulkRequestDocumentFromSender,
} from '../../../services/repositories/relationships/relationships';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { Textarea } from '../../../components/Form/Input/Textarea/Textarea';
import { CheckboxInABox } from 'components/Form/Checkbox/Checkbox';
import { useForm } from 'react-hook-form';

export type RequestDocumentFromSenderModalProps = {
  catalogPartnerListItems: Array<CatalogPartnerListItem>;
  open: boolean;
  onClose?: () => void;
  onComplete: () => void;
};

export function RequestDocumentFromSenderModal({
  catalogPartnerListItems,
  open,
  onClose,
  onComplete,
}: RequestDocumentFromSenderModalProps) {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const { addNotification } = useNotificationStore();
  const { mutate, isLoading, reset, isSuccess } = useBulkRequestDocumentFromSender();
  const shouldLoadingState = isLoading || isSuccess;

  const selectedRelationshipIds: Array<string> = useMemo(
    (): Array<string> =>
      catalogPartnerListItems.map((item: CatalogPartnerListItem): string => item.catalogRelationshipId),
    [catalogPartnerListItems],
  );

  const sendRequest = async (customMessage: string, includeAsCCRecipient: boolean) => {
    customMessage = customMessage.trim();

    const payload: BulkRequestDocumentFromSenderRequest = {
      custom_message: customMessage.length > 0 ? customMessage : null,
      relationships: selectedRelationshipIds,
      include_as_cc_recipient: Boolean(includeAsCCRecipient),
    };

    mutate(payload, {
      onSuccess: (response) => {
        track('priceCatalogs_request_sent', {
          custom_message: customMessage,
          relationship_count: selectedRelationshipIds.length,
          relationship_ids: selectedRelationshipIds,
        });

        if (response.data.failed.length > 0) {
          addNotification(
            {
              title: t('priceCatalogs:requestCatalogModal.response.warn', {
                successful: selectedRelationshipIds.length - response.data.failed.length,
                total: selectedRelationshipIds.length,
              }),
              subtitle: t('priceCatalogs:requestCatalogModal.response.failed_description'),
            },
            NotificationType.error,
          );
        } else {
          addNotification(
            {
              title: t('priceCatalogs:requestCatalogModal.response.success', {
                count: selectedRelationshipIds.length,
              }),
            },
            NotificationType.success,
          );
        }
      },
      onError: () => {
        addNotification(t('common:error.generic'), NotificationType.error);
      },
      onSettled: () => {
        onComplete();
        // We need this timeout with the reset to restore the modal state to its initial state.
        // Otherwise, after making the first price catalog request, the loading state would remain.
        // The timeout is necessary here to prevent the modal from flickering.
        setTimeout(reset, 500);
      },
    });
  };

  const { register, watch } = useForm<{ custom_message: string; include_as_cc_recipient: boolean }>();

  const PrimaryButton = () => (
    <Button
      onClick={() => {
        sendRequest(watch('custom_message'), watch('include_as_cc_recipient'));
      }}
      loading={shouldLoadingState}
      RightIcon={PaperAirplaneIcon}
      analyticsId="priceCatalogs:send_request"
    >
      <div data-testid="action-button">{t('priceCatalogs:requestCatalogModal.actions.sendRequest')}</div>
    </Button>
  );

  const CancelButton = ({ cancelButtonRef }: { cancelButtonRef: React.RefObject<HTMLButtonElement> }) => (
    <Button
      onClick={onComplete}
      disabled={shouldLoadingState}
      variant="secondary"
      ref={cancelButtonRef}
      analyticsId="priceCatalogs:request_cancel"
    >
      <div data-testid="cancel-button">{t('priceCatalogs:requestCatalogModal.actions.cancel')}</div>
    </Button>
  );

  return (
    <Popup
      CancelButton={CancelButton}
      PrimaryButton={PrimaryButton}
      title={
        !shouldLoadingState
          ? t('priceCatalogs:requestCatalogModal.title', { count: catalogPartnerListItems.length })
          : ''
      }
      subtitle={
        !shouldLoadingState
          ? t('priceCatalogs:requestCatalogModal.subtitle', {
              count: catalogPartnerListItems.length,
              senderPartner: catalogPartnerListItems[0]?.partner.name,
            })
          : ''
      }
      open={open}
      onClose={onClose || EMPTY_FUNCTION}
      testId="request-price-catalog-modal"
    >
      {shouldLoadingState && (
        <div className="flex h-full flex-col items-center justify-center text-center">
          <div className="mb-6 w-11">
            <LoadingLogo />
          </div>

          <p className="text-gray-900">{t('priceCatalogs:requestCatalogModal.loading.title')}</p>
          <p className="text-gray-500">{t('priceCatalogs:requestCatalogModal.loading.subtitle')}</p>
        </div>
      )}

      {!shouldLoadingState && (
        <div className="flex flex-col gap-2">
          <p className="mb-3 text-sm text-gray-500">
            {t('priceCatalogs:requestCatalogModal.provideAdditionalContext')}
          </p>
          <Textarea {...register('custom_message')} />
          <CheckboxInABox
            label={t('partners:request.includeAsCCRecipient.label')}
            description={t('partners:request.includeAsCCRecipient.description')}
            {...register('include_as_cc_recipient')}
          />
        </div>
      )}
    </Popup>
  );
}
