import { ApplicationLogoSmall } from 'components/Logo/ApplicationLogoSmall/ApplicationLogoSmall';
import { useEffect } from 'react';
import { logError } from 'services/logging/logging';
import { GenericError } from 'support/helpers/errors/errors';

export function NotFoundPage() {
  useEffect(() => {
    logError(new GenericError('404 | Page not found', { location: window.location.href }));
  }, []);
  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center bg-gray-100">
      <ApplicationLogoSmall className="size-40 rounded-full bg-black shadow-2xl" />
      <div className="mt-5 text-4xl">404 | Not Found</div>
    </div>
  );
}
