import { usePriceCatalogSummary } from 'components/WebEDI/hooks/usePriceCatalogSummary';
import { useTranslation } from 'react-i18next';
import { formatTimestampToDay } from 'support/helpers/dateTime/dateTime';
import { WebEDISidebarTableContent } from '../../WebEDISidebarTableContent/WebEDISidebarTableContent';

export const PriceCatalogSummary = () => {
  const { t } = useTranslation();
  const priceCatalogSummary = usePriceCatalogSummary();
  return (
    <WebEDISidebarTableContent
      items={[
        {
          label: t('webedi:documentSummary.priceCatalog.lineCount.label'),
          value: Math.round(priceCatalogSummary.lineCount),
        },
        {
          label: t('webedi:documentSummary.priceCatalog.creationDate.label'),
          value: formatTimestampToDay(priceCatalogSummary.creationDate) ?? '—',
        },
      ]}
    />
  );
};
