import api from '@flatfile/api';
import i18n from 'i18next';
import { Constraint, CreateWorkbookConfig, Property } from '@flatfile/api/api';
import { FlatfileProvider, Space, Workbook, useEvent, useFlatfile } from '@flatfile/react';
import { Button } from 'components/Form/Button/Button';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ENV } from 'services/environment/environment';
import { formatDateToISO } from 'support/helpers/dateTime/dateTime';
import { BasePopupV2 } from 'components/Display/BasePopup/BasePopupV2';

const PUBLISHABLE_KEY = ENV.FLATFILE_PUBLISHABLE_KEY;

export type FieldDefinition = {
  key: string;
  type: 'string' | 'number' | 'date' | 'enum' | 'boolean';
  options?: Array<{ value: string; label: string }>;
  label: string;
  description?: string;
  isRequired?: boolean;
  isUnique?: boolean;
};

export type DataRow = Record<string, unknown>;

const toFlatFileFieldType = (type: FieldDefinition['type']): FieldDefinition['type'] => {
  if (type === 'date') {
    // Cannot use the default date type in Flatfile as that one
    // does not work with european date formats. See https://flatfile.com/docs/blueprint/field-types#date
    return 'string';
  }
  return type;
};

const toFlatFileField = (field: FieldDefinition): Property => {
  const constraints: Array<Constraint> = [];

  if (field.isRequired) {
    constraints.push({
      type: 'required',
    });
  }

  if (field.isUnique) {
    constraints.push({
      type: 'unique',
    });
  }

  return {
    key: field.key,
    type: toFlatFileFieldType(field.type),
    label: field.label,
    description: field.description,
    constraints,
    config: {
      options: field.options ?? [],
    },
  } as Property;
};

const FlatFileIframe = ({
  workbookName,
  sheetName,
  workbookDescription,
  sheetDescription,
  importActionLabel,
  importActionDescription,
  fields,
  onSubmit,
}: {
  workbookName: string;
  sheetName: string;
  workbookDescription: string;
  sheetDescription: string;
  importActionLabel: string;
  importActionDescription: string;
  fields: Array<FieldDefinition>;
  onSubmit: (data: Array<DataRow>) => void;
}) => {
  const currentLanguage = i18n.language;
  const { t } = useTranslation();
  const { openPortal, closePortal } = useFlatfile();

  const workbook: CreateWorkbookConfig = useMemo(
    () => ({
      name: workbookName,
      description: workbookDescription,
      labels: ['pinned'],
      sheets: [
        {
          name: sheetName,
          description: sheetDescription,
          slug: 'line_items',
          fields: fields.map(toFlatFileField),
        },
      ],
      actions: [
        {
          operation: 'submitActionFg',
          mode: 'foreground',
          label: importActionLabel,
          description: importActionDescription,
          primary: true,
          trackChanges: true,
          constraints: [{ type: 'hasAllValid' }, { type: 'hasData' }],
        },
      ],
      submitSettings: {
        deleteSpaceAfterSubmit: true,
      },
    }),
    [
      fields,
      workbookDescription,
      workbookName,
      importActionDescription,
      importActionLabel,
      sheetDescription,
      sheetName,
    ],
  );

  const onRecordUpdated = useCallback(
    (record: any) => {
      for (const [key, value] of Object.entries(record.data)) {
        const fieldDefinition = fields.find((field) => field.key === key);
        if (!fieldDefinition || !value) {
          continue;
        }
        switch (fieldDefinition.type) {
          case 'date': {
            // Convert the date to a string
            const timestamp = Date.parse(`${value}`);
            if (isNaN(timestamp)) {
              record.addError(key, t('webedi:importModal.fieldTypes.date.errors.invalid.message'));
              break;
            }
            record.set(key, formatDateToISO(new Date(timestamp)));
            record.addInfo(key, t('webedi:importModal.fieldTypes.date.infos.converted.message'));
            break;
          }
          case 'number': {
            const parsedValue = parseFloat(`${value}`);
            if (isNaN(parsedValue)) {
              record.addError(key, t('webedi:importModal.fieldTypes.number.errors.notANumber.message'));
              break;
            }
            if (parsedValue != value) {
              record.set(key, parsedValue);
              record.addInfo(key, t('webedi:importModal.fieldTypes.number.errors.notANumber.message'));
            }
            break;
          }
        }
      }

      return record;
    },
    [fields, t],
  );

  /**
   * First step to enable the "back button" workaround described
   * here: https://support.flatfile.com/en/articles/8844013-custom-button-back-to-mapping
   *
   * When the mapping job completes, let's cache the last config so we can restore it during the back
   * operation and let's add the back action.
   */
  useEvent(
    'job:completed',
    { operation: 'map' },
    async ({ context }) => {
      const { data: job } = await api.jobs.get(context.jobId);
      // @ts-expect-error The FlatFile API is not typed correctly
      const { data: sheet } = await api.sheets.get(job.config.destinationSheetId);
      const { data: workbook } = await api.workbooks.get(sheet.workbookId);

      await api.workbooks.update(workbook.id, {
        metadata: {
          lastMappingConfig: {
            type: 'workbook',
            operation: 'map',
            trigger: 'manual',
            source: job.source,
            destination: job.destination,
            mode: 'foreground',
            config: job.config,
          },
        },
        actions: [
          // If you already have a submit action, you'll want to include this in the update call, otherwise it will be overwritten
          {
            operation: 'submitActionFg',
            mode: 'foreground',
            label: importActionLabel,
            description: importActionDescription,
            primary: true,
            // @ts-expect-error The FlatFile API is not typed correctly
            trackChanges: true,
            constraints: [{ type: 'hasAllValid' }, { type: 'hasData' }],
          },
          {
            operation: 'backToMappingAction',
            mode: 'foreground',
            label: t('webedi:importModal.actions.backToMapping.label'),
            tooltip: t('webedi:importModal.actions.backToMapping.tooltip'),
            description: t('webedi:importModal.actions.backToMapping.description'),
            confirm: true,
          },
        ],
      });
    },
    [api, t],
  );

  /**
   * Second step to enable the "back button" workaround described
   * here: https://support.flatfile.com/en/articles/8844013-custom-button-back-to-mapping
   *
   * Handle the back to mapping job by deleting records. Do not complete the job here, pick it up
   * after the data deletion job completes.
   */
  useEvent(
    'job:ready',
    { operation: 'backToMappingAction' },
    async ({ context }) => {
      const { jobId, workbookId } = context;

      await api.jobs.ack(jobId, {
        info: t('webedi:importModal.jobs.backToMappingAction.messages.started'),
        progress: 10,
      });

      const { data: workbook } = await api.workbooks.get(workbookId);

      // remove the back action
      await api.workbooks.update(context.workbookId, {
        actions: workbook.actions!.filter((a) => a.operation !== 'backToMappingAction'),
      });

      const sheets = await api.sheets.list({ workbookId });

      await api.jobs.ack(jobId, {
        info: t('webedi:importModal.jobs.backToMappingAction.messages.deletingRecords'),
        progress: 30,
      });

      await api.jobs.create({
        operation: 'delete-records',
        type: 'workbook',
        source: workbookId,
        trigger: 'immediate',
        config: {
          sheet: sheets.data[0].id,
          filter: 'all',
        },
        input: {
          proceedMappingAfterComplete: true,
          originalJobId: jobId,
        },
      });
    },
    [api, t],
  );

  /**
   * Third step to enable the "back button" workaround described
   * here: https://support.flatfile.com/en/articles/8844013-custom-button-back-to-mapping
   *
   * After records are deleted, check to see if it was initiated by a back action. If so
   * spin up a new mapping job using the most recent configuration and direct the user.
   */
  useEvent(
    'job:completed',
    { operation: 'delete-records' },
    async ({ context }) => {
      const { data: job } = await api.jobs.get(context.jobId);
      const { data: workbook } = await api.workbooks.get(context.workbookId);

      if (job.input!.proceedMappingAfterComplete && workbook.metadata.lastMappingConfig) {
        const { data: mappingJob } = await api.jobs.create(workbook.metadata.lastMappingConfig);
        await api.jobs.complete(job.input!.originalJobId, {
          outcome: {
            acknowledge: true,
            heading: t('webedi:importModal.jobs.deleteRecords.messages.backToMappingHeading'),
            next: {
              type: 'id',
              id: context.spaceId,
              path: `job/${mappingJob.id}`,
              label: t('webedi:importModal.jobs.deleteRecords.messages.backToMappingButton'),
            },
            hideDefaultButton: true,
          },
        });
      }
    },
    [api, t],
  );

  useEvent(
    'job:ready',
    { job: 'workbook:submitActionFg' },
    async (event) => {
      const { jobId } = event.context;
      try {
        await api.jobs.ack(jobId, {
          info: t('webedi:importModal.jobs.submitAction.messages.started'),
          progress: 10,
        });

        const sheetsResponse = await api.sheets.list({ workbookId: event.context.workbookId });
        const recordsResponse = await api.records.get(sheetsResponse.data[0].id);

        // console.log('the records:', recordsResponse.data.records);
        const mappedData = recordsResponse.data.records.map((record: any) => {
          return Object.entries(record.values).reduce((acc, [key, valueObj]) => {
            const fieldDefinition = fields.find((field) => field.key === key);
            if (!fieldDefinition || !valueObj) {
              return acc;
            }
            const value = (valueObj as any).value as string;

            switch (fieldDefinition.type) {
              case 'date':
                acc[key] = new Date(value).getTime() / 1000;
                break;
              case 'number':
                if (isNaN(parseFloat(value))) {
                  break;
                }
                acc[key] = parseFloat(value);
                break;
              default:
                acc[key] = value;
            }

            return acc;
          }, {} as DataRow);
        });

        await api.jobs.complete(jobId);

        onSubmit(mappedData);
        closePortal();
      } catch (error: any) {
        console.error('Error:', error.stack);

        await api.jobs.fail(jobId, {
          outcome: {
            message: t('webedi:importModal.jobs.submitAction.messages.error'),
          },
        });
      }
    },
    [fields, api, t],
  );

  useEffect(() => {
    openPortal();
  }, []);

  return (
    <Space
      config={{
        languageOverride: currentLanguage,
        metadata: {
          theme: {
            root: {
              primaryColor: '#000000',
              dangerColor: '#DC2626',
              warningColor: '#D97706',
              successColor: '#059669',
              actionColor: '#000000',
              borderColor: '#E5E7EB',
              badgeBorderColor: '#E5E7EB',
              badgeBorderRadius: '6px',
              buttonBorderRadius: '6px',
              fontFamily: 'Inter, Arial, sans-serif',
              fontSize: '14px',
              checkboxBorderColor: '#D1D5DB',
              checkboxBorderRadius: '4px',
              interactiveBorderColor: '#D1D5DB',
              interactiveBorderRadius: '6px',
              tabstripActiveColor: '#0BA683',
              tabstripInactiveColor: '#6B7280',
              tabstripHoverTextColor: '#374151',
              tabstripHoverBorderColor: '#D1D5DB',
              modalBorderRadius: '8px',
              pillBorderRadius: '32px',
              popoverBackgroundColor: '#FFFFFF',
              popoverBorderRadius: '8px',
              tooltipBackgroundColor: '#000000',
            },
            table: {
              fontFamily: 'Inter, Arial, sans-serif',
              cell: {
                active: {
                  borderWidth: '2px',
                  borderShadow: '0 0 0 2px #000000',
                },
                number: {
                  fontFamily: 'Inter, Arial, sans-serif',
                },
              },
              column: {
                header: {
                  backgroundColor: '#FFFFFF',
                  color: '#6B7280',
                },
              },
              indexColumn: {
                backgroundColor: '#FFFFFF',
                color: '#6B7280',
                selected: {
                  backgroundColor: '#FFFFFF',
                },
              },
              inputs: {
                checkbox: {
                  color: '#0ED6A9',
                  borderColor: '#D1D5DB',
                },
              },
              filters: {
                outerBorderRadius: '6px',
                innerBorderRadius: '4px',
                // outerBorder: '1px solid #E5E7EB',
              },
              buttons: {
                iconColor: '#4B5563',
                pill: {
                  // color: '#4B5563',
                  backgroundColor: '#FFFFFF',
                },
              },
            },
          },
          sidebarConfig: {
            showSidebar: false,
          },
        },
      }}
    >
      <Workbook config={workbook} onRecordHooks={[['line_items', onRecordUpdated]]} />
    </Space>
  );
};

const UploadStage = ({
  workbookName,
  workbookDescription,
  sheetName,
  sheetDescription,
  importActionLabel,
  importActionDescription,
  fields,
  onSubmit,
}: {
  workbookName: string;
  workbookDescription: string;
  sheetName: string;
  sheetDescription: string;
  importActionLabel: string;
  importActionDescription: string;
  fields: Array<FieldDefinition>;
  onSubmit: (data: Array<DataRow>) => void;
}) => {
  return (
    <FlatfileProvider
      publishableKey={PUBLISHABLE_KEY}
      config={{
        displayAsModal: false,
      }}
    >
      <FlatFileIframe
        workbookName={workbookName}
        workbookDescription={workbookDescription}
        sheetName={sheetName}
        sheetDescription={sheetDescription}
        importActionLabel={importActionLabel}
        importActionDescription={importActionDescription}
        fields={fields}
        onSubmit={onSubmit}
      />
    </FlatfileProvider>
  );
};

const RequirementsStage = ({ fields, onNext }: { fields: Array<FieldDefinition>; onNext: () => void }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="space-y-4 p-6">
        <div className="flex items-center justify-between">
          <h2 className="text-lg font-bold">{t('webedi:importLineItems.requirementsStage.title')}</h2>
          <Button onClick={onNext} analyticsId="webedi:import-next">
            {t('common:next')}
          </Button>
        </div>
        <div className="space-y-4 text-gray-700">
          <ul className="list-inside list-disc text-sm leading-relaxed">
            {fields.map((field) => (
              <li key={field.key}>
                {field.label}
                {field.isRequired && <span className="text-procuros-green-500"> *</span>}
              </li>
            ))}
          </ul>
          <p className="text-xs">
            {t('webedi:importLineItems.requirementsStage.requiredNotice')}
            <span className="text-procuros-green-500"> *</span>
          </p>
        </div>
      </div>
      <div className="space-y-4 bg-gray-50 p-6 text-sm text-gray-700">
        <p>
          {t('webedi:importLineItems.requirementsStage.note1')}
          <br />
          {t('webedi:importLineItems.requirementsStage.note2')}
        </p>
      </div>
    </>
  );
};

type ImportModalProps = {
  workbookName: string;
  workbookDescription: string;
  sheetName: string;
  sheetDescription: string;
  importActionLabel: string;
  importActionDescription: string;
  fields: Array<FieldDefinition>;
  setOpen: (open: boolean) => void;
  open: boolean;
  onImport: (data: Array<DataRow>) => void;
};

enum UploadStages {
  REQUIREMENTS,
  UPLOAD,
}

export default function ImportModal({
  workbookName,
  workbookDescription,
  sheetName,
  sheetDescription,
  importActionLabel,
  importActionDescription,
  fields,
  open,
  setOpen,
  onImport,
}: ImportModalProps) {
  const [stage, setStage] = useState<UploadStages>(UploadStages.REQUIREMENTS);

  const onSubmit = (data: Array<DataRow>) => {
    onImport(data);
    onClose();
  };

  const onClose = () => {
    setOpen(false);
    setStage(UploadStages.REQUIREMENTS);
  };

  return (
    <BasePopupV2 setOpen={setOpen} open={open} width="xl" shouldGrowIndefinitely className="max-h-[80%] overflow-auto">
      {stage === UploadStages.REQUIREMENTS && (
        <RequirementsStage fields={fields} onNext={() => setStage(UploadStages.UPLOAD)} />
      )}
      {stage === UploadStages.UPLOAD && (
        <UploadStage
          workbookName={workbookName}
          workbookDescription={workbookDescription}
          sheetName={sheetName}
          sheetDescription={sheetDescription}
          importActionLabel={importActionLabel}
          importActionDescription={importActionDescription}
          fields={fields}
          onSubmit={onSubmit}
        />
      )}
    </BasePopupV2>
  );
}
