import { ui } from '@procuros/datachecks';
import { DisplayModes } from 'components/DataTable/Types';
import { useDocumentErrors } from 'services/webediErrors/webediErrors';
import { DocumentHeaderInfo } from '../DocumentHeaderInfo/DocumentHeaderInfo';
import { DocumentLineItems } from '../DocumentLineItems/DocumentLineItems';
import { DocumentParties } from '../DocumentParties/DocumentParties';
import { DocumentPaymentTerms } from '../DocumentPaymentTerms/DocumentPaymentTerms';
import { DocumentTransportUnits } from '../DocumentTransportUnits/DocumentTransportUnits';
import { DocumentType, ValueOf } from 'support/types';
import { DocumentModificationGroups } from '../DocumentModificationGroups/DocumentModificationGroups';
import { DocumentBaseSection } from '../DocumentBaseSection/DocumentBaseSection';

export const DocumentSections = ({
  mode,
  uiConfig,
  documentType,
  variables,
}: {
  mode: DisplayModes;
  uiConfig: Array<ui.Section>;
  documentType: ValueOf<DocumentType>;
  variables: Record<string, string>;
}) => {
  useDocumentErrors(uiConfig, mode, variables);
  return (
    <div className="h-full space-y-8 divide-y divide-gray-200 [&>*:first-child]:pt-0 [&>*]:pt-6">
      {uiConfig.map((config) => {
        switch (config.type) {
          case 'info':
            return <DocumentHeaderInfo key={config.type} mode={mode} config={config} />;
          case 'parties':
            return <DocumentParties key={config.type} mode={mode} config={config} />;
          case 'transport_units':
            return <DocumentTransportUnits key={config.type} mode={mode} config={config} />;
          case 'line_items':
            return (
              <DocumentLineItems
                key={config.type}
                mode={mode}
                config={config}
                documentType={documentType as DocumentType}
              />
            );
          case 'payment_terms':
            return <DocumentPaymentTerms key={config.type} mode={mode} config={config} />;
          case 'global_modifications':
            return <DocumentModificationGroups key={config.type} mode={mode} config={config} />;
          default:
            return <DocumentBaseSection key={config.type} mode={mode} config={config} />;
        }
      })}
    </div>
  );
};
