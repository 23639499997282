import { CellContext } from '@tanstack/react-table';
import { classNames } from 'support/helpers/generic/generic';
import {
  useCellClassNames,
  useIsReadonly,
  useNumberConfigurations,
  isProcurosAddedValue,
  disableArrowKeys,
} from '../cellHelpers';
import { Input } from 'components/Form/Input/Input/Input';
import { CellProps } from '../../types';
import { RowStatus } from '../components/RowStatus/RowStatus';
import { useValue } from 'components/WebEDI/helpers';
import { useTranslation } from 'react-i18next';
import { InputWrapperCell } from './InputWrapperCell';
import { useFormContext } from 'react-hook-form';
import { useWebEDIFieldError } from 'services/webediErrors/webediErrors';
import { WebEDIRowData } from 'components/DataTable/Types';

export type Props = CellContext<WebEDIRowData, unknown> & CellProps;

export const NumberCell = ({ fieldId, column, row, table, isHighlighted, mode }: Props) => {
  const { t } = useTranslation();
  const {
    columnDef: { meta: columnMeta, ...columnDef },
    getIsFirstColumn,
    getIsLastColumn,
  } = column;

  const inputValue = useValue(row.original, fieldId, columnMeta?.optionalConfig?.expression);
  const { error } = useWebEDIFieldError(fieldId);
  const { prefix, formattedValue, suffix } = useNumberConfigurations({
    value: inputValue,
    mode,
    meta: columnMeta,
    rowData: row.original,
    fieldId,
  });

  const { register } = useFormContext();
  const isAdded = isProcurosAddedValue(row);
  const isReadOnly = useIsReadonly({ isAdded, mode, columnMeta, rowIndex: row.index, fieldId });
  const cellClassNames = useCellClassNames({
    isLastColumn: getIsLastColumn(),
    isFirstColumn: getIsFirstColumn(),
    row,
    table,
  });

  const inputClassNames = classNames(
    error
      ? 'group-hover:bg-red-100 bg-red-50 focus:!bg-transparent disabled:!bg-red-50'
      : 'group-hover:bg-procuros-green-50 focus:!bg-transparent disabled:!bg-transparent',
    cellClassNames,
    'text-end',
    columnMeta?.optionalConfig?.isStatusColumn ? '!pl-10' : undefined,
  );

  return (
    <InputWrapperCell fieldId={fieldId} isHighlighted={isHighlighted} className={cellClassNames}>
      <Input
        wrapperClassName="w-full"
        className={classNames(
          'bg-transparent w-full !border-none p-3 !outline-none text-xs !shadow-none rounded-none min-w-[140px]',
          inputClassNames,
        )}
        placeholder={!formattedValue && error?.type === 'required' ? t('common:table.errors.requiredField') : undefined}
        title={formattedValue ? String(formattedValue) : undefined}
        readOnly={isReadOnly}
        type="number"
        {...register(fieldId, { valueAsNumber: true })}
        value={columnMeta?.optionalConfig?.expression ? inputValue : undefined}
        hasError={Boolean(error)}
        leftIcon={
          columnMeta?.optionalConfig?.isStatusColumn ? (
            <RowStatus
              statusColumn={columnMeta?.optionalConfig?.quantityField}
              currentPath={fieldId}
              isProcurosAdded={isAdded}
              currentColumn={columnDef && 'accessorKey' in columnDef ? (columnDef.accessorKey as string) : ''}
              tooltips={{
                deleted: t('webedi:inputCell.status.deleted'),
                partial: t('webedi:inputCell.status.partial'),
                complete: t('webedi:inputCell.status.completed'),
              }}
            />
          ) : null
        }
        rightAddon={suffix}
        leftAddon={prefix}
        step={0.01}
        onKeyDown={disableArrowKeys}
        id={fieldId}
        data-field={fieldId}
        data-testid={fieldId}
        data-1p-ignore
      />
    </InputWrapperCell>
  );
};
