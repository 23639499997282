import { CellContext } from '@tanstack/react-table';
import { CellProps } from '../../types';
import { useEnumerableOptions } from 'components/WebEDI/helpers';
import { DropdownCell } from './DropdownCell';
import { WebEDIRowData } from 'components/DataTable/Types';

export type Props = CellContext<WebEDIRowData, unknown> & Omit<CellProps, 'register'>;

export const EnumerableCell = (props: Props) => {
  const {
    column: {
      columnDef: { meta },
    },
  } = props;
  const options = useEnumerableOptions(meta?.optionalConfig);

  return <DropdownCell {...props} options={options} />;
};
