import { Page } from 'components/Page/Page';
import { FormProvider, useForm } from 'react-hook-form';
import { Form } from 'components/Form/Form';
import { Button } from 'components/Form/Button/Button';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { DisplayModes } from 'components/DataTable/Types';
import { DocumentType, ProcessSpecificationDTO, RelationshipStatus, TransactionInternalType } from 'support/types/dtos';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { routeToPage, routeToTransactionPage } from 'support/helpers/navigation/navigation';
import { useHistoryStore } from 'stores/history/history';
import { useTranslation } from 'react-i18next';
import { DocumentSections } from 'components/WebEDI/DocumentSections/DocumentSections';
import { PriceList } from 'components/WebEDI/PriceList/PriceList';
import { getCanonical, terminateDataChecksWorker } from 'services/datachecks/datachecks';
import {
  useRelationshipDocumentCreation,
  useReceiverProcessSpecification,
} from 'services/repositories/relationships/relationships';
import { logError } from 'services/logging/logging';
import { addNotification, NotificationType } from 'stores/notifications/notifications';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { datachecksResolver } from 'support/helpers/resolvers/datachecksResolver';
import { cleanStore, setInitialCanonical, setProcessSpecificationFields, useWebEDIStore } from 'stores/webedi/webedi';
import { FEATURE_FLAGS, usePartnerFeatureFlag } from 'support/helpers/featureFlags/featureFlags';
import { GenericError } from 'support/helpers/errors/errors';
import { DocumentTitle } from './components/DocumentTitle/DocumentTitle';
import { WebEDIMessageErrorBanner } from 'components/WebEDI/WebEDIMessageErrorBanner/WebEDIMessageErrorBanner';
import { useAnalytics } from 'support/helpers/analytics/analytics';
import { OcrResult } from 'components/WebEDI/DocumentExtractor/types';
import { recordExtractionQuality } from 'components/WebEDI/DocumentExtractor/utils';
import { enrichDocument } from 'support/helpers/transformation/transformer';
import { Datachecks } from '../components/Datachecks/Datachecks';
import { BottomBanner } from '../CreateEditDocumentBottomBanner';
import { PriceCatalogRoutes } from 'support/types';
import { OCRButton } from './components/OCRButton/OCRButton';
import { useDocumentCreationRelationship } from 'support/helpers/relationships/relationships';

const mode = DisplayModes.create;
type RouteParams = {
  messageId: string;
  relationshipId: string;
  messageType: DocumentType;
};

const useIntervalSinceMount = () => {
  const initialTime = useRef(Date.now());
  const getIntervalSinceMount = useCallback(() => Date.now() - initialTime.current, []);

  return getIntervalSinceMount;
};

export const CreateDocument = () => {
  const { track } = useAnalytics();
  const getIntervalSinceMount = useIntervalSinceMount();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { previousPath } = useHistoryStore();
  const { pathname } = useLocation();

  const { enabled: priceCatalogsEnabled } = usePartnerFeatureFlag(FEATURE_FLAGS.TEMPORARY_PRICE_CATALOGS);
  const { messageId: sourceMessageId, relationshipId, messageType: documentType } = useParams<RouteParams>();
  const { mutate: createDocument, isLoading: isCreating } = useRelationshipDocumentCreation();
  const targetRelationship = useDocumentCreationRelationship({ relationshipId, sourceMessageId, documentType });
  const isLoading = useWebEDIStore((state) => state.isLoading);
  const uiConfig = useWebEDIStore((state) => state.uiConfig);

  const [ocrResult, setOcrResult] = useState<OcrResult>();

  useLayoutEffect(() => {
    cleanStore();
    return () => {
      cleanStore();
      terminateDataChecksWorker();
    };
  }, []);

  const receiverProcessSpecification = useProcessSpecification({
    relationshipId: targetRelationship?.id,
    sourceMessageId,
  });

  const resolver = useMemo(
    () => datachecksResolver(receiverProcessSpecification?.fields),
    [receiverProcessSpecification],
  );
  const formMethods = useForm<Record<string, unknown>>({
    mode: 'onSubmit',
    resolver,
    reValidateMode: 'onChange',
  });

  const {
    getValues,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = formMethods;

  const submit = async () => {
    if (!targetRelationship) {
      logError('No target relationship found');
      return;
    }

    if (!receiverProcessSpecification?.fields) {
      logError('No process specification found');
      return;
    }

    const { canonical } = await getCanonical(receiverProcessSpecification.fields, getValues());
    const { data: enrichedCanonical } = enrichDocument({ data: canonical, messageType: documentType });

    createDocument(
      {
        relationshipId: targetRelationship.id,
        data: enrichedCanonical,
      },
      {
        onSuccess: ({ transactionId, transactionType }) => {
          track('new_document_send', {
            document_type: documentType,
            time_to_submit: getIntervalSinceMount(),
          });

          if (ocrResult) {
            recordExtractionQuality(ocrResult.documentHash, {
              merged: ocrResult.data ?? {},
              sent: enrichedCanonical,
              context: {
                transaction_id: transactionId,
                transaction_type: transactionType,
              },
            });
          }

          if (
            previousPath &&
            ![RelationshipStatus.LIVE, RelationshipStatus.ACTIVE].includes(
              RelationshipStatus[targetRelationship.status],
            )
          ) {
            return navigate(previousPath);
          }
          const inProductCatalogRoute = pathname.startsWith(PriceCatalogRoutes.root);
          navigate(
            inProductCatalogRoute
              ? routeToPage(PriceCatalogRoutes.view, { id: transactionId })
              : routeToTransactionPage(TransactionInternalType.message, transactionId),
          );
        },
        onError: (e) => {
          logError(new GenericError('[WebEDIV2] Failed to create document: ', { errorMessage: e.message }));
          addNotification(
            {
              title: t('webedi:notifications.createDocumentBackendFailed.title', {
                documentType: t(`common:messageTypes.plural.${documentType}`),
              }),
              subtitle: t('webedi:notifications.createDocumentBackendFailed.subtitle', {
                documentType: t(`common:messageTypes.plural.${documentType}`),
              }),
            },
            NotificationType.error,
          );
        },
      },
    );
  };

  const handleCancel = useCallback(() => {
    const inProductCatalogRoute = pathname.startsWith(PriceCatalogRoutes.root);
    navigate(
      previousPath
        ? previousPath
        : inProductCatalogRoute
          ? PriceCatalogRoutes.root
          : routeToTransactionPage(TransactionInternalType.message, sourceMessageId!),
    );
  }, [navigate, pathname, previousPath, sourceMessageId]);

  if (!documentType) {
    return <Page isLoading={true}>{null}</Page>;
  }

  return (
    <FormProvider {...formMethods}>
      <Datachecks targetRelationshipId={targetRelationship?.id} documentType={documentType} />
      <Page isLoading={isLoading}>
        <Page.Head
          title={<DocumentTitle relationship={targetRelationship} />}
          rightContent={
            <div className="flex items-center gap-2">
              <Button
                variant="minimal"
                disabled={isSubmitting || isCreating}
                onClick={handleCancel}
                analyticsId="webedi:new_document_cancel"
              >
                {t('webedi:createDocument.cancel')}
              </Button>
              {[DocumentType.invoice, DocumentType.creditNote].includes(documentType) &&
                mode === DisplayModes.create &&
                !priceCatalogsEnabled && <PriceList receiverPartnerId={targetRelationship?.receiver_partner?.id} />}
              <OCRButton
                setOcrResult={setOcrResult}
                targetRelationship={targetRelationship}
                sourceMessageId={sourceMessageId}
                documentType={documentType}
                disabled={isSubmitting || isCreating}
              />
              <Button
                type="submit"
                form="webedi-document"
                loading={isSubmitting || isCreating}
                RightIcon={PaperAirplaneIcon}
                analyticsId="webedi:new_document_send"
              >
                {t('webedi:createDocument.submit')}
              </Button>
            </div>
          }
        />

        <Page.Section
          topBanner={Object.keys(errors).length ? <WebEDIMessageErrorBanner mode={mode} blockingError={null} /> : null}
          bottomBanner={<BottomBanner documentType={documentType} />}
        >
          <Form
            noValidate={true}
            onSubmit={handleSubmit(submit)}
            id="webedi-document"
            data-testid="webedi-document"
            autoComplete="off"
          >
            {uiConfig ? (
              <DocumentSections
                mode={mode}
                uiConfig={uiConfig}
                documentType={documentType}
                variables={
                  targetRelationship?.receiver_partner
                    ? { receiverPartnerName: targetRelationship.receiver_partner.name }
                    : {}
                }
              />
            ) : null}
          </Form>
        </Page.Section>
      </Page>
    </FormProvider>
  );
};

const useProcessSpecification = ({
  relationshipId,
  sourceMessageId,
}: {
  relationshipId: string | undefined;
  sourceMessageId: string | undefined;
}): ProcessSpecificationDTO | undefined => {
  const processSpecificationFields = useWebEDIStore((state) => state.processSpecificationFields);
  const { data: receiverProcessSpecification } = useReceiverProcessSpecification({
    variables: { relationshipId, sourceMessageId },
  });

  const finalReceiverProcessSpecification = useMemo(
    () =>
      receiverProcessSpecification
        ? {
            fields: processSpecificationFields ?? receiverProcessSpecification?.fields,
            data: receiverProcessSpecification?.data,
            process: receiverProcessSpecification?.process,
          }
        : undefined,
    [processSpecificationFields, receiverProcessSpecification],
  );

  useEffect(() => {
    if (receiverProcessSpecification) {
      setProcessSpecificationFields(receiverProcessSpecification.fields);

      if (!receiverProcessSpecification.data) {
        logError('No data found in process specification for receiver');
      }
      setInitialCanonical(receiverProcessSpecification.data || undefined);
    }
  }, [receiverProcessSpecification]);

  return finalReceiverProcessSpecification;
};
