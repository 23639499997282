import { CellContext } from '@tanstack/react-table';
import { cellMapping } from 'components/DataTable/cellMapping';
import { ColumnTypesInternal, DisplayModes, WebEDIRowData } from 'components/DataTable/Types';
import { useMemo } from 'react';
import { EMPTY_FUNCTION } from 'support/helpers/const/const';
import { getFieldId, isRelativeModification } from './cellHelpers';
import { ui } from '@procuros/datachecks';
import { logError } from 'services/logging/logging';
import { GenericError } from 'support/helpers/errors/errors';

export const BodyCell = (props: CellContext<WebEDIRowData, unknown>) => {
  const {
    cell: { id },
    column,
    row,
    table,
  } = props;
  const { columnDef } = column;
  const tableMeta = table.options.meta;

  if (!table.options.meta?.mode) {
    logError(new GenericError('Table display mode is missing', { tableMeta }));
  }
  const displayMode = (table.options.meta?.mode || DisplayModes.view) as DisplayModes;

  const globalFilter = table.getState().globalFilter;
  const isHighlighted = useMemo(() => {
    const globalFilterFn = table.getGlobalFilterFn();
    const isHighlightedDisabled = [
      (displayMode === DisplayModes.create || displayMode === DisplayModes.edit) && 'select',
      (displayMode === DisplayModes.create || displayMode === DisplayModes.edit) && 'date',
      ColumnTypesInternal.procuros_delete,
      ColumnTypesInternal.procuros_select,
    ].includes(column.columnDef.meta?.type as string);

    return !isHighlightedDisabled && globalFilter && globalFilterFn?.(row, column.id, globalFilter, EMPTY_FUNCTION);
  }, [displayMode, globalFilter, column.columnDef.meta?.type, column.id, row, table]);
  let fieldId = useMemo(() => getFieldId(columnDef, tableMeta?.dataPrefix, id), [columnDef, id, tableMeta?.dataPrefix]);

  if (!columnDef.meta) {
    logError(new GenericError('Column meta is missing', { columnDef }));
    return null;
  }
  const [ViewComponent, EditComponent] = cellMapping[columnDef.meta.type as ui.FieldTypes];

  if (!ViewComponent || !EditComponent) {
    logError(new GenericError('Cell component not found', { type: columnDef.meta.type }));
    return null;
  }
  const Cell = displayMode === DisplayModes.view ? ViewComponent : EditComponent;

  const isLineItems = tableMeta?.dataPrefix.includes('line_items');
  const isRelativeModificationRow = isRelativeModification(fieldId, row.original, displayMode, Boolean(isLineItems));

  if (isRelativeModificationRow && fieldId.endsWith('.amount')) {
    fieldId = fieldId.replace('.amount', '.percentage');
  }

  return <Cell {...props} isHighlighted={isHighlighted} fieldId={fieldId} key={fieldId} mode={displayMode} />;
};
