import { Connectors, IntegrationConfigurationSteps } from 'support/types';
import { IntegrationConfigurationForm } from '../IntegrationConfigurationForm/IntegrationConfigurationForm';
import { WebEDIInitialInformation } from './WebEDI/WebEDIInitialInformation';
import { WebEDIConfigurationForm } from './WebEDI/WebEDIConfigurationForm';
import { Page } from 'components/Page/Page';
import { useTranslation } from 'react-i18next';
import { useCurrentPartner } from 'services/repositories/partners/partners';

type ConnectorStepConfiguration = {
  key: IntegrationConfigurationSteps;
  content: React.ReactNode;
};

export const ConnectorSteps = ({ connector, stepKey }: { connector: Connectors; stepKey: string | undefined }) => {
  const { t } = useTranslation();
  const { data: currentPartner } = useCurrentPartner();

  let steps: Array<ConnectorStepConfiguration> = [];
  switch (connector) {
    case Connectors.WEB_EDI:
      steps = [
        {
          key: IntegrationConfigurationSteps.INITIAL_INFORMATION,
          content: <WebEDIInitialInformation />,
        },
        {
          key: IntegrationConfigurationSteps.CONFIGURATION,
          content: currentPartner ? (
            <IntegrationConfigurationForm
              partner={currentPartner}
              integration={{
                handle: '',
                connector,
                name: '',
                environment: 'PRODUCTION',
              }}
              mainForm={<WebEDIConfigurationForm />}
              header={{ environment: false, name: false }}
              defaultConfiguration={{
                billFromAddressIdentifier: currentPartner?.settings?.billFromAddressIdentifier,
                taxId: currentPartner?.settings?.billFromFederalTaxIdentifier,
                vatId: currentPartner?.settings?.billFromVatIdentifier,
                email: currentPartner?.settings?.invoiceToEmailAddress,
              }}
            />
          ) : null,
        },
      ];
      break;
  }

  const step = steps.find(({ key }) => stepKey === key) || steps[0];

  return (
    <Page>
      {step ? (
        <>
          <Page.Head title={t(`integrations:new.title.${step.key}.${connector}`)} />
          <Page.Section>{step.content}</Page.Section>
        </>
      ) : null}
    </Page>
  );
};

// Translations

// t('integrations:new.title.INITIAL_INFORMATION.WEB_EDI')
// t('integrations:new.title.CONFIGURATION.WEB_EDI')
