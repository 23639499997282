import { DataTable, useGenerateWebEDIColumns } from 'components/DataTable';
import { WebEDISection } from '../WebEDISection/WebEDISection';
import { DataTableProvider, useDataTableContext } from 'components/DataTable/contexts/DataTableContext';
import { ui } from '@procuros/datachecks';
import { useTranslation } from 'react-i18next';
import { DisplayModes } from 'components/DataTable/Types';
import { memo } from 'react';
import { DocumentSectionError } from '../DocumentSectionError/DocumentSectionError';

export const DocumentBaseSection = memo(({ config, mode }: DocumentBaseSectionProps) => {
  const { label: title, description, fields } = config;
  if (!fields?.length) return null;
  return (
    <WebEDISection header={{ title, spacing: 'medium', description }}>
      <div className="space-y-4">
        <DocumentSectionError path={config.type} />
        <DataTableProvider name={config.type}>
          <BaseSectionTable config={fields} mode={mode} dataPrefix={config.type} />
        </DataTableProvider>
      </div>
    </WebEDISection>
  );
});

DocumentBaseSection.displayName = 'DocumentBaseSection';

const BaseSectionTable = ({
  mode,
  config,
  dataPrefix,
}: {
  mode: DisplayModes;
  config: ui.Section['fields'];
  dataPrefix: string;
}) => {
  const { t } = useTranslation();
  const { fields } = useDataTableContext();

  const columns = useGenerateWebEDIColumns({
    config: config,
    ...TABLE_STATIC_CONFIG[mode],
  });

  return (
    <DataTable
      key={mode}
      meta={{ dataPrefix, mode }}
      columns={columns}
      data={fields}
      enableGlobalSearch={false}
      showCellBorders={mode === DisplayModes.create || mode === DisplayModes.edit}
      emptyState={
        mode === DisplayModes.create || mode === DisplayModes.edit
          ? t(`webedi:${dataPrefix}.empty_state.create.text`)
          : t(`webedi:${dataPrefix}.empty_state.view.text`)
      }
    />
  );
};

const TABLE_STATIC_CONFIG = {
  view: {},
  create: {},
  edit: {},
};

type DocumentBaseSectionProps = {
  config: ui.Section;
  mode: DisplayModes;
};

/**
 * t('webedi:attachments.empty_state.create.text')
 * t('webedi:attachments.empty_state.view.text')
 * t('webedi:attachments.empty_state.edit.text')
 */
