import { useMemo } from 'react';
import { useWebEDIStore } from 'stores/webedi/webedi';

type PriceCatalogSummaryValues = {
  lineCount: number;
  creationDate?: number;
};

export const usePriceCatalogSummary = (): PriceCatalogSummaryValues => {
  const canonical = useWebEDIStore((state) => state.canonical);

  return useMemo(
    () => ({
      lineCount: canonical?.line_items?.length ?? 0,
      creationDate: canonical?.catalog_date,
    }),
    [canonical?.line_items, canonical?.catalog_date],
  );
};
