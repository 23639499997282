import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { applyRowMasksToData } from 'services/datachecks/datachecks';
import { useWebEDIStore, setUiConfig } from 'stores/webedi/webedi';
import useDebouncePromise, { CANCELLED_ERROR } from 'support/helpers/hooks/useDebouncePromise';
import { useDebouncedWatch } from 'support/helpers/hooks/useDebounceWatch';
import { ValueOf } from 'support/types';
import { getEnrichedFlattenDataAndUIConfig } from './getEnrichedFlattenDataAndUIConfig';
import { useInitialWebEDISetup } from './hooks/useInitialWebEDISetup';
import { DocumentType } from 'support/types';

export type DatachecksProps = {
  targetRelationshipId?: string;
  documentType: ValueOf<DocumentType> | undefined;
};

export const DEBOUNCE_TIME = 800;
export const Datachecks = ({ targetRelationshipId, documentType }: DatachecksProps) => {
  useInitialWebEDISetup();

  const processSpecificationFields = useWebEDIStore((state) => state.processSpecificationFields);
  const {
    watch,
    reset,
    trigger,
    formState: { isSubmitted },
  } = useFormContext();

  const debouncedGetEnrichedFlattenDataAndUIConfig = useDebouncePromise(
    getEnrichedFlattenDataAndUIConfig,
    DEBOUNCE_TIME,
  );
  const documentFlattenData = useDebouncedWatch(watch, DEBOUNCE_TIME);

  // Process form data changes
  useEffect(() => {
    let isMounted = true;

    const processData = async () => {
      if (!documentFlattenData || Object.keys(documentFlattenData).length === 0 || !documentType) return;

      try {
        const result = await debouncedGetEnrichedFlattenDataAndUIConfig({
          data: documentFlattenData,
          receiverProcessSpecificationFields: processSpecificationFields,
          documentType,
        });

        if (!isMounted || !result) return;
        const { data, uiConfig } = result;
        const filteredData = uiConfig ? applyRowMasksToData(data, uiConfig) : data;

        reset(filteredData, {
          keepIsSubmitted: true,
          keepErrors: true,
        });

        if (isSubmitted) {
          trigger();
        }

        setUiConfig(uiConfig);
      } catch (error) {
        if (error instanceof Error && error.message !== CANCELLED_ERROR) {
          console.error('Error processing form data:', error);
        }
      }
    };

    processData();

    return () => {
      isMounted = false;
      debouncedGetEnrichedFlattenDataAndUIConfig.cancel();
    };
  }, [
    documentType,
    processSpecificationFields,
    targetRelationshipId,
    documentFlattenData,
    reset,
    trigger,
    debouncedGetEnrichedFlattenDataAndUIConfig,
    isSubmitted,
  ]);

  return null;
};
