import { DocumentEnvironmentBadge } from 'pages/Transactions/components/DocumentEnvironmentBadge/DocumentEnvironmentBadge';
import { useTranslation } from 'react-i18next';
import { DocumentType, RelationshipV2 } from 'support/types';

export const DocumentTitle = ({ relationship }: { relationship: RelationshipV2 | undefined }) => {
  const { t } = useTranslation();
  let title = t('webedi:createDocument.titles.unknownDocumentType');
  const messageType = relationship?.message_type;
  if (messageType && Object.values(DocumentType).includes(messageType as DocumentType)) {
    title = t('webedi:createDocument.titles.default', {
      documentType: t(`common:messageTypes.singular.${messageType}`),
    });
  }

  return (
    <div className="flex items-center gap-3">
      <h1>{title}</h1>
      <DocumentEnvironmentBadge relationshipStatus={relationship?.status} />
    </div>
  );
};
