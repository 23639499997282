import { FC, useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useInfinitePricingConfigs } from 'services/repositories/pricingConfigs/pricingConfigs';
import { PriceListSelector } from './components/PriceListSelector';
import { applyPricingConfigToInvoiceOrCreditNote } from './helpers';
import { useWebEDIStore } from 'stores/webedi/webedi';

export type PriceListProps = {
  receiverPartnerId?: string;
};
export const PriceList: FC<PriceListProps> = ({ receiverPartnerId }) => {
  const {
    getValues,
    reset,
    trigger,
    formState: { isSubmitted },
  } = useFormContext();
  const isLoadingWebEDI = useWebEDIStore((state) => state.isLoading);
  const [selectedPricingConfigId, setSelectedPricingConfigId] = useState<string>();
  const { data: pricingConfigsData } = useInfinitePricingConfigs({
    variables: {
      query: { cursor: null, perPage: null, filterValues: [{ key: 'partner_id', value: receiverPartnerId ?? '' }] },
    },
    enabled: !!receiverPartnerId,
  });
  const pricingConfigs = useMemo(() => pricingConfigsData?.pages.flatMap((page) => page.items), [pricingConfigsData]);

  const updatePriceList = useCallback(
    (priceConfigId: string) => {
      setSelectedPricingConfigId(priceConfigId);

      const pricingConfig = pricingConfigs?.find(({ id }) => id === priceConfigId);
      if (!pricingConfig) {
        return;
      }

      // Just updating the flattenData on the store
      // does not work as there is a race condition
      // that causes trigger() to execute the form validation
      // using stale data.
      reset(applyPricingConfigToInvoiceOrCreditNote(getValues(), pricingConfig), {
        keepIsSubmitted: true,
        keepErrors: true,
      });
      if (isSubmitted) {
        trigger();
      }
    },
    [pricingConfigs, reset, trigger, getValues, isSubmitted],
  );

  if (isLoadingWebEDI) {
    return null;
  }
  return (
    <PriceListSelector
      selectedPriceListId={selectedPricingConfigId}
      setSelectedPricingConfigId={updatePriceList}
      priceLists={pricingConfigs}
    />
  );
};
