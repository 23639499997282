import { datachecks, ui } from '@procuros/datachecks';
import isEqual from 'lodash/isEqual';
import { getCanonical } from 'services/datachecks/datachecks';
import { enrichDocument } from 'support/helpers/transformation/transformer';
import { orderLineItems, reAddInternalInformation } from './utils';
import { DocumentType, ProcessSpecificationDTO, ValueOf } from 'support/types';
import { logError } from 'services/logging/logging';

let previousEnrichedCanonical: Record<string, any> | null = null;

export type ProcessFormDataArgs = {
  data: Record<string, any>;
  receiverProcessSpecificationFields?: ProcessSpecificationDTO['fields'];
  documentType: ValueOf<DocumentType> | DocumentType;
};

export type ProcessFormResult = {
  data: Record<string, any>;
  uiConfig: Array<ui.Section>;
};

export const getEnrichedFlattenDataAndUIConfig = async ({
  data,
  receiverProcessSpecificationFields,
  documentType,
}: ProcessFormDataArgs): Promise<ProcessFormResult | undefined> => {
  if (!receiverProcessSpecificationFields || !data || Object.keys(data).length === 0) {
    throw new Error('Missing required data for processing');
  }

  try {
    const { canonical } = await getCanonical(receiverProcessSpecificationFields, data);
    const { data: enrichedDocument } = enrichDocument({
      data: canonical,
      messageType: documentType as DocumentType,
    });

    if (!isEqual(enrichedDocument, previousEnrichedCanonical)) {
      previousEnrichedCanonical = enrichedDocument;
      return getFlattenDataAndUIConfig(receiverProcessSpecificationFields, enrichedDocument, data);
    }
  } catch (error) {
    logError(error);
    throw error;
  }
};

const getFlattenDataAndUIConfig = async (
  receiverProcessSpecificationFields: ProcessSpecificationDTO['fields'],
  enrichedDocument: Record<string, any>,
  previousFlattenData: Record<string, any>,
) => {
  const uiResult = await ui.view(
    { fields: receiverProcessSpecificationFields as Array<datachecks.SpecFieldT> },
    enrichedDocument,
  );

  const hasTransportUnits = 'transport_units' in uiResult.values && uiResult.values.transport_units?.length > 0;
  const hasLineItems = 'line_items' in uiResult.values && uiResult.values.line_items?.length > 0;
  if (hasTransportUnits && hasLineItems) {
    uiResult.values.line_items = orderLineItems(uiResult.values.line_items, previousFlattenData.line_items);
  }

  const newFlattenData = reAddInternalInformation(uiResult.values, previousFlattenData);

  return {
    data: newFlattenData,
    uiConfig: uiResult.view,
  };
};
