import { forwardRef } from 'react';
import type { Header } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import { getPinningStyles } from '../layoutHelpers/getPinningStyles';
import classNames from 'classnames';
type Props = {
  header: Header<any, unknown>;
  betweenCellBorders: boolean;
  isFirstColumn: boolean;
  isLastColumn: boolean;
  isAction: boolean;
  className?: string;
  style?: React.CSSProperties;
};

export const Th = forwardRef<HTMLTableCellElement, Props>(
  ({ style, header, isFirstColumn, isLastColumn, className, betweenCellBorders, isAction }, forwardRef) => {
    return (
      <th
        ref={forwardRef}
        key={header.id}
        className={classNames(
          'whitespace-nowrap text-xs font-normal text-gray-500 text-left bg-white p-0 border-b border-b-gray-200 border-r border-r-gray-200 last:border-r-0',
          {
            'rounded-tl-md': isFirstColumn,
            'rounded-tr-md': isLastColumn,
            'border-r-transparent': !betweenCellBorders && !isLastColumn,
            'w-[40px] max-w-[40px]': isAction,
          },
          className,
        )}
        style={{
          ...style,
          ...getPinningStyles(header.column),
        }}
      >
        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
      </th>
    );
  },
);

Th.displayName = 'Th';
