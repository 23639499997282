import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useMemo } from 'react';
import { useSenderProcessSpecification } from 'services/repositories/relationships/relationships';
import { DocumentType, ProcessSpecificationDTO } from 'support/types';
import { ENV } from 'services/environment/environment';

export const useShouldShowOcr = (documentType: DocumentType | undefined) => {
  const shippingNoticeOcrEnabled = useFeatureFlagEnabled('ocr-shipping-notice');
  return useMemo(() => {
    return documentType === DocumentType.shippingNotice && !!shippingNoticeOcrEnabled;
  }, [documentType, shippingNoticeOcrEnabled]);
};

export const isBetaOCR = (senderProcessSpecification: ProcessSpecificationDTO | undefined) => {
  if (ENV.APP_ENV === 'staging') {
    return false;
  }
  return !senderProcessSpecification?.meta?.ocr?.enabled;
};

export const useIsBetaOCR = ({
  targetRelationshipId,
  sourceMessageId,
}: {
  targetRelationshipId: string | undefined;
  sourceMessageId: string | undefined;
}) => {
  const { data: senderProcessSpecification, isLoading } = useSenderProcessSpecification({
    variables: { relationshipId: targetRelationshipId, sourceMessageId },
  });

  return { isLoading, isBeta: isBetaOCR(senderProcessSpecification) };
};
