import get from 'lodash/get';
import { useEffect, useState } from 'react';
import { useWebEDIStore } from 'stores/webedi/webedi';

export const useInitialValue = (fieldId: string) => {
  const [initialValue, setInitialValue] = useState<any>(null);
  const initialData = useWebEDIStore((state) => state.initialFlattenData);

  useEffect(() => {
    setInitialValue(get(initialData, fieldId));
  }, [fieldId, initialData]);

  return initialValue;
};
